import React from "react";
import Button from "../../form/Button";

const MediaGridBtns = ({
  selectedImages,
  setSelectedImages = () => {},
  setOpen = () => {},
  selectedMedia,
  setSelectedMedia = () => {},
  modalOpen = () => {},
  viewMode, 
  setViewMode,
}) => {
  
  const handleInsertMedia = () => {
    if (
      selectedImages &&
      Array.isArray(selectedImages) &&
      selectedImages.length > 0
    ) {
      const hasDefault = selectedMedia.some(
        (item) => item.default_status === 1
      );

      const formatedData = selectedImages.map((item) => ({
        mediaurl: item?.mediaurl,
        id: item?.id,
        default_status: hasDefault ? 0 : item.default_status || 0,
      }));

      setSelectedMedia((pre) => [...pre, ...formatedData]);
      modalOpen(false);
    }
  };

  return (
    <>
      <div className="flex gap-3">
        {/* {selectedImages && selectedImages.length > 0 && (
          <>
            <div>
              <Button
                buttonClasses={"!h-10 !bg-black"}
                buttonIcon={"fa-solid fa-xmark"}
                buttonIconPosition={"right"}
                buttonLabel={"Cancel"}
                buttonLabelClasses={""}
                buttonHasLink={false}
                buttonFunction={() => {
                  setSelectedImages([]);
                  setOpen(false);
                }}
              />
            </div>
            <div>
              <Button
                buttonClasses={"!h-10 !bg-black"}
                buttonIcon={"fa-regular fa-arrow-down-to-arc"}
                buttonIconPosition={"right"}
                buttonLabel={"Insert"}
                buttonLabelClasses={""}
                buttonHasLink={false}
                buttonFunction={handleInsertMedia}
              />
            </div>
          </>
        )} */}

        <div className="flex gap-1">
          <div>
            <Button
              buttonClasses={"!h-10 !bg-black"}
              buttonIcon={"fa-sharp fa-solid fa-grid-2"}
              buttonIconPosition={"right"}
              buttonLabel={""}
              buttonLabelClasses={""}
              buttonFunction={()=>setViewMode('grid')}
            />
          </div>
          <div>
            <Button
              buttonClasses={"!h-10 !bg-black"}
              buttonIcon={"fa-regular fa-chart-tree-map"}
              buttonIconPosition={"right"}
              buttonLabel={""}
              buttonLabelClasses={""}
              buttonFunction={()=>setViewMode('list')}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MediaGridBtns;
