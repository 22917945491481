import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import LOGO from "../../../src/assets/images/logo.png";
import invoice from "../../../src/assets/images/invoice.jpg";

const Invoice = React.forwardRef(({ data, settings, profile }, ref) => {
  const [logoBase64, setLogoBase64] = useState("");

  const contact_info = {
    name: JSON.parse(data?.user_info)?.name,
    email: JSON.parse(data?.user_info)?.email,
    phone: JSON.parse(data?.user_info)?.contact_no,
  };

  // useEffect(() => {
  //   if (settings?.logoUrl) {
  //     fetch(settings?.logoUrl)
  //       .then((response) => response.blob())
  //       .then((blob) => {
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //           setLogoBase64(reader.result);
  //         };
  //         reader.readAsDataURL(blob);
  //       })
  //       .catch((error) =>
  //         console.error("Error converting image to base64:", error)
  //       );
  //   }
  // }, [settings]);

  return (
    <div ref={ref} className="p-4">
      <div className="flex lg:justify-between border-b pb-2 border-gray-500">
        <div>
          {/* {logoBase64 ? (
            <img
              src={logoBase64 || LOGO}
              alt="logo"
              className="h-24 w-36 object-contain indent"
            />
          ) : (
            <p>Loading logo...</p>
          )} */}
          <img src={LOGO} alt="logo" className="h-24 w-24 object-contain" />
        </div>
        <div className="mt-4 text-right">
          <p className="mb-1 font-semibold text-4xl">
            <img src={invoice} alt="invoice" className="h-12 w-64 object-contain" />
          </p>
          <p className="text-sm font-normal">
            Order ID : <span className="font-semibold text-lg">{data?.order_id || "N/A"}</span>
          </p>
          <p className="text-xs font-normal">
            Date :{" "}
            <span className="text-sm">{data?.created_at ? dayjs(data?.created_at).format("DD/MM/YYYY") : "N/A"}</span>
          </p>
        </div>
      </div>
      <div className="flex justify-between mt-3 border-b pb-2 border-gray-500 gap-4">
        <div className="text-left">
          <span className="font-bold text-sm">Contact Info</span>
          <p className="font-semibold text-md">{contact_info?.name || ""}</p>
          <p className="text-xs font-normal">{contact_info?.email || ""}</p>
          <p className="text-xs font-normal">{contact_info?.phone || ""}</p>
        </div>

        <div className="text-center">
          <span className="font-bold text-sm">Shipping Address</span>
          {profile?.userShippingAddressInfo?.location ? (
            <p className="text-xs font-normal">{profile?.userShippingAddressInfo?.location}</p>
          ) : (
            ""
          )}

          <p className="text-xs font-normal">
            {` ${profile?.userShippingAddressInfo?.city ? profile?.userShippingAddressInfo?.city : ""}`}
          </p>
          {profile?.userShippingAddressInfo?.stateName ? (
            <p className="text-xs font-normal">{profile?.userShippingAddressInfo?.stateName || ""}</p>
          ) : (
            ""
          )}
          {profile?.userShippingAddressInfo?.pin ? (
            <p className="text-xs font-normal">{profile?.userShippingAddressInfo?.pin || ""}</p>
          ) : (
            ""
          )}
        </div>

        <div className="text-right">
          <span className="font-bold text-sm">Billing Address</span>
          {profile?.userBillingAdderssInfo?.address ? (
            <p className="text-xs font-normal">{profile?.userBillingAdderssInfo?.address}</p>
          ) : (
            <p className="text-xs font-normal">{profile?.userShippingAddressInfo?.location}</p>
          )}

          <p className="text-xs font-normal">
            {`${profile?.userBillingAdderssInfo?.address2 ? profile?.userBillingAdderssInfo?.address2 : ""}, ${
              profile?.userBillingAdderssInfo?.city
                ? profile?.userBillingAdderssInfo?.city
                : profile?.userShippingAddressInfo?.city
            }`}
          </p>
          {profile?.userBillingAdderssInfo?.stateName ? (
            <p className="text-xs font-normal">{profile?.userBillingAdderssInfo?.stateName || ""}</p>
          ) : (
            <p className="text-xs font-normal">{profile?.userShippingAddressInfo?.stateName || ""}</p>
          )}
          {profile?.userBillingAdderssInfo?.pin ? (
            <p className="text-xs font-normal">{profile?.userBillingAdderssInfo?.pin || ""}</p>
          ) : (
            <p className="text-xs font-normal">{profile?.userShippingAddressInfo?.pin || ""}</p>
          )}
        </div>
      </div>

      <div className="-mx-4 flow-root sm:mx-0">
        <table className="min-w-full">
          <thead className="border-b border-gray-500 text-gray-900">
            <tr>
              <th scope="col" className="py-2 pl-2 pr-2 text-left text-sm font-bold text-gray-900 sm:pl-0">
                Items Name
              </th>
              <th scope="col" className="hidden px-2 py-2 text-center text-sm font-bold text-gray-900 sm:table-cell">
                Quantity
              </th>
              <th scope="col" className="hidden px-2 py-2 text-center text-sm font-bold text-gray-900 sm:table-cell">
                No Of Packages
              </th>
              <th scope="col" className="hidden px-2 py-2 text-right text-sm font-bold text-gray-900 sm:table-cell">
                Price
              </th>
              <th scope="col" className="py-2 pl-2 pr-2 text-right text-sm font-bold text-gray-900 sm:pr-0">
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.orderDetailsInfo?.map((item) => (
              <tr key={item?.order_id}>
                <td className="max-w-0 py-3 pl-2 pr-2 text-xs sm:pl-0">
                  <div className="font-bold text-gray-900">{item?.product_name || ""}</div>
                </td>

                <td className="hidden px-2 py-3 text-center text-xs text-gray-700 sm:table-cell">
                  {item?.quantity || ""}
                </td>
                <td className="hidden px-2 py-3 text-center text-xs text-gray-700 sm:table-cell">
                  {item?.package_qty || ""}
                </td>
                <td className="hidden px-2 py-3 text-right text-xs text-gray-700 sm:table-cell">
                  {item?.actual_price.toFixed(2) || ""}
                </td>
                <td className="py-3 pl-2 pr-2 text-right text-xs text-gray-700 sm:pr-0">
                  {item?.total_price.toFixed(2) || ""}
                </td>
              </tr>
            ))}
            <tr className="border-t border-gray-500">
              <th
                scope="row"
                colspan="5"
                className="hidden pl-2 pr-2 pt-2 text-right text-sm font-normal text-gray-700 sm:table-cell sm:pl-0">
                <div className="flex justify-end">
                  <div className="border-b border-gray-500 w-[270px] pb-2 space-y-2">
                    <div className="flex gap-2 items-center">
                      <div className="flex-grow">Sub Total</div>
                      <div className="flex-grow-0 text-right text-md font-semibold text-gray-900 w-[120px]">
                        CAD {data?.sub_total.toFixed(2) || ""}
                      </div>
                    </div>
                    <div className="flex gap-1 items-center">
                      <div className="flex-grow">GST/HST Charge ({data?.vat_percentage}%)</div>
                      <div className="flex-grow-0 text-right text-md text-gray-900 w-[120px]">
                        CAD {data?.vat_charge || ""}
                      </div>
                    </div>
                    <div className="flex gap-2 items-center">
                      <div className="flex-grow"> Delivery Charge</div>
                      <div className="flex-grow-0 text-right text-md text-gray-900 w-[120px]">
                        {data?.delivery_charge === 0.0 ? "Free" : "CAD " + data?.delivery_charge.toFixed(2) || ""}
                      </div>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th
                scope="row"
                colspan="5"
                className="hidden pl-2 pr-2  text-right text-xl font-semibold text-gray-900 sm:table-cell sm:pl-0">
                <div className="flex justify-end">
                  <div className="flex gap-2 items-center">
                    <div className="flex-grow">Grand Total</div>
                    <div className="flex-grow-0 text-right w-[120px]">CAD {data?.total_price.toFixed(2) || ""}</div>
                  </div>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default Invoice;
