import React, { useState } from "react";
import Button from "../form/Button";
import FavouriteImage from "./FavouriteImage";
import Modal from "../elements/Modal/Modal";
import LeaveFeedback from "../sections/LeaveFeedback";
import moment from "moment";
import { country } from "../../helpers";
import { useNavigate } from "react-router-dom";
import Image from "../elements/Image";
import image2 from "../../assets/images/cards/card-inside-2.webp";

const OrderDetailsItems = ({ data, title }) => {
  const navigate = useNavigate();
  const productInfo = JSON.parse(data?.product_info);

  return (
    <>
      <div className="relative md:flex w-full bg-slate-50 rounded-md shadow py-6 border border-slate-200 md:mt-5 mt-5 xl:px-10 px-6">
        <div className="flex justify-center">
          <div className="md:w-40 w-56 xl:aspect-square overflow-hidden rounded-md flex">
            <div>
              <Image src={data?.orderProdImage || image2} classes="w-full h-full object-cover" alt="product image" />
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row">
          <div className="w-full xl:w-2/5 md:pl-4 pl-0">
            <div className="w-full xl:space-y-1 space-y-0">
              <div
                className="xl:!text-2xl text-lg font-bold lg:mt-0 mt-4"
                onClick={() => navigate(`/order-details/${data?.order_id}`)}>
                {data?.order_id}
              </div>
              <div className="flex space-x-2 pt-1 items-center">
                <div className="xl:text-base text-sm font-bold">Order Date:</div>
                <div className="xl:text-base text-sm text-gray-500">
                  {moment(data?.created_at).format("MMM DD,YYYY")}
                </div>
              </div>
              <div className="flex space-x-2 pt-1 items-center">
                <div className="xl:text-base text-sm font-bold">No Of Package:</div>
                <div className="xl:text-base text-sm text-gray-500">{productInfo[0]?.minimum_package_quantity}</div>
              </div>
              <div className="flex space-x-2 pt-1 items-center">
                <div className="xl:text-base text-sm font-bold">Quantity:</div>
                <div className="xl:text-base text-sm text-gray-500">{productInfo[0]?.quantity}</div>
              </div>
              <div className="flex space-x-2 pt-1 items-center">
                <div className="xl:text-base text-sm font-bold">Payment:</div>
                <div className="xl:text-base text-sm text-gray-500">{data?.payment}</div>
              </div>
              <div className="flex space-x-2 pt-1 items-center">
                <div className="xl:text-base text-sm font-bold">Payment Status:</div>
                <div className="xl:text-base text-sm text-gray-500">
                  {data?.payment_status === 1 ? "Paid" : "Pending"}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full  flex flex-col md:pl-8 pl-0 mt-4 lg:mt-0">
            <div className="flex md:justify-end justify-start xl:text-2xl text-lg text-red-600 font-bold">
              {country + " " + (data?.total_price.toFixed(2) || "")}
            </div>
            <div className="flex md:justify-end justify-start xl:text-base text-sm  text-gray-600 font-semibold mt-4">
              {data?.status === 1 ? (
                <span className="ml-2 text-amber-600 border xl:text-base  text-sm border-slate-200 rounded-md bg-amber-100 shadow-sm px-2">
                  {data?.orderStatusInfo?.name} ({data?.orderStatusInfo?.processing_parcentage})
                </span>
              ) : data?.status === 2 ? (
                <span className="ml-2 text-green-600 border xl:text-base  text-sm border-slate-200 rounded-md bg-green-100 shadow-sm px-2">
                  {data?.orderStatusInfo?.name} ({data?.orderStatusInfo?.processing_parcentage})
                </span>
              ) : data?.status === 3 ? (
                <span className="ml-2 text-pink-600 border xl:text-base  text-sm border-slate-200 rounded-md bg-pink-100 shadow-sm px-2">
                  {data?.orderStatusInfo?.name} ({data?.orderStatusInfo?.processing_parcentage})
                </span>
              ) : data?.status === 4 ? (
                <span className="ml-2 text-blue-600 border xl:text-base  text-sm border-slate-200 rounded-md bg-blue-100 shadow-sm px-2">
                  {data?.orderStatusInfo?.name} ({data?.orderStatusInfo?.processing_parcentage})
                </span>
              ) : (
                <span className="ml-2 text-red-600 border xl:text-base  text-sm border-slate-200 rounded-md bg-red-100 shadow-sm px-2">
                  {data?.orderStatusInfo?.name}
                </span>
              )}
            </div>
            <div className="flex w-full gap-3 items-end !md:justify-end justify-end mt-auto">
              <div className="flex flex-wrap md:gap-3 gap-2 justify-end">
                {/* <Button
                  buttonClasses="!px-4 !py-1 !h-10 !text-white hover:!text-white !w-full md:!w-auto !text-xl hover:!bg-black hover:!border-black !text-center bg-gray-600 gap-3 transition duration-200 rounded-none leading-none !rounded-md"
                  buttonLabel="Add to Cart Again"
                  buttonLabelClasses="md:!text-base text-sm"
                  buttonEffect="filled"
                  buttonHasLink
                  buttonLink="/cart"
                /> */}
                {/* <Button
                  buttonClasses="!px-4 !py-1 xl:!h-10 h-8 !text-white hover:!text-white !w-full md:!w-auto !text-xl !bg-red-600 hover:!border-black !text-center hover:!bg-black gap-3 transition duration-200 rounded-none leading-none !rounded-md"
                  buttonLabel="View Details"
                  buttonLabelClasses="md:!text-base text-sm"
                  buttonEffect="filled"
                  buttonHasLink={false}
                  buttonFunction={() => navigate(`/order-details/${data?.order_id}`)}
                /> */}
                <Button
                  buttonClasses="!px-4 !py-1 xl:!h-10 h-8 !text-white hover:!text-white !w-full md:!w-auto !text-xl !bg-red-600 hover:!border-black !text-center hover:!bg-black gap-3 transition duration-200 rounded-none leading-none !rounded-md"
                  buttonLabel="View Details"
                  buttonLabelClasses="md:!text-base text-sm"
                  buttonEffect="filled"
                  buttonHasLink={false}
                  buttonFunction={() => {
                    localStorage.setItem("selectedTabId", data?.status);
                    navigate(`/order-details/${data?.order_id}`);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <Modal size="xl4" modalTitle="" isOpen={isOpen}>
          <LeaveFeedback onClose={() => setIsOpen(false)} />
        </Modal> */}
      </div>
    </>
  );
};

export default OrderDetailsItems;
