import React from "react";
import Tabs from "../elements/Tabs";
import ProductDescriptionItem from "./ProductDescriptionItem";
import ProductDescriptionRating from "./ProductDescriptionRating";

const ProductDetailsDrescription = ({ details, activeTab, setActiveTab = () => {} }) => {
  const tabData = [
    {
      _id: 1,
      name: "Description",
      component: <ProductDescriptionItem details={details} />,
    },
    {
      _id: 2,
      name: "Reviews",
      component: <ProductDescriptionRating details={details} />,
    },
  ];

  return (
    <>
      <div className="relative md:pt-20 pt-10">
        <div className="App">
          <header className="App-header">
            <h1 className="md:text-3xl text-2xl font-medium mb-4 border-b border-slate-300 pb-4">
              Product{" "}
              <span className="text-red-600 font-extrabold">
                {activeTab === 1 ? "Description" : activeTab === 2 ? "Reviews" : ""}
              </span>
            </h1>
            <div className="relative">
              <Tabs
                tabs={tabData}
                selectedTabId={activeTab}
                onTabChange={(tabId) => setActiveTab(tabId)}
                navTabClass={""}
              />
            </div>
          </header>
        </div>
      </div>
    </>
  );
};

export default ProductDetailsDrescription;
