import React, { useState } from "react";
import Image from "../../../components/elements/Image";
import Button from "../../../components/form/Button";
import MediaSection from "../media/MediaSection";
import Modal from "../../../components/elements/Modal/Modal";
import DeleteModal from "../../../components/common/DeleteModal";

const SelectedMedia = ({ selectedMedia = [], setSelectedMedia = () => {} }) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const handleRemoveChange = (index) => {
    setIsOpenPopup(true);
    // setSelectedMedia((prevSelectedMedia) => {
    //   const newImages = [...prevSelectedMedia];
    //   newImages.splice(index, 1);
    //   return newImages;
    // });
  };

  const onDelete = (index) => {
    setSelectedMedia((prevSelectedMedia) => {
      const newImages = [...prevSelectedMedia];
      newImages.splice(index, 1);
      return newImages;
    });
  };

  const handleSetDefault = (index) => {
    setSelectedMedia((prevSelectedMedia) => {
      return prevSelectedMedia.map((item, i) => {
        return {
          ...item,
          default_status: i === index ? 1 : 0,
        };
      });
    });
  };
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="grid gap-4 divide-x divide-slate-200 pe-4 h-full transition-all duration-300 grid-cols-6">
      {selectedMedia && Array.isArray(selectedMedia) && selectedMedia.length > 0 ? (
        selectedMedia.map((item, index) => (
          <div key={index} className="relative group flex-shrink-0 rounded-md bg-white shadow-md">
            <div className="relative w-full rounded-md overflow-hidden border-none">
              <div className="w-full aspect-square overflow-hidden flex flex-shrink-0 rounded-md">
                <Image src={item?.mediaurl} alt="" classes="w-full h-full !object-cover" effect={""} />
              </div>
            </div>
            <div className="absolute top-1 right-2">
              <button
                onClick={() => handleRemoveChange(index)}
                type="button"
                className="!text-white !shadow-md rounded-lg bg-black/60 hover:text-white hover:bg-red-600 font-bold py-2 flex items-center transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg px-2">
                <i className="fa-solid fa-trash"></i>
              </button>
            </div>
            <div className="relative my-2 flex items-center justify-center">
              <button
                onClick={() => handleSetDefault(index)}
                type="button"
                className={`!text-white text-xs py-1 !shadow-md rounded-lg  font-bold flex items-center px-2  ${
                  item?.default_status === 1 ? "bg-blue-700" : "bg-rose-500"
                }`}>
                {item?.default_status === 1 ? "Default" : "Set As Default"}
              </button>
            </div>
          </div>
        ))
      ) : (
        <button
          onClick={() => setIsOpen(true)}
          type="button"
          className="col-span-6 flex flex-col items-center justify-center text-slate-500">
          <i className="fa-solid fa-folder-open text-6xl mb-4"></i>
          <p className="text-lg">No media selected yet</p>
          <p className="text-sm">Please select images or media to display here</p>
        </button>
      )}

      <Modal
        open={isOpen}
        title={`Media Section`}
        size="xl7"
        // onClose={setIsOpen}
        modalDialogueStyle={"overflow-hidden"}
        modalPaddingClass={"!pr-0"}
        titleClass={"pt-2"}>
        <div className="absolute -top-8 right-5">
          <Button
            buttonClasses={
              "!bg-white !border !border-gray-600 !rounded-full !text-black !w-8 !h-8 !items-center !justify-center !shadow-md !text-xl"
            }
            buttonIcon={"fa-regular fa-xmark"}
            buttonIconPosition={"left"}
            buttonHasLink={false}
            buttonFunction={() => setIsOpen(false)}
          />
        </div>
        <MediaSection
          isModal={true}
          selectedMedia={selectedMedia}
          setSelectedMedia={setSelectedMedia}
          modalOpen={setIsOpen}
          mediaContentClass={"!max-h-[calc(100vh-400px)] xl:!max-h-[calc(100vh-370px)]"}
          modalDescriptionHeight={"!max-h-[calc(100vh-315px)]"}
        />
      </Modal>
      <DeleteModal open={isOpenPopup} close={() => setIsOpenPopup(false)} deleteHandler={onDelete} />
    </div>
  );
};

export default SelectedMedia;
