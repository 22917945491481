import React, { useState } from "react";
import lable from "../../assets/images/labels/lable-card-12.webp";
import lable_2 from "../../assets/images/labels/lable-card-18.webp";
import lable_3 from "../../assets/images/labels/lable-card-13.webp";
import SingleProduct from "../Items/SingleProduct";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Pagination, Navigation } from "swiper/modules";
import ProductLoader from "../loader/ProductLoader";
import Modal from "../elements/Modal/Modal";
import QuickViewModal from "./QuickViewModal";

const SelectMoreProducts = ({
  list,
  actions,
  isOpen,
  setIsOpen = () => {},
}) => {
  const swiperParams = {
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1.5,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2.5,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3.5,
        spaceBetween: 20,
      },
      1280: {
        slidesPerView: 3.5,
        spaceBetween: 30,
      },
      1900: {
        slidesPerView: 3.5,
        spaceBetween: 40,
      },
    },
  };

  const featuredProductData = {
    subheading: "Great Designs At The Best Price",
    heading: "OUR LABELS",
    description:
      "HJL Designs is a manufacturer and wholesaler of high quality vinyl labels for the craft wine making industry. With a mix of modern and traditional designs, our ever-expanding lineup of 9.5 cm x 7 cm labels fit any bottle size, are easy to remove, and  do not leave any residue behind.",
    // subdata: [
    //   {
    //     _id: 1,
    //     name: "Pinot Gris (die cut)",
    //     image: lable,
    //     isLink: true,
    //     link: "/productdetails",
    //     actions: [
    //       {
    //         _id: 1,
    //         icon: "fa-light fa-heart",
    //         isLink: false,
    //         click: () => {},
    //       },
    //       {
    //         _id: 2,
    //         icon: "fa-light fa-bag-shopping",
    //         isLink: false,
    //         click: () => {},
    //       },
    //       {
    //         _id: 3,
    //         icon: "fa-light fa-arrow-right",
    //         isLink: true,
    //         link: "/productdetails",
    //       },
    //     ],
    //   },
    //   {
    //     _id: 2,
    //     name: "Verdicchio",
    //     image: lable_2,
    //     actions: [
    //       {
    //         _id: 1,
    //         icon: "fa-light fa-heart",
    //         isLink: false,
    //         click: () => {},
    //       },
    //       {
    //         _id: 2,
    //         icon: "fa-light fa-bag-shopping",
    //         isLink: false,
    //         click: () => {},
    //       },
    //       {
    //         _id: 3,
    //         icon: "fa-light fa-arrow-right",
    //         isLink: true,
    //         link: "/productdetails",
    //       },
    //     ],
    //   },
    //   {
    //     _id: 3,
    //     name: "California White (Die Cut)",
    //     image: lable_3,
    //     actions: [
    //       {
    //         _id: 1,
    //         icon: "fa-light fa-heart",
    //         isLink: false,
    //         click: () => {},
    //       },
    //       {
    //         _id: 2,
    //         icon: "fa-light fa-bag-shopping",
    //         isLink: false,
    //         click: () => {},
    //       },
    //       {
    //         _id: 3,
    //         icon: "fa-light fa-arrow-right",
    //         isLink: true,
    //         link: "/productdetails",
    //       },
    //     ],
    //   },
    //   {
    //     _id: 3,
    //     name: "California White (Die Cut)",
    //     image: lable_3,
    //     actions: [
    //       {
    //         _id: 1,
    //         icon: "fa-light fa-heart",
    //         isLink: false,
    //         click: () => {},
    //       },
    //       {
    //         _id: 2,
    //         icon: "fa-light fa-bag-shopping",
    //         isLink: false,
    //         click: () => {},
    //       },
    //       {
    //         _id: 3,
    //         icon: "fa-light fa-arrow-right",
    //         isLink: true,
    //         link: "/productdetails",
    //       },
    //     ],
    //   },
    //   {
    //     _id: 3,
    //     name: "California White (Die Cut)",
    //     image: lable_3,
    //     actions: [
    //       {
    //         _id: 1,
    //         icon: "fa-light fa-heart",
    //         isLink: false,
    //         click: () => {},
    //       },
    //       {
    //         _id: 2,
    //         icon: "fa-light fa-bag-shopping",
    //         isLink: false,
    //         click: () => {},
    //       },
    //       {
    //         _id: 3,
    //         icon: "fa-light fa-arrow-right",
    //         isLink: true,
    //         link: "/productdetails",
    //       },
    //     ],
    //   },
    // ],
    subdata: list && list?.data?.length > 0 ? list?.data : [],
  };
  const loaders = Array(4).fill(null);
  return (
    <>
      <div className="md:pt-20 pt-10">
        <div className="md:text-3xl text-2xl font-medium text-slate-900  border-b border-slate-300 pb-4">
          View{" "}
          <span className="text-red-600 font-extrabold">More Products</span>
        </div>
        {list?.loading ? (
          <>
            <div className="grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 xl:gap-8 md:gap-8 gap-5 md:pt-14 pt-10">
              {loaders?.map((_, index) => (
                <ProductLoader key={index} />
              ))}
            </div>
          </>
        ) : (
          featuredProductData?.subdata?.length > 0 && (
            <div className="relative w-full">
              <Swiper
                {...swiperParams}
                // pagination={true}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                modules={[Navigation]}
                className="grid grid-cols-3 gap-16 !py-12 !px-8 !h-full"
              >
                {featuredProductData?.subdata?.map((item, index) => (
                  <SwiperSlide key={index} className="relative !h-auto">
                    <SingleProduct
                      key={index}
                      data={item}
                      lable={lable_3}
                      actions={actions}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="swiper-button-next !text-red-600 md:!-right-10 !-right-0 !z-[9]"></div>
              <div className="swiper-button-prev !text-red-600 md:!-left-10 !-left-0 !text-sm !z-[9]"></div>
            </div>
          )
        )}
      </div>
      <Modal
        size="xl4"
        modalTitle=""
        open={isOpen?.open}
        // onClose={() => setIsOpen({ open: false })}
      >
        <QuickViewModal
          onClose={() => setIsOpen({ open: false })}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
      </Modal>
    </>
  );
};

export default SelectMoreProducts;
