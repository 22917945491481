import React, { useEffect, useState } from "react";
import Tabs from "../../elements/Tabs";
import MyCurrentOrdersDetails from "../MyCurrentOrdersDetails";
import MyAllOrdersDetails from "../MyAllOrdersDetails";
import Input from "../../form/Input";

const MyOrderList = ({
  myorderList = [],
  keyword,
  setKeyword = () => {},
  selectedTabId,
  setSelectedTabId = () => {},
}) => {
  const validOrderList = Array.isArray(myorderList) ? myorderList : [];

  const Processing = validOrderList.filter((order) => order?.status === 1);
  const Accepted = validOrderList.filter((order) => order?.status === 2);
  const OutForDelivery = validOrderList.filter((order) => order?.status === 3);
  const Shipped = validOrderList.filter((order) => order?.status === 4);
  const Cancelled = validOrderList.filter((order) => order?.status === 5);

  const tabData = [
    {
      _id: 1,
      name: "Processing",
      component: <MyCurrentOrdersDetails data={Processing} title={"Processing"} />,
    },
    {
      _id: 2,
      name: "Accepted",
      component: <MyAllOrdersDetails data={Accepted} title={"Accepted"} />,
    },
    {
      _id: 3,
      name: "Out For Delivery",
      component: <MyAllOrdersDetails data={OutForDelivery} title={"Out For Delivery"} />,
    },
    {
      _id: 4,
      name: "Shipped",
      component: <MyAllOrdersDetails data={Shipped} title={"Shipped"} />,
    },

    {
      _id: 5,
      name: "Cancelled",
      component: <MyAllOrdersDetails data={Cancelled} title={"Cancelled"} />,
    },
  ];

  return (
    <>
      <div className="relative">
        <Tabs tabs={tabData} selectedTabId={selectedTabId} onTabChange={(tabId) => setSelectedTabId(tabId)} />
      </div>
    </>
  );
};

export default MyOrderList;
