import React, { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import DefaultHeader from "../shared/DefaultHeader";
import DefaultFooter from "../shared/DefaultFooter";
import { Icons, toast } from "react-toastify";
import { categoryListData } from "../services/frontService/CategoryService";
import { getProfileDetails } from "../services/frontService/ProfileService";
import { setProfileDetails } from "../redux/slice/profileSlice,";
import { useDispatch, useSelector } from "react-redux";
import { userVerifyTokan } from "../services/frontService/Login";
import Modal from "../components/elements/Modal/Modal";
import LoginModal from "../components/elements/loginModal/LoginModal";
import { loginModalOpen } from "../redux/slice/loginSlice";
import Input from "../components/form/Input";
import { setIsLogin } from "../redux/slice/authSlice";
import { cartListData, checkoutDetailsData } from "../services/frontService/ProductService";
import { setCartDetails } from "../redux/slice/cartSlice";
import { setCheckoutDetails } from "../redux/slice/checkoutSlice";
import { siteSettingDetails } from "../services/frontService/SiteSettingDetails";
import { getSettings } from "../redux/slice/settingsSlice";

const DefaultLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginModal = useSelector((state) => state?.loginModal?.data?.isOpen);
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const [lableSubCategory, setLableSubCategory] = useState({
    loading: true,
    data: [],
  });
  const [rjsLableSubCategory, setRjsLableSubCategory] = useState({
    loading: true,
    data: [],
  });

  const [profileDetailsData, setProfileDetailsData] = useState({});
  const { role, customer_id } = useSelector((state) => state.profile.profileData);
  const authToken = localStorage.getItem("auth_token");

  useEffect(() => {
    if (authToken && role == "USER" && customer_id) {
      dispatch(setIsLogin(true));
    } else {
      dispatch(setIsLogin(false));
    }
  }, [authToken, role, customer_id]);

  const loadVerifyToken = useCallback(async () => {
    try {
      const res = await userVerifyTokan();
      if (res?.status === 401) {
        localStorage.removeItem("auth_token");
        navigate("/");
        dispatch(setIsLogin(false));
      } else {
        localStorage.setItem(
          "role",
          res?.data?.data?.role === "admin" ? "ADMIN" : res?.data?.data?.role === "user" ? "USER" : ""
        );
        if (res?.data?.data?.role === "user") {
          dispatch(setIsLogin(true));
        }
      }
    } catch (error) {
      console.error("Error verifying token:", error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("auth_token");
        navigate("/");
        dispatch(setIsLogin(false));
      } else {
        console.error("Unexpected error:", error);
        localStorage.removeItem("auth_token");
        navigate("/");
        dispatch(setIsLogin(false));
      }
    }
  }, []);

  useEffect(() => {
    if (authToken) {
      loadVerifyToken();
    }
  }, [loadVerifyToken, authToken]);

  //  Categroylisttt list >>>>>>>>>
  const loadList = useCallback(() => {
    setLableSubCategory((pre) => ({
      ...pre,
      data: [],
      loading: false,
    }));
    categoryListData({
      // status: 1,
      // featured: 1,
      parent_category_slug: "labels",
    }).then((res) => {
      const additionalItem = {
        parent_category: "Labels",
        parent_category_slug: "labels",
        category_name: "All Wine Labels",
        cat_slug: "all-wine-labels",
      };

      // const updatedData = [additionalItem, ...res?.data];
      const updatedData = [additionalItem, ...(Array.isArray(res?.data) ? res.data : [])];
      if (res && res?.success) {
        setLableSubCategory({
          loading: false,
          data: updatedData?.map((item) => ({
            name: item?.category_name,
            slug: item?.cat_slug,
            parent_category: item?.parent_category,
            parent_category_slug: item?.parent_category_slug,
          })),
        });
      } else {
        setLableSubCategory((pre) => ({ ...pre, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const loadRjsList = useCallback(() => {
    setRjsLableSubCategory((pre) => ({
      ...pre,
      data: [],
      loading: false,
    }));
    categoryListData({
      // status: 1,
      // featured: 1,
      parent_category_slug: "rjs-labels",
    }).then((res) => {
      if (res && res?.success) {
        setRjsLableSubCategory({
          loading: false,
          data: res.data?.map((item) => ({
            name: item?.category_name,
            slug: item?.cat_slug,
            parent_category: item?.parent_category,
            parent_category_slug: item?.parent_category_slug,
          })),
        });
      } else {
        setRjsLableSubCategory((pre) => ({ ...pre, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, []);

  useEffect(() => {
    loadRjsList();
  }, [loadRjsList]);

  const menuData = {
    menu: [
      {
        _id: 1,
        name: "Home",
        link: "/",
        isParent: false,
      },
      {
        _id: 2,
        name: "Labels",
        link: "/label",
        isParent: true,
        subdata: lableSubCategory && lableSubCategory?.data?.length > 0 ? lableSubCategory?.data : [],
      },
      {
        _id: 3,
        name: "RJS Lables",
        link: "/rjs-label",
        isParent: true,
        subdata: rjsLableSubCategory && rjsLableSubCategory?.data?.length > 0 ? rjsLableSubCategory?.data : [],
      },
      {
        _id: 4,
        name: "Cards",
        link: "/cards",
        isParent: false,
      },
      {
        _id: 5,
        name: "Testimonial",
        link: "/testimonial",
        isParent: false,
      },
      {
        _id: 6,
        name: "About Us",
        link: "/aboutus",
        isParent: false,
      },
      {
        _id: 7,
        name: "Contact",
        link: "/contactus",
        isParent: false,
      },
      {
        _id: 8,
        name: "Faqs",
        link: "/faq",
        isParent: false,
      },
    ],
  };

  const getDetails = useCallback(() => {
    setProfileDetailsData({ loading: true, data: {} });
    getProfileDetails().then((res) => {
      if (res?.status === 200) {
        setProfileDetailsData({
          loading: true,
          data: {
            ...res?.data,
            role: "USER",
          },
        });
        dispatch(setProfileDetails({ ...res?.data, role: "USER" }));
      } else {
        setProfileDetailsData({});
        dispatch(setProfileDetails({}));
      }
    });
  }, []);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  //cart list api call

  const getCartList = useCallback(() => {
    cartListData().then((res) => {
      if (res?.status === 200) {
        dispatch(setCartDetails(res?.data));
      } else {
        dispatch(setCartDetails({}));
      }
    });
  }, []);

  useEffect(() => {
    if (isLogin) {
      getCartList();
    }
  }, [getCartList, isLogin]);

  //checkout details api call

  const getCheckoutDetails = useCallback(() => {
    checkoutDetailsData().then((res) => {
      if (res?.status === 200) {
        dispatch(setCheckoutDetails(res?.data));
      } else {
        dispatch(setCheckoutDetails({}));
      }
    });
  }, []);

  useEffect(() => {
    if (isLogin) {
      getCheckoutDetails();
    }
  }, [getCheckoutDetails, isLogin]);

  //Site setting details api call
  const getSiteDetails = useCallback(() => {
    siteSettingDetails().then((res) => {
      if (res?.status === 200) {
        dispatch(getSettings(res?.data));
      }
    });
  }, []);

  useEffect(() => {
    getSiteDetails();
  }, [getSiteDetails]);

  return (
    <>
      <DefaultHeader data={menuData} />
      <Outlet />
      <DefaultFooter lableSubCategory={lableSubCategory} rjsLableSubCategory={rjsLableSubCategory} />
      <Modal
        open={loginModal}
        modalDialogueStyle="!p-0"
        size={"xl4"}
        titleShow={false}
        // onClose={() => {
        //   dispatch(loginModalOpen({ isOpen: false }));
        // }}
        >
        <LoginModal
          onClose={() => {
            dispatch(loginModalOpen({ isOpen: false }));
          }}
          closeModal={() => {
            dispatch(loginModalOpen({ isOpen: false }));
          }}
          getProfile={getDetails}
        />
      </Modal>
    </>
  );
};

export default DefaultLayout;
