import React, { useRef, useState } from "react";
import img from "../../../assets/images/all label/alllabel-10.jpg";
import img2 from "../../../assets/images/admin/folder.avif";
import Image from "../../elements/Image";
import Button from "../../form/Button";
import ShowMediaDetails from "./ShowMediaDetails";
import Dropdown from "../../form/Dropdown";
import { classNames } from "../../../helpers/classNames";
import useClickOutside from "../../../hooks/useClickOutside";
import DeleteModal from "../../common/DeleteModal";
import { toast } from "react-toastify";
import {
  deleteMediaFile,
  deleteMediaFolder,
} from "../../../services/mediaService";

const ShowMediaContent = ({
  isTrash = false,
  open,
  setOpen = () => {},
  folderList,
  setSelectedFolderId = () => {},
  setList = () => {},
  list,
  selectedImages,
  setSelectedImages = () => {},
  mediaDetailsId,
  setMediaDetailsId = () => {},
  loading = true,
  loadList = () => {},
  pagination,
  setCurrentPage = () => {},
  currentPage,
  isModal,
  setIsExpanded = () => {},
  handleRestore = () => {},
  handleFolderRestore = () => {},
  viewMode,
  setViewMode = () => {},
  mediaContentClass,
  selectedFolderDetails,
  setSelectedFolderDetails = () => {},
}) => {
  const preesFolder = useRef();
  const popperElRef = useRef();
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    id: null,
  });
  const [deleteFileModal, setDeleteFileModal] = useState({
    isOpen: false,
    id: null,
  });
  const [deleteComplete, setDeleteComplete] = useState(false);

  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);

  const handleSelectImage = (e, item, index, type) => {
    if (isModal) {
      // Handle Ctrl (Command on Mac) + Click
      if (e.ctrlKey || e.metaKey) {
        const alreadySelected = selectedImages?.find(
          (image) => image.id === item.id
        );
        if (alreadySelected) {
          setSelectedImages((prev) => prev.filter((img) => img.id !== item.id));
        } else {
          setSelectedImages((prev) => [...prev, item]);
        }
        setLastSelectedIndex(index);
      } else if (e.shiftKey && lastSelectedIndex !== null) {
        const start = Math.min(lastSelectedIndex, index);
        const end = Math.max(lastSelectedIndex, index);
        const rangeSelected = list.data.slice(start, end + 1);

        const mergedSelection = [
          ...selectedImages,
          ...rangeSelected.filter(
            (newItem) => !selectedImages?.find((img) => img.id === newItem.id)
          ),
        ];

        setSelectedImages(mergedSelection);
      } else {
        setSelectedImages([item]);
        setLastSelectedIndex(index);
      }
      setOpen(true);

      setMediaDetailsId({ type: type, id: item?.id });
    } else {
      setOpen(true);

      setMediaDetailsId({ type: type, id: item?.id });
    }
  };

  useClickOutside(popperElRef, () => {
    setSelectedImages([]);
  });

  const onDelete = async () => {
    if (!deleteModal?.id) {
      toast.error("No Folder specified for deletion");
      return;
    }
    setOpen(false);
    setDeleteComplete(true);
    try {
      const res = await deleteMediaFolder({ id: deleteModal.id });

      if (res?.status === 200) {
        toast.success(res?.message || "Folder deleted successfully");
        setDeleteModal({ isOpen: false, id: null });
        loadList();
        setCurrentPage(1);
      } else {
        toast.error(res?.message || "Failed to delete folder");
      }
    } catch (error) {
      toast.error(
        error?.message || "An error occurred while deleting the folder"
      );
    } finally {
      setDeleteComplete(false);
    }
  };

  const onFileDelete = async () => {
    if (!deleteFileModal?.id) {
      toast.error("No File  specified for deletion");
      return;
    }
    setOpen(false);
    setDeleteComplete(true);
    try {
      const res = await deleteMediaFile({ id: deleteFileModal.id });

      if (res?.status === 200) {
        toast.success(res?.message || "File deleted successfully");
        setDeleteFileModal({ isOpen: false, id: null });
        setList((prevList) => ({
          ...prevList,
          data: prevList.data.filter((item) => item.id !== deleteFileModal.id),
          totalItem: prevList.totalItem - 1,
        }));
      } else {
        toast.error(res?.message || "Failed to delete file");
      }
    } catch (error) {
      toast.error(
        error?.message || "An error occurred while deleting the file"
      );
    } finally {
      setDeleteComplete(false);
    }
  };

  function onScroll({ scrollTop, clientHeight, scrollHeight }) {
    if (
      Math.ceil(scrollTop) >= scrollHeight - clientHeight &&
      pagination?.hasNextPage
    ) {
      setCurrentPage(currentPage + 1);
    }
  }

  return (
    <>
      {viewMode === "grid" && (
        <div className="mt-8">
          <div
            className={classNames(
              "overflow-auto pb-4 scroll-smooth",
              isTrash
                ? "h-[calc(100vh-230px)] md:h-[calc(100vh-170px)]"
                : "h-[calc(100vh-450px)] md:h-[calc(100vh-296px)]",
              mediaContentClass
            )}
            onScroll={(e) => {
              onScroll({
                scrollTop: e.target.scrollTop,
                clientHeight: e.target.clientHeight,
                scrollHeight: e.target.scrollHeight,
              });
            }}
          >
            <div
              className={classNames(
                "flex flex-wrap gap-4 divide-x divide-slate-200 transition-all duration-300 md:justify-start justify-center"
                // open
                //   ? "3xl:grid-cols-5 grid-cols-3"
                //   : "3xl:grid-cols-6 grid-cols-4"
              )}
            >
              {folderList &&
                Array.isArray(folderList) &&
                folderList?.length > 0 &&
                folderList?.map((item, index) => (
                  <div
                    key={index}
                    className="relative group w-32 md:w-24 lg:w-[108px] 7xl:w-[122px] 8xl:w-[130px] 3xl:!w-[150px] !rounded-md !border-0 shadow-lg"
                  >
                    <button
                      className="relative bg-white rounded-md shadow-lg aspect-square"
                      onDoubleClick={() => {
                        if (!isTrash && preesFolder.current != undefined) {
                          clearTimeout(preesFolder.current);
                        }
                        setSelectedFolderId(item?.id);
                        setSelectedFolderDetails(item);
                        setOpen(false);
                        setCurrentPage(1);
                        // setIsExpanded(false);
                      }}
                      onClick={(e) => {
                        if (!isTrash && e.detail === 1) {
                          preesFolder.current = setTimeout(() => {
                            setOpen(true);
                            // setIsExpanded(false);
                            setMediaDetailsId({ type: "folder", id: item?.id });
                          }, 250);
                        }
                      }}
                    >
                      <Image
                        src={img2}
                        alt=""
                        classes="w-full h-full !object-cover"
                        effect={""}
                      />
                      <div className="relative px-4 py-3 group">
                        <div className="line-clamp-1 text-start !text-sm font-bold text-gray-800 break-all">
                          {item?.name || ""}
                        </div>
                        <div className="absolute bottom-full mb-2 hidden group-hover:block z-10 transform -translate-x-1/2 left-1/2">
                          <div className="bg-red-600 text-white text-[10px] rounded py-1 px-2 whitespace-nowrap shadow-lg  3xl:min-w-36 min-w-28 !max-w-96 text-wrap break-all">
                            {item?.name || ""}
                          </div>
                          <div className="w-3 h-3 bg-red-600 rotate-45 absolute bottom-[-6px]  left-[42%]"></div>
                        </div>
                      </div>

                      {isTrash && (
                        <div className=" flex justify-center pb-3">
                          <button
                            onClick={() => handleFolderRestore(item?.id)}
                            type="button"
                            className={`!text-white text-xs py-1 !shadow-md rounded-lg  font-bold flex items-center px-2 bg-blue-500 gap-2`}
                          >
                            <i class="fa-duotone fa-solid fa-arrows-rotate"></i>
                            <span className="space-x-3 gap-4">Restore</span>
                          </button>
                        </div>
                      )}
                    </button>
                    <div className="absolute top-1 right-2">
                      {!isTrash && (
                        <button
                          onClick={() => {
                            setDeleteModal({
                              isOpen: true,
                              id: item?.id,
                            });
                          }}
                          className="!text-white !shadow-md rounded-lg bg-black/60 hover:text-white  hover:bg-red-600 font-bold py-2  flex items-center transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg px-2 text-sm"
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      )}
                    </div>
                  </div>
                ))}

              {list &&
                Array.isArray(list?.data) &&
                list?.data?.length > 0 &&
                list?.data?.map((item, index) => (
                  <div
                    key={index}
                    // className="relative overflow-hidden group flex-shrink-0"
                    className={`relative  group  w-32 md:w-24 lg:w-[104px] 7xl:w-[122px] 8xl:w-[130px] 3xl:!w-[150px] rounded-md bg-white shadow-md ${
                      selectedImages?.find((img) => img.id === item.id)
                        ? "opacity-50 scale-90"
                        : ""
                    }`}
                  >
                    <button
                      className="relative w-full rounded-md border-none"
                      //  onClick={() => setOpen(true)}
                      onClick={(e) => {
                        if (!isTrash) {
                          handleSelectImage(e, item, index, "file");
                        }
                      }}
                    >
                      <div className="w-full aspect-square overflow-hidden flex flex-shrink-0 rounded-md">
                        <Image
                          src={item?.mediaurl || img}
                          alt=""
                          classes="w-full h-full !object-cover"
                          effect={""}
                        />
                      </div>

                      <div
                        className={`relative px-4 py-3 group ${
                          selectedImages?.find((img) => img.id === item.id)
                            ? "bg-red-200"
                            : "bg-white"
                        }`}
                      >
                        <div className="line-clamp-1 text-start !text-sm font-bold text-gray-800 break-all">
                          {item?.name || ""}
                        </div>
                        <div className="absolute bottom-full mb-2 hidden group-hover:block z-20 transform -translate-x-1/2 left-1/2">
                          <div className="bg-red-600 text-white text-[10px] rounded py-1 px-2 whitespace-nowrap shadow-lg 3xl:min-w-36 min-w-28 !max-w-96 text-wrap break-all">
                            {item?.name || ""}
                          </div>
                          <div className="w-3 h-3 bg-red-600 rotate-45 absolute bottom-[-6px] left-[42%]"></div>
                        </div>
                      </div>

                      {isTrash && (
                        <div className=" flex justify-center pb-3">
                          {/* <button
                      //   onClick={() => handleSetDefault(index)}
                      type="button"
                      className={`!text-white text-xs py-1 !shadow-md rounded-lg  font-bold flex items-center px-2 bg-rose-500`}
                    >
                      <i className="fa-solid fa-trash"></i>
                      <span className="space-x-3 gap-4">Delete</span>
                    </button> */}
                          <button
                            onClick={() => handleRestore(item?.id)}
                            type="button"
                            className={`!text-white text-xs py-1 !shadow-md rounded-lg  font-bold flex items-center px-2 bg-blue-500 gap-2`}
                          >
                            <i class="fa-duotone fa-solid fa-arrows-rotate"></i>
                            <span className="space-x-3 gap-4">Restore</span>
                          </button>
                        </div>
                      )}
                    </button>
                    {!isTrash && (
                      <div className="absolute top-1 right-2 ">
                        {/* <Dropdown
                      buttonLabel={
                        <i class="fa-solid fa-ellipsis-vertical"></i>
                      }
                      buttonLabelClass={
                        "!text-white !shadow-md !rounded-full !px-0"
                      }
                      buttonClass={"!bg-black/60"}
                      dropdownContainer={"!w-36"}
                      data={[
                        // { _id: 1, name: "Copy" },
                        // { _id: 2, name: "Move" },
                        { _id: 3, name: "Delete" },
                      ]}
                    /> */}
                        <button
                          onClick={() => {
                            setDeleteFileModal({
                              isOpen: true,
                              id: item?.id,
                            });
                          }}
                          className="!text-white !shadow-md rounded-lg bg-black/60 hover:text-white  hover:bg-red-600 font-bold py-2  flex items-center transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg px-2 text-sm"
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      </div>
                    )}
                  </div>
                ))}
            </div>
            {loading && (
              <div className="flex items-center justify-center h-screen">
                <div className="w-16 h-16 border-4 border-red-500 border-t-transparent border-solid rounded-full animate-spin"></div>
              </div>
            )}
          </div>
        </div>
      )}
      {viewMode === "list" && (
        <div className="mt-8">
          <div
            className={classNames(
              "overflow-auto pb-4 scroll-smooth",
              isTrash
                ? "h-[calc(100vh-230px)] md:h-[calc(100vh-170px)]"
                : "h-[calc(100vh-450px)] md:h-[calc(100vh-296px)]",
              mediaContentClass
            )}
            onScroll={(e) => {
              onScroll({
                scrollTop: e.target.scrollTop,
                clientHeight: e.target.clientHeight,
                scrollHeight: e.target.scrollHeight,
              });
            }}
          >
            <div
              className={classNames(
                "flex flex-wrap gap-3 divide-x divide-slate-400 transition-all duration-300 md:justify-start justify-center pe-2"
                // open
                //   ? "3xl:grid-cols-5 grid-cols-3"
                //   : "3xl:grid-cols-6 grid-cols-4"
              )}
            >
              {folderList &&
                Array.isArray(folderList) &&
                folderList?.length > 0 &&
                folderList?.map((item, index) => (
                  <div
                    key={index}
                    className="relative group w-full !rounded-md overflow-hidden shadow-lg !border-0 bg-white py-2 px-5"
                  >
                    <button
                      className="relative flex w-full items-center gap-5"
                      onDoubleClick={() => {
                        if (!isTrash && preesFolder.current != undefined) {
                          clearTimeout(preesFolder.current);
                        }
                        setSelectedFolderId(item?.id);
                        setSelectedFolderDetails(item);
                        setOpen(false);
                        setCurrentPage(1);
                        // setIsExpanded(false);
                      }}
                      onClick={(e) => {
                        if (!isTrash && e.detail === 1) {
                          preesFolder.current = setTimeout(() => {
                            setOpen(true);
                            // setIsExpanded(false);
                            setMediaDetailsId({ type: "folder", id: item?.id });
                          }, 250);
                        }
                      }}
                    >
                      <div className="!w-16 aspect-square overflow-hidden">
                        <Image
                          src={img2}
                          alt=""
                          classes="w-full h-full !object-cover"
                          effect={""}
                        />
                      </div>
                      <div className="flex flex-col w-full">
                        <div className="flex items-center">
                          <div className="line-clamp-1 text-start !text-sm font-bold text-gray-800 break-all w-5/12">
                            {item?.name || ""}
                          </div>
                          <div className="line-clamp-1 text-start !text-sm font-medium text-gray-800 break-all w-2/12">
                            {item?.totalFileSize || "0 Kb"}
                          </div>

                          <div className="line-clamp-1 text-center !text-sm font-medium text-gray-800 break-all w-3/12">
                            {item?.totalFolder || 0}
                            <span>
                              {" "}
                              {item?.totalFolder > 1 ? "Folders" : "Folder"}
                            </span>
                          </div>
                          <div className="line-clamp-1 text-center !text-sm font-medium text-gray-800 break-all w-3/12">
                            {item?.totalFile || 0}
                            <span>
                              {" "}
                              {item?.totalFile > 1 ? "Files" : "File"}
                            </span>
                          </div>
                          {isTrash && (
                            <div className=" flex justify-end w-3/12">
                              <button
                                onClick={() => handleFolderRestore(item?.id)}
                                type="button"
                                className={`!text-white text-xs py-1 !shadow-md rounded-lg  font-bold flex items-center px-2 bg-blue-500 gap-2`}
                              >
                                <i class="fa-duotone fa-solid fa-arrows-rotate"></i>
                                <span className="space-x-3 gap-4">Restore</span>
                              </button>
                            </div>
                          )}
                          {!isTrash && (
                            <div className="w-[20%] flex justify-end">
                              {/* <Dropdown
                     buttonLabel={
                       <i class="fa-solid fa-ellipsis-vertical"></i>
                     }
                     buttonLabelClass={
                       "!text-white !shadow-md !rounded-full !px-0"
                     }
                     buttonClass={"!bg-black/60"}
                     dropdownContainer={"!w-36"}
                     data={[
                       // { _id: 1, name: "Copy" },
                       // { _id: 2, name: "Move" },
                       {
                         _id: 3,
                         name: "Delete",
                       },
                     ]}
                     handleSelect={(e) => console.log(e, ",lkkoko")}
                   /> */}
                              {!isTrash && (
                                <button
                                  onClick={() => {
                                    setDeleteModal({
                                      isOpen: true,
                                      id: item?.id,
                                    });
                                  }}
                                  className="!text-white !shadow-md rounded-lg bg-black/60 hover:text-white  hover:bg-red-600 font-bold py-2  flex items-center transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg px-2 text-sm"
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </button>
                  </div>
                ))}

              {list &&
                Array.isArray(list?.data) &&
                list?.data?.length > 0 &&
                list?.data?.map((item, index) => (
                  <div
                    key={index}
                    // className="relative overflow-hidden group flex-shrink-0"
                    className={`relative group w-full !rounded-md overflow-hidden shadow-lg !border-0 bg-white py-2 px-5 ${
                      selectedImages?.find((img) => img.id === item.id)
                        ? "opacity-50 scale-20"
                        : ""
                    }`}
                  >
                    <button
                      className="relative flex gap-5 items-center w-full rounded-md overflow-hidden border-none"
                      //  onClick={() => setOpen(true)}
                      onClick={(e) => {
                        if (!isTrash) {
                          handleSelectImage(e, item, index, "file");
                        }
                      }}
                    >
                      <div className=" w-16 !aspect-square overflow-hidden flex flex-shrink-0 rounded-md">
                        <Image
                          src={item?.mediaurl || img}
                          alt=""
                          classes="w-full h-full !object-cover"
                          effect={""}
                        />
                      </div>

                      <div
                        className={` w-full flex items-center gap-4 ${
                          selectedImages?.find((img) => img.id === item.id)
                            ? ""
                            : "bg-white"
                        }`}
                      >
                        <div className="line-clamp-1 text-start !text-sm font-bold text-gray-800 break-all w-5/12">
                          {item?.name || ""}
                        </div>
                        <div className="line-clamp-1 text-start !text-sm font-medium text-gray-800 break-all w-2/12">
                          {item?.size || "0 Kb"}
                        </div>
                        <div className="line-clamp-1 text-center !text-sm font-medium text-gray-800 break-all w-3/12">
                          {item?.mime_type || ""}
                        </div>
                        {isTrash && (
                          <div className=" flex justify-end w-3/12">
                            <button
                              onClick={() => handleRestore(item?.id)}
                              type="button"
                              className={`!text-white text-xs py-1 !shadow-md rounded-lg  font-bold flex items-center px-2 bg-blue-500 gap-2`}
                            >
                              <i class="fa-duotone fa-solid fa-arrows-rotate"></i>
                              <span className="space-x-3 gap-4">Restore</span>
                            </button>
                          </div>
                        )}
                        {!isTrash && (
                          <div className="w-[20%] w-[20%] flex justify-end">
                            <button
                              onClick={() => {
                                setDeleteFileModal({
                                  isOpen: true,
                                  id: item?.id,
                                });
                              }}
                              className="!text-white !shadow-md rounded-lg bg-black/60 hover:text-white  hover:bg-red-600 font-bold py-2  flex items-center transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg px-2 text-sm"
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        )}
                      </div>
                    </button>
                  </div>
                ))}
            </div>
            {loading && (
              <div className="flex items-center justify-center h-screen">
                <div className="w-16 h-16 border-4 border-red-500 border-t-transparent border-solid rounded-full animate-spin"></div>
              </div>
            )}
          </div>
        </div>
      )}

      <DeleteModal
        open={deleteModal?.isOpen}
        close={() => setDeleteModal({ isOpen: false })}
        loading={deleteComplete}
        deleteHandler={onDelete}
      />
      <DeleteModal
        open={deleteFileModal?.isOpen}
        close={() => setDeleteFileModal({ isOpen: false })}
        loading={deleteComplete}
        deleteHandler={onFileDelete}
      />
    </>
  );
};

export default ShowMediaContent;
