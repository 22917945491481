export const status_filter = [
  { value: 1, name: "Active", type: "checkbox" },
  { value: 2, name: "In-active", type: "checkbox" },
];
export const featured_filter = [
  { value: 1, name: "Featured", type: "checkbox" },
  { value: 2, name: "Non-featured", type: "checkbox" },
];
export const email_filter = [
  { value: 1, name: "Verified", type: "checkbox" },
  { value: 2, name: "Not-verified", type: "checkbox" },
];
export const review_status_filter = [
  { value: 1, name: "Approved", type: "checkbox" },
  { value: 2, name: "Not Approved", type: "checkbox" },
];
export const order_status_filter = [
  { value: 1, name: "Processing", type: "checkbox" },
  { value: 2, name: "Accepted", type: "checkbox" },
  { value: 3, name: "Out for Delivery", type: "checkbox" },
  { value: 4, name: "Shipped", type: "checkbox" },
  { value: 5, name: "Cancel", type: "checkbox" },
];
export const review_by_status_filter = [
  { value: 1, name: "User", type: "checkbox" },
  { value: 2, name: "Admin", type: "checkbox" },
];
