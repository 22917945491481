import React from "react";
import Image from "../elements/Image";
import image from "../../assets/images/cards/card-inside-3.webp";
import Button from "../form/Button";
import SingleCartHover from "../Items/SingleCartHover";
import { checkoutDetailsData } from "../../services/frontService/ProductService";
import { setCheckoutDetails } from "../../redux/slice/checkoutSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CartHover = ({ myCartData, orderSummary }) => {
  const cartListData = myCartData?.cartList?.slice(0, 5);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBuyNow = () => {
    if (!orderSummary?.placeOrder) {
      toast.error(
        `Minimum order size should ${orderSummary?.minimumOrderSize} or more than ${orderSummary?.minimumOrderSize} to place an order. `
      );
      return;
    }
    checkoutDetailsData().then((res) => {
      if (res?.status === 200) {
        dispatch(setCheckoutDetails(res?.data));
      } else {
        dispatch(setCheckoutDetails({}));
      }
    });
    navigate("/checkout");
  };

  return (
    <>
      <div className="relative w-full bg-white rounded-md shadow-md md:p-8 p-4">
        {cartListData?.length > 0 && (
          <div className="relative space-y-4">
            {cartListData?.map((item, index) => (
              <SingleCartHover key={index} data={item} />
            ))}
            {!orderSummary?.placeOrder && (
              <div className="relative space-y-1 p-2 shadow-md rounded-md bg-gray-50 border-2 border-dotted border-gray-400">
                <p className="flex justify-center font-bold text-sm text-red-600">
                  Minimum order size is {orderSummary?.minimumOrderSize}
                </p>
                <p className="flex justify-center font-semibold text-xs">(Masks & packages of Labels combined)</p>
              </div>
            )}
          </div>
        )}

        <div className="flex flex-wrap pt-6 w-full justify-center md:space-x-5 space-x-0 px-0 md:space-y-0 space-y-2">
          <div className="md:w-auto w-full">
            <Button
              buttonClasses={
                "md:!w-auto !w-full !px-4 !h-10 !text-3xl !text-white !text-xl hover:!bg-red-600 hover:!border-black !text-center bg-black gap-3 !rounded-md  transition duration-300 rounded-none"
              }
              buttonLabel="Go To Cart"
              buttonLabelClasses=""
              buttonEffect={"filled"}
              buttonHasLink={true}
              buttonLink={"/cart"}
            />
          </div>
          <div className="md:w-auto w-full">
            <Button
              buttonClasses={
                "md:!w-auto !w-full !px-4 !h-10 !text-3xl !text-white !text-xl hover:!bg-black hover:!border-black !text-center bg-red-600 gap-3 !rounded-md  transition duration-300 rounded-none border border-red-600"
              }
              buttonLabel="Proceed To Checkout"
              buttonLabelClasses=""
              buttonEffect={"filled"}
              buttonHasLink={false}
              buttonFunction={handleBuyNow}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CartHover;
