import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setTitle } from "../../../helpers/MetaTag";
import { addEditVat, vatDetails } from "../../../services/vatService";
import SelectDropdownSearch from "../../../components/form/SelectDropdownSearch";
import { getStateList } from "../../../services/userService";

export const AddEditVat = () => {
  const location = useLocation();
  setTitle("HJL DESIGNS");
  const { id } = useParams();
  const validation = {
    state: { required: true, message: "Please enter your state name !!!" },
    price: { required: true, message: "Please enter your GST/HST (%) !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [companyImage, setCompanyImage] = useState();
  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });
  const [statusKeyword, setStatusKeyword] = useState("");
  const [stateKeyword, setStateKeyword] = useState("");
  const [stateList, setStateList] = useState([]);

  const getDetails = useCallback(() => {
    if (id) {
      setDetailsData({ loading: true, data: {} });
      vatDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          setFieldsValue({
            state:
              {
                name: res?.docs?.stateInfo?.name ? res?.docs?.stateInfo?.name : null,
                _id: res?.docs?.stateInfo?.id ? res?.docs?.stateInfo?.id : null,
              } || "",
            price: res?.docs?.price || "",
            status:
              {
                name: res?.docs?.status ? res?.docs?.status : null,
                _id: res?.docs?.status ? res?.docs?.status : null,
              } || "",
          });
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      if (!id) {
        const res = await addEditVat({
          // id: id,
          states_id: values?.state?._id || "",
          price: values?.price || "",
          status: values?.status?._id || "",
        });

        if (res?.data?.status === 200 || res?.data?.status === 201) {
          navigate("/admin/vat-list");
          toast.info(res?.data?.message);
        } else {
          toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
        }
      } else {
        const res = await addEditVat({
          id: id,
          states_id: values?.state?._id || "",
          price: values?.price || "",
          status: values?.status?._id || "",
        });

        if (res?.data?.status === 200 || res?.data?.status === 201) {
          navigate(`/admin/vat-list${location.search}`);
          toast.info(res?.data?.message);
        } else {
          toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
        }
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  const loadStateList = useCallback(() => {
    getStateList({
      keyword: stateKeyword,
      perPage: 9999,
    }).then((res) => {
      if (res && res?.success) {
        setStateList(res?.data);
      }
    });
  }, [stateKeyword]);

  useEffect(() => {
    loadStateList();
  }, [loadStateList]);

  const data = [
    { name: "Active", _id: 1 },
    { name: "In-Active", _id: 2 },
  ];

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <PageHeader
          title={detailsData?.data?.id ? "Edit GST/HST" : "Add New GST/HST"}
          headerActions={() => (
            <div className="flex items-center space-x-3">
              <Button
                buttonHasLink={true}
                buttonLink={`/admin/vat-list${location.search}`}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonLabelClasses={"md:block hidden"}
                buttonClasses={"!bg-white !text-primary !border-red-600 !border-2 !text-red-600"}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-red-600 !text-org-silver"}
                buttonLabel={"Save"}
                buttonLabelClasses={"md:block hidden"}
                isDisable={loaded}
              />
            </div>
          )}
        />
        <div className="py-2 md:px-6 px-0">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-5 gap-4 divide-x divide-slate-200 md:px-10 px-0">
              <div className="xl:col-span-6 col-span-5">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative md:col-span-6 col-span-12">
                      <SelectDropdownSearch
                        label={"State Name "}
                        placeholder={"Select a state "}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"state"}
                        isSearch={true}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={handleChange}
                        onSearch={(key) => setStateKeyword(key)}
                        value={values?.state}
                        dropdownData={stateList?.map((item) => ({
                          name: item?.name,
                          _id: item?.id,
                        }))}
                        {...formValidate(errors, "state")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={false}
                        inputGroupIcon={""}
                        inputGroupPosition={"left"}
                        label={"GST/HST (%)"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.price}
                        inputName={"price"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter GST/HST (%)"}
                        {...formValidate(errors, "price")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <SelectDropdownSearch
                        label={"Status  "}
                        placeholder={"Select Status"}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"status"}
                        isSearch={false}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={handleChange}
                        onSearch={(key) => setStatusKeyword(key)}
                        value={values?.status}
                        dropdownData={data}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3 w-full justify-end">
                <Button
                  buttonHasLink={true}
                  buttonLink={`/admin/vat-list${location.search}`}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={"!bg-white !text-primary !border-primary !border-2"}
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-primary !text-org-silver"}
                  buttonLabel={"Save"}
                  isDisable={loaded}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
