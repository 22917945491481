import React, { useState } from "react";
import Button from "../../form/Button";
import SearchBar from "../SearchBar";
import Modal from "../../elements/Modal/Modal";
import FolderAdd from "./FolderAdd";
import FileAdd from "./FileAdd";

const MediaHeaderBtns = ({
  selectedFolderId,
  setSelectedFolderId = () => {},
  loadList = () => {},
  handelRefresh = () => {},
  setKeyword = () => {},
  keyword,
  setCurrentPage = () => {},
  selectedFolderDetails
}) => {
  const [folderModalOpen, setFolderModalOpen] = useState(false);
  const [fileModalOpen, setFileModalOpen] = useState(false);

  console.log(selectedFolderId, "selectedFolderIdselectedFolderId");
  return (
    <>
      <div className="sticky top-2 flex flex-wrap justify-between items-center bg-gray-50 px-4 py-2 rounded-md shadow-md md:mt-auto mt-12 gap-4">
        <div className="flex flex-wrap gap-3">
          <div>
            <Button
              buttonClasses={"!h-10"}
              buttonIcon={"fa-solid fa-cloud-arrow-up"}
              buttonIconPosition={"left"}
              buttonLabel={"Upload"}
              buttonLabelClasses={"!text-sm"}
              buttonHasLink={false}
              buttonFunction={() => setFileModalOpen(true)}
            />
          </div>
          {/* <div>
          <Button
            buttonClasses={"!h-10"}
            buttonIcon={"fa-regular fa-plus"}
            buttonIconPosition={"left"}
            buttonLabel={"Add Form"}
            buttonLabelClasses={""}
          />
        </div> */}
          <div>
            <Button
              buttonClasses={"!h-10"}
              buttonIcon={"fa-solid fa-folder-plus"}
              buttonIconPosition={"left"}
              buttonLabel={"Create Folder"}
              buttonLabelClasses={"!text-sm"}
              buttonHasLink={false}
              buttonFunction={() => setFolderModalOpen(true)}
            />
          </div>
          <div>
            <Button
              buttonClasses={"!h-10"}
              buttonIcon={"fa-regular fa-arrows-rotate"}
              buttonIconPosition={"left"}
              buttonLabel={"Reset"}
              buttonLabelClasses={"!text-sm"}
              buttonHasLink={false}
              buttonFunction={handelRefresh}
            />
          </div>
        </div>
        <div>
          <div className="flex justify-center items-center">
            <input
              type="text"
              onChange={(e) => {
                setKeyword(e.target.value);
                setCurrentPage(1);
              }}
              placeholder="Search..."
              value={keyword}
              className="w-full max-w-md p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            />
            {/* <button
              className="ml-2 bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 transition-colors"
              type="submit"
            >
              Search
            </button> */}
          </div>
        </div>
        <Modal
          open={folderModalOpen}
          title={`Create New Folder (${selectedFolderDetails?.name || "Home"})`}
          size="xl2"
          // onClose={setFolderModalOpen}
          // className={"!pt-5"}
          titleClass={"!pt-3 !ps-2"}
        >
          <FolderAdd
            setFolderModalOpen={setFolderModalOpen}
            selectedFolderId={selectedFolderId}
            setSelectedFolderId={setSelectedFolderId}
            loadList={loadList}
            setCurrentPage={setCurrentPage}
            selectedFolderDetails={selectedFolderDetails}
          />
        </Modal>
        <Modal
          open={fileModalOpen}
          title={`Add New File (${selectedFolderDetails?.name || "Home"})`}
          size="xl4"
          // onClose={setFileModalOpen}
          titleClass={"!pt-3 !ps-2"}
        >
          <FileAdd
            setFileModalOpen={setFileModalOpen}
            selectedFolderId={selectedFolderId}
            setSelectedFolderId={setSelectedFolderId}
            loadList={loadList}
            setCurrentPage={setCurrentPage}
          />
        </Modal>
      </div>
    </>
  );
};

export default MediaHeaderBtns;
