import React, { useState } from "react";
import Tabs from "../../elements/Tabs";
import ProductDescriptionItem from "../../Items/ProductDescriptionItem";
import ProductGalleryItem from "../../Items/ProductGalleryItem";
import ProductDescriptionRatingAdmin from "../../Items/ProductDescriptionRatingAdmin";

const ProductDetailsTab = ({ data, reviewList, activeTab, setActiveTab = () => {} }) => {
  const tabData = [
    {
      _id: 0,
      name: "Description",
      component: <ProductDescriptionItem data={data} />,
    },
    {
      _id: 1,
      name: "Reviews",
      component: <ProductDescriptionRatingAdmin reviewList={reviewList} />,
    },
    {
      _id: 2,
      name: "Gallery",
      component: <ProductGalleryItem data={data} />,
    },
  ];

  const validTabIndex = activeTab >= 0 && activeTab < tabData.length ? activeTab : 0;

  return (
    <>
      <div className="relative md:pt-20 pt-10">
        <div className="App">
          <header className="App-header">
            <h1 className="md:text-3xl text-2xl font-medium mb-4 border-b border-slate-300 pb-4">
              Product <span className="text-red-600 font-extrabold">{tabData[validTabIndex].name}</span>
            </h1>
            <div className="relative tab-container">
              <Tabs tabs={tabData} selectedTabId={validTabIndex} onTabChange={(tabId) => setActiveTab(tabId)} />
            </div>
          </header>
        </div>
      </div>
    </>
  );
};

export default ProductDetailsTab;
