import React from "react";
import Image from "../elements/Image";
import img from "../../assets/images/cards/card-inside-4.webp";
import Review from "../Items/Review";
import { country } from "../../helpers";
import { classNames } from "../../helpers/classNames";

const ProductDetailsText = ({
  data,
  paddingTop,
  ratingPadding,
  ratingPaddingTop,
  reviewList,
  scrollHandler = () => {},
}) => {
  return (
    <>
      <div>
        <div className="text-red-600 py-2 font-bold 3xl:text-4xl text-3xl md:pt-0 pt-5 xl:pt-0">{data?.title}</div>
        <div className="text-lg text-black font-bold"> {data?.productCategoryInfo?.parent_category}</div>
        <div className="text-xl text-red-600 font-bold"> {data?.productCategoryInfo?.category_name}</div>
        <div className="text-2xl text-gray-700 py-2">Packs of {data?.minimum_package_quantity}s</div>
        <div className={classNames("pt-6", ratingPaddingTop)} onClick={() => scrollHandler("review")}>
          <Review
            rating={
              reviewList &&
              Array.isArray(reviewList?.data) &&
              reviewList?.data.length > 0 &&
              reviewList?.data[0]?.rating
            }
            totalReviews={reviewList?.data?.length || 0}
          />
        </div>

        <div
          className={classNames("text-lg pt-5 text-black leading-7", ratingPadding)}
          dangerouslySetInnerHTML={{
            __html: data?.short_description || "N/A",
          }}
          onClick={() => scrollHandler("short_description")}></div>
        <div className={classNames("flex gap-6 pt-5", paddingTop)}>
          <div className="flex gap-2 items-center">
            <div className="3xl:text-4xl text-2xl font-bold text-red-600">{country}</div>
            <div className="3xl:text-4xl text-2xl font-bold text-red-600">{data?.price ? data?.price : null}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetailsText;
