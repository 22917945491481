import React, { useCallback, useEffect, useState } from "react";
import ShowMediaContent from "../../../components/sections/AdminSection/ShowMediaContent";
import ShowMediaDetails from "../../../components/sections/AdminSection/ShowMediaDetails";
import DetailsBreadcrumb from "../../../components/Items/DetailsBreadcrumb";
import MediaHeaderBtns from "../../../components/sections/AdminSection/MediaHeaderBtns";
import MediaGridBtns from "../../../components/sections/AdminSection/MediaGridBtns";
import { classNames } from "../../../helpers/classNames";
import { toast } from "react-toastify";
import { useDebounce } from "../../../hooks/useDebounce";
import { getMediaList } from "../../../services/mediaService";
import DeleteModal from "../../../components/common/DeleteModal";
import MediaSidePanel from "../../../components/sections/AdminSection/MediaSidePanel";
import ShowTrashContent from "../../../components/sections/AdminSection/ShowTrashContent";
import Button from "../../../components/form/Button";

const MediaSection = ({
  isModal = false,
  selectedMedia,
  setSelectedMedia = () => {},
  modalOpen = () => {},
  mediaContentClass,
  modalDescriptionHeight,
}) => {
  const [limit, setLimit] = useState(20);
  const [status, setStatus] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [keyword, setKeyword] = useState("");
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [folderList, setFolderList] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedFolderId, setSelectedFolderId] = useState(0);
  const [breadcrumbList, setBreadcrumbList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [mediaDetailsId, setMediaDetailsId] = useState({ type: "", id: null });
  const [isExpanded, setIsExpanded] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState("all");
  const [selectedFolderDetails, setSelectedFolderDetails] = useState({});

  const search = useDebounce(keyword, 500);

  const handelRefresh = () => {
    setSelectedFolderId({ id: 0 });
    setKeyword("");
    setSelectedImages([]);
    setOpen(false);
    setCurrentPage(1);
  };

  //   loading the media list >>>>>>>>>
  const loadList = useCallback(() => {
    setLoading(true);
    // setList((pre) => ({
    //   ...pre,
    //   loading: true,
    // }));
    // setFolderList([]);

    getMediaList({
      keyword: search,
      perPage: limit,
      page: currentPage,
      id: selectedFolderId,
    }).then((res) => {
      if (res && res?.docs?.success) {
        setSelectedImages([]);
        if (currentPage > 1) {
          setList((prevList) => ({
            ...prevList,
            loading: false,
            data: [...prevList.data, ...(res.docs?.media?.data || [])],
            pageCount: res?.docs?.media?.metadata?.totalPages,
            keyword: search,
            totalItem: res?.docs?.media?.metadata?.totalDocs,
          }));
        } else {
          setList({
            loading: false,
            data: res.docs?.media?.data,
            pageCount: res?.docs?.media?.metadata?.totalPages,
            keyword: search,
            totalItem: res?.docs?.media?.metadata?.totalDocs,
          });
        }

        setFolderList(res?.docs?.folders || []);
        setBreadcrumbList(res?.docs?.breadcum || []);
        setCount(res?.docs?.media.metadata?.totalDocs);
        setPagination({
          hasNextPage: res?.docs?.media?.metadata?.hasNextPage,
          hasPrevPage: res?.docs?.media?.metadata?.hasPrevPage,
        });
        setLoading(false);
      } else {
        setList((pre) => ({ ...pre, data: [], loading: false }));
        toast.error(res?.message);
        setLoading(false);
      }
    });
  }, [limit, status, currentPage, search, selectedFolderId]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const [viewMode, setViewMode] = useState("grid");

  const handleInsertMedia = () => {
    if (selectedImages && Array.isArray(selectedImages) && selectedImages.length > 0) {
      const hasDefault = selectedMedia.some((item) => item.default_status === 1);

      const formatedData = selectedImages.map((item) => ({
        mediaurl: item?.mediaurl,
        id: item?.id,
        default_status: hasDefault ? 0 : item.default_status || 0,
      }));

      setSelectedMedia((pre) => [...pre, ...formatedData]);
      modalOpen(false);
    }
  };

  return (
    <>
      <div
        className={classNames(
          "absolute z-[2] flex-shrink-0 md:h-[calc(100vh-64px)] h-[calc(100vh-120px)]",
          open ? "" : ""
        )}>
        <MediaSidePanel
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          toggleSidebar={toggleSidebar}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          setSelectedFolderId={setSelectedFolderId}
          loadList={loadList}
          setOpen={setOpen}
          setCurrentPage={setCurrentPage}
        />
      </div>
      {selectedMenu === "all" && (
        <div
          className={classNames(
            " transition-all duration-300",
            open ? "pl-0" : `pl-60 ${isExpanded ? "md:!pl-56 !pl-0" : "md:!pl-16 !pl-0"}`
          )}>
          <div className={classNames("xl:px-6 px-3 py-4 w-full", open ? "xl:pl-64 lg:pl-64 md:pl-20 pl-5" : " pl-3")}>
            <MediaHeaderBtns
              selectedFolderId={selectedFolderId}
              setSelectedFolderId={setSelectedFolderId}
              loadList={loadList}
              handelRefresh={handelRefresh}
              setKeyword={setKeyword}
              keyword={keyword}
              setCurrentPage={setCurrentPage}
              selectedFolderDetails={selectedFolderDetails}
            />
          </div>
          <div
            className={classNames(
              "flex w-full relative transition-all duration-300",
              open ? "3xl:pr-80 xl:pr-72 lg:pr-56 lg:pl-60 md:pl-14" : "pr-0"
            )}>
            <div className="px-6 md:py-4 py-2 w-full">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-6">
                  <div className="font-bold text-3xl m-1 text-secondary pb-2">Media</div>
                  <div className="flex gap-2">
                    {selectedImages && selectedImages.length > 0 && (
                      <>
                        <div>
                          <Button
                            buttonClasses={"!h-8 !bg-black"}
                            buttonIcon={"fa-solid fa-xmark"}
                            buttonIconPosition={"right"}
                            buttonLabel={"Cancel"}
                            buttonLabelClasses={""}
                            buttonHasLink={false}
                            buttonFunction={() => {
                              setSelectedImages([]);
                              setOpen(false);
                            }}
                          />
                        </div>
                        <div>
                          <Button
                            buttonClasses={"!h-8 !bg-black"}
                            buttonIcon={"fa-regular fa-arrow-down-to-arc"}
                            buttonIconPosition={"right"}
                            buttonLabel={"Insert"}
                            buttonLabelClasses={""}
                            buttonHasLink={false}
                            buttonFunction={handleInsertMedia}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div>
                  <MediaGridBtns
                    viewMode={viewMode}
                    setViewMode={setViewMode}
                    selectedImages={selectedImages}
                    setSelectedImages={setSelectedImages}
                    setOpen={setOpen}
                    selectedMedia={selectedMedia}
                    setSelectedMedia={setSelectedMedia}
                    modalOpen={modalOpen}
                  />
                </div>
              </div>
              <div className="">
                <DetailsBreadcrumb
                  items={breadcrumbList}
                  setSelectedFolderId={setSelectedFolderId}
                  selectedFolderId={selectedFolderId}
                  setOpen={setOpen}
                  setCurrentPage={setCurrentPage}
                  selectedFolderDetails={selectedFolderDetails}
                  setSelectedFolderDetails={setSelectedFolderDetails}
                  BredcrumbText={"!text-sm"}
                />
              </div>
              <div className="">
                <ShowMediaContent
                  viewMode={viewMode}
                  setViewMode={setViewMode}
                  isModal={isModal}
                  open={open}
                  setOpen={setOpen}
                  loading={loading}
                  list={list}
                  setList={setList}
                  folderList={folderList}
                  setSelectedFolderId={setSelectedFolderId}
                  selectedFolderId={selectedFolderId}
                  selectedImages={selectedImages}
                  setSelectedImages={setSelectedImages}
                  mediaDetailsId={mediaDetailsId}
                  setMediaDetailsId={setMediaDetailsId}
                  loadList={loadList}
                  pagination={pagination}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  setIsExpanded={setIsExpanded}
                  mediaContentClass={mediaContentClass}
                  selectedFolderDetails={selectedFolderDetails}
                  setSelectedFolderDetails={setSelectedFolderDetails}
                />
              </div>
            </div>

            <div
              className={classNames(
                "absolute  h-[calc(100vh-154px)] 3xl:w-80 xl:w-72 w-56 md:top-0 top-[-202px] right-0 transition-all duration-300 flex z-20",
                open ? "translate-x-0" : "translate-x-full"
              )}>
              <ShowMediaDetails
                isOpen={open}
                setIsOpen={setOpen}
                mediaDetailsId={mediaDetailsId}
                setMediaDetailsId={setMediaDetailsId}
                loadList={loadList}
                modalDescriptionHeight={modalDescriptionHeight}
              />
            </div>
          </div>
        </div>
      )}

      {selectedMenu === "trash" && (
        <>
          <div
            className={classNames(
              " transition-all duration-300",
              open ? "pl-0" : `pl-60 ${isExpanded ? "md:!pl-56 !pl-0" : "md:!pl-16 !pl-0"}`
            )}>
            <div
              className={classNames(
                "flex w-full relative transition-all duration-300",
                open ? "xl:pr-80 xl:pl-60 md:pl-14" : "pr-0"
              )}>
              <div className="px-6 md:py-4 py-2 w-full">
                <div className="">
                  <ShowTrashContent
                    viewMode={viewMode}
                    setViewMode={setViewMode}
                    mediaContentClass={mediaContentClass}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MediaSection;
