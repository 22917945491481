import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../../../components/elements/table/Table";
import { featured_filter, status_filter } from "../../../constant/Constant";
import Select from "../../../components/form/Select";
import Input from "../../../components/form/Input";
import Button from "../../../components/form/Button";
import { setTitle } from "../../../helpers/MetaTag";
import DeleteModal from "../../../components/common/DeleteModal";
import { changeProductFeaturedStatus, deleteProduct, getProductList } from "../../../services/productService";
import { getCategoryList } from "../../../services/categoryService";
import SelectDropdownSearch from "../../../components/form/SelectDropdownSearch";
import { useDebounce } from "../../../hooks/useDebounce";
import Modal from "../../../components/elements/Modal/Modal";
import { AddEditProductReview } from "./AddEditProductReview";
import ProductImageSlider from "./ProductImageSlider";

const RJSLabels = ({ taballData }) => {
  const location = useLocation();
  setTitle("HJL");
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState("all");
  const [featured, setFeatured] = useState(" ");
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState({ orderBy: "", ordering: "DESC" });
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [statusFilterData, setStatusFilterData] = useState(status_filter || []);
  const [featuredFilterData, setFeaturedFilterData] = useState(featured_filter || []);
  const [keyword, setKeyword] = useState("");
  const search = useDebounce(keyword, 200);
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [count, setCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    id: null,
  });
  const [deleteComplete, setDeleteComplete] = useState(false);
  const [categoryList, setCategoryList] = useState({ data: [] });
  const [category, setCategory] = useState();
  const [categoryKeyword, setCategoryKeyword] = useState("");
  const [selectStatus, setSelectStatus] = useState();
  const [statusKeyword, setStatusKeyword] = useState("");
  const [selectFeatured, setSelectFeatured] = useState();
  const [featuredKeyword, setFeaturedKeyword] = useState("");
  const [filterSearchKey, setFilterSearchKey] = useState("");
  const [featuredSearchKey, setFeaturedSearchKey] = useState("");
  const [filters, setFilters] = useState({
    status: null,
    featured: null,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [productDetails, setProductDetails] = useState({});
  const [openImage, setOpenImage] = useState({ data: [], isOpen: false });

  const rjslabelsFilter = categoryList?.data?.filter((item) => item?.parent_category_slug === "rjs-labels");

  const data = [
    { name: "All Items", value: "All" },
    { name: "5 Items", value: 5 },
    { name: "10 Items", value: 10 },
    { name: "20 Items", value: 20 },
    { name: "30 Items", value: 30 },
    { name: "50 Items", value: 50 },
    { name: "100 Items", value: 100 },
  ];

  //Page data limit function
  const handleChange = (value) => {
    if (value?.value !== "All") {
      setOffset(0);
      setLimit(value?.value);
      setSelectedItems([]);
    } else {
      setOffset(0);
      setLimit(count);
      setCurrentPage(1);
      setSelectedItems([]);
    }
  };

  useEffect(() => {
    getCategoryList().then((res) => {
      if (res && res?.docs?.status === 200) {
        setCategoryList({
          data: res.docs?.data,
        });
      }
    });
  }, []);

  const handleSelectChange = (val) => {
    setCategory(val?.id);
  };

  const handleSelectStatusChange = (val) => {
    setSelectStatus(val?.id);
  };

  const handleSelectFeaturedChange = (val) => {
    setSelectFeatured(val?.id);
  };

  useEffect(() => {
    if (filterSearchKey) {
      setStatusFilterData(
        status_filter?.filter((item) => item?.name?.toLocaleLowerCase().includes(filterSearchKey?.toLocaleLowerCase()))
      );
    } else {
      setStatusFilterData(status_filter);
    }
  }, [filterSearchKey]);

  useEffect(() => {
    if (featuredSearchKey) {
      setFeaturedFilterData(
        featured_filter?.filter((item) =>
          item?.name?.toLocaleLowerCase().includes(featuredSearchKey?.toLocaleLowerCase())
        )
      );
    } else {
      setFeaturedFilterData(featured_filter);
    }
  }, [featuredSearchKey]);

  const handleFilterChange = useCallback((name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  }, []);

  //   loading the member list >>>>>>>>>
  const loadList = useCallback(() => {
    const status_filter = {};
    if (filters.status && Array.isArray(filters.status) && filters.status.length > 0) {
      filters.status.forEach((item, i) => (status_filter[`status[${i}]`] = item));
    }
    const featured_filter = {};
    if (filters.featured && Array.isArray(filters.featured) && filters.featured.length > 0) {
      filters.featured.forEach((item, i) => (featured_filter[`featured[${i}]`] = item));
    }
    setList((pre) => ({
      ...pre,
      data: [],
      loading: true,
    }));
    let payload = {
      ...sort,
      keyword: search,
      perPage: limit,
      category_id: category?._id || "",
      page: currentPage,
      // status: selectStatus || "",
      // featured: selectFeatured || "",
      parent_category_slug: "rjs-labels",
      ...status_filter,
      ...featured_filter,
    };
    getProductList(payload).then((res) => {
      if (res && res?.docs?.success) {
        setList({
          loading: false,
          data: res.docs?.data,
          pageCount: res?.docs?.metadata?.totalPages,
          keyword: search,
          totalItem: res?.docs?.metadata?.totalDocs,
        });
        setCount(res?.docs?.metadata?.totalDocs);
        setPagination({
          hasNextPage: res?.docs?.metadata?.hasNextPage,
          hasPrevPage: res?.docs?.metadata?.hasPrevPage,
        });
      } else {
        setList((pre) => ({ ...pre, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [limit, sort, currentPage, search, category?._id, filters]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pageParam = params.get("page");
    if (pageParam) {
      setCurrentPage(parseInt(pageParam, 10));
    }
  }, [location.search]);

  // Pagination Logic
  // useEffect(() => {
  //   if (list?.data?.length <= 0 && pagination?.hasNextPage) {
  //     setCurrentPage(currentPage);
  //   } else if (list?.data?.length <= 0 && !pagination?.hasPrevPage) {
  //     setCurrentPage(1);
  //   } else if (list?.data?.length <= 0) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // }, [list?.data.length]);

  //Refresh Function

  const refreshData = () => {
    setLimit(10);
    setKeyword("");
    setSelectedItems([]);
    setCategory({});
  };

  //Table Heading Data >>>>>>>>>

  const tableHeadData = [
    {
      _id: 1,
      name: "Images",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 2,
      name: "Title",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort({ orderBy: "title", ordering: "DESC" });
        } else {
          setSort({ orderBy: "title", ordering: "ASC" });
        }
      },
    },
    {
      _id: 3,
      name: "Category",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort({ orderBy: "category_name", ordering: "DESC" });
        } else {
          setSort({ orderBy: "category_name", ordering: "ASC" });
        }
      },
    },
    {
      _id: 4,
      name: "Quantity",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort({ orderBy: "minimum_package_quantity", ordering: "DESC" });
        } else {
          setSort({ orderBy: "minimum_package_quantity", ordering: "ASC" });
        }
      },
    },
    {
      _id: 5,
      name: "Price(CAD)",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort({ orderBy: "price", ordering: "DESC" });
        } else {
          setSort({ orderBy: "price", ordering: "ASC" });
        }
      },
    },
    {
      _id: 6,
      name: "Status",
      align: "left",
      isFilter: true,
      isFilterSearch: false,
      isSort: false,
      filterData: statusFilterData,
      onFilter: (data) =>
        handleFilterChange(
          "status",
          data?.map((item) => item?.value)
        ),
      filterSearch: (e) => setFilterSearchKey(e),
    },
    {
      _id: 7,
      name: "Featured",
      align: "left",
      isFilter: true,
      isFilterSearch: false,
      isSort: false,
      filterData: featuredFilterData,
      onFilter: (data) =>
        handleFilterChange(
          "featured",
          data?.map((item) => item?.value)
        ),
      filterSearch: (e) => setFeaturedSearchKey(e),
    },
    {
      _id: 8,
      name: "Action",
      align: "left",
      isFilter: false,
      isSort: false,
    },
  ];

  //   Status Change Api Call >>>>>>>>>>

  const handleStatusChange = async (data) => {
    // changeProductStatus({
    //   id: data.id,
    //   status: data.status === 1 ? 2 : 1,
    // }).then((res) => {
    //   if (res?.status === 200) {
    //     setList((pre) => ({
    //       ...pre,
    //       data: pre.data.map((item) =>
    //         item.id === data.id
    //           ? {
    //               ...item,
    //               status: item.status === 1 ? 2 : 1,
    //             }
    //           : item
    //       ),
    //     }));
    //     toast.success("Status Change Successful");
    //   } else {
    //     toast.error("Somthing Went Wrong");
    //   }
    // });
  };

  const handleFeaturedChange = async (data) => {
    changeProductFeaturedStatus({
      id: data.id,
      featured: data.featured === 1 ? 2 : 1,
    }).then((res) => {
      if (res?.status === 200) {
        setList((pre) => ({
          ...pre,
          data: pre.data.map((item) =>
            item.id === data.id
              ? {
                  ...item,
                  featured: item.featured === 1 ? 2 : 1,
                }
              : item
          ),
        }));
        toast.success("Featured Change Successful");
      } else {
        toast.error("Somthing Went Wrong");
      }
    });
  };

  // Multi selected Rows statuschange
  //   const handleActiveStatusToggle = () => {
  //     changeUserStatus({ ids: selectedItems, status: "active" })
  //       .then((res) => {
  //         if (res.data.status === 200) {
  //           setList((prevState) => ({
  //             ...prevState,
  //             data: prevState.data.map((user) =>
  //               selectedItems.includes(user._id) ? { ...user, status: "active" } : user
  //             ),
  //           }));
  //           setSelectedItems([]);
  //           toast.success(res?.data?.message);
  //         } else {
  //           toast.error(res?.data?.message);
  //         }
  //       })
  //       .catch();
  //   };

  //   const handleInactiveStatusToggle = () => {
  //     changeUserStatus({ ids: selectedItems, status: "inActive" })
  //       .then((res) => {
  //         if (res.data.status === 200) {
  //           setList((prevState) => ({
  //             ...prevState,
  //             data: prevState.data.map((user) =>
  //               selectedItems.includes(user._id) ? { ...user, status: "inActive" } : user
  //             ),
  //           }));
  //           setSelectedItems([]);
  //           toast.success(res?.data?.message);
  //         } else {
  //           toast.error(res?.data?.message);
  //         }
  //       })
  //       .catch();
  //   };

  const addNew = () => {
    navigate(`/admin/product-add?selectValue=${taballData}`);
  };
  const editData = (item) => {
    navigate(`/admin/product-edit/${item}${location.search}`);
  };
  const viewData = (item) => {
    navigate(`/admin/product-details/${item}${location.search}`);
  };
  const addProductReview = (item) => {
    setIsOpen(true);
    setProductDetails(item);
  };

  // Table Body Data >>>>>>>>>
  const tableData = list?.data?.map((item, index) => ({
    _id: item?.id,
    rowData: [
      {
        _id: 1,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        // image: item?.productImages?.find((item) => item?.default_status === 1)
        //   ? item?.productImages?.find((item) => item?.default_status === 1)?.imageUrl
        //   : logo,
        data: (
          <>
            {item?.productImages?.find((item) => item?.default_status === 1) && (
              <div
                className="w-10 aspect-square overflow-hidden rounded-full max-w-[140px]"
                onClick={() => setOpenImage({ data: item.productImages || [], isOpen: true })}>
                <img
                  src={item?.productImages?.find((item) => item?.default_status === 1)?.imageUrl}
                  alt=""
                  crossOrigin="anonymous"></img>
              </div>
            )}
          </>
        ),
      },
      {
        _id: 2,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.title || "N/A",
      },
      {
        _id: 3,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.productCategoryInfo?.category_name || "N/A",
      },
      {
        _id: 4,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.minimum_package_quantity || "N/A",
      },
      {
        _id: 5,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.price || "N/A",
      },
      {
        _id: 6,
        type: "status",
        align: "left",
        cellClass: "!min-w-[100px]",
        statusLabel: item?.status === 1 ? "Active" : "In Active",
        statusType: item?.status === 1 ? "info" : "danger",
        functions: () => handleStatusChange(item),
      },
      {
        _id: 7,
        type: "status",
        align: "left",
        cellClass: "!min-w-[100px]",
        statusLabel: item?.featured === 1 ? "Featured" : "Non Featured",
        statusType: item?.featured === 1 ? "info" : "danger",
        functions: () => handleFeaturedChange(item),
      },
      {
        _id: 8,
        type: "action",
        align: "left",
        cellClass: "w-[5%]",
        actionData: [
          {
            _id: 1,
            name: "Add Review",
            icon: "fa-regular fa-plus",
            standout: false,
            onClick: () => addProductReview(item),
          },
          {
            _id: 2,
            name: "Edit",
            icon: "fa-regular fa-pen-to-square",
            standout: false,
            onClick: () => editData(item?.id),
          },
          {
            _id: 3,
            name: "View Details",
            icon: "fa-regular fa-eye",
            standout: false,
            onClick: () => viewData(item?.id),
          },
          {
            _id: 4,
            name: "Delete",
            icon: "fa-regular fa-trash-can",
            standout: true,
            onClick: () => {
              setDeleteModal({
                isOpen: true,
                id: item?.id,
              });
            },
          },
        ],
      },
    ],
  }));

  // data delte api call
  // const onDelete = (item) => {
  //   setDeleteComplete(true);
  //   if (deleteModal?.id) {
  //     try {
  //       deleteProduct({ id: deleteModal?.id }).then((res) => {
  //         if (res?.status === 200) {
  //           toast?.success(res?.message);
  //           setList((prevList) => ({
  //             ...prevList,
  //             data: prevList.data.filter((item) => item.id !== deleteModal.id),
  //           }));
  //           setDeleteModal({
  //             isOpen: false,
  //             id: null,
  //           });
  //         } else {
  //           toast.error(res?.message || res?.error);
  //         }
  //       });
  //       setDeleteComplete(false);
  //     } catch (error) {
  //       setDeleteComplete(false);
  //       // toast.error(error);
  //     }
  //   }
  // };

  const onDelete = async () => {
    if (!deleteModal?.id) {
      toast.error("No product ID specified for deletion");
      return;
    }

    setDeleteComplete(true);

    try {
      const res = await deleteProduct({ id: deleteModal.id });

      if (res?.status === 200) {
        toast.success(res?.message || "Product deleted successfully");

        setList((prevList) => ({
          ...prevList,
          data: prevList.data.filter((item) => item.id !== deleteModal.id),
          totalItem: prevList.totalItem - 1,
        }));

        setDeleteModal({ isOpen: false, id: null });

        if (list.data.length === 1 && pagination.hasNextPage) {
          const nextPageItems = await getProductList({
            ...sort,
            keyword: search,
            perPage: limit,
            page: currentPage + 1,
            category_id: category?._id || "",
            ...status_filter,
            ...featured_filter,
          });

          if (nextPageItems?.docs?.data?.length > 0) {
            const firstNextPageItem = nextPageItems.docs.data[0];

            setList((prevList) => ({
              ...prevList,
              data: [...prevList.data, firstNextPageItem],
              totalItem: prevList.totalItem - 1,
            }));

            setList((prevList) => ({
              ...prevList,
              data: nextPageItems.docs.data.slice(1),
            }));
          }
        }

        if (list.data.length === 1 && currentPage > 1) {
          setCurrentPage((prevPage) => prevPage - 1);
        }

        loadList();
      } else {
        toast.error(res?.message || "Failed to delete product");
      }
    } catch (error) {
      toast.error(error?.message || "An error occurred while deleting the product");
    } finally {
      setDeleteComplete(false);
    }
  };

  return (
    <>
      <div className="w-full py-4">
        <div className="font-bold text-3xl m-1 text-secondary mb-5 border-b border-gray-300 pb-2">RJS Labels</div>
        <div className="mb-6 md:flex block gap-3 md:space-y-0 space-y-2">
          <div className="md:w-40 w-full">
            <Select
              dropdownData={data}
              placeholder={"Select Limit"}
              dropdownButtonClass={"!h-10 !rounded-md "}
              selectedValue={limit}
              onChange={handleChange}
            />
          </div>
          <div className="md:w-80 w-full">
            <Input
              inputType={"text"}
              isInputGroup={true}
              inputGroupPosition={"left"}
              inputGroupIcon={"fa-regular fa-search"}
              inputPlaceholder={"Search"}
              inputClasses={"!pl-3"}
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
                setCurrentPage(1);
                setSelectedItems([]);
              }}
            />
          </div>
          <div className="md:w-60 w-full">
            <SelectDropdownSearch
              dropdownData={
                rjslabelsFilter &&
                rjslabelsFilter?.map((item) => ({
                  name: item?.category_name,
                  _id: item?.id,
                }))
              }
              placeholder={"Select Product Category"}
              dropdownButtonClass={"!h-10 !rounded-md "}
              onChange={(e) => {
                setCategory({
                  name: e.target.value.name,
                  _id: e.target.value._id,
                });
                setCurrentPage(1);
                setSelectedItems([]);
              }}
              isSearch={true}
              onSearch={(key) => setCategoryKeyword(key)}
              value={category}
            />
          </div>

          {/* {selectedItems.length > 0 && (
            <>
              <div className="relative">
                <Button
                  buttonHasLink={false}
                  buttonIcon={"fa-regular fa-badge-check"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-org-green !border-org-border !text-white"}
                  buttonLabel={"Active"}
                  buttonLabelClasses={"font-medium !text-sm"}
                  buttonFunction={handleActiveStatusToggle}
                />
              </div>
            </>
          )}
          {selectedItems.length > 0 && (
            <>
              <div className="relative">
                <Button
                  buttonHasLink={false}
                  buttonIcon={"fa-regular fa-circle-xmark"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-org-red !border-org-border !text-white"}
                  buttonLabel={"In Active"}
                  buttonLabelClasses={"font-medium !text-sm"}
                  buttonFunction={handleInactiveStatusToggle}
                />
              </div>
            </>
          )} */}

          <div className="ml-auto flex justify-end space-x-2">
            <Button
              buttonHasLink={false}
              buttonIcon={"fa-solid fa-arrows-rotate"}
              buttonIconPosition={"left"}
              buttonClasses={
                "!bg-secondary !h-10 !text-white !text-bold hover:!bg-secondary !border-org-theme  hover:!text-white"
              }
              buttonLabel={"Reset"}
              buttonLabelClasses={"font-medium !text-md"}
              buttonFunction={refreshData}
            />
            <Button
              buttonHasLink={false}
              buttonIcon={"fa-regular fa-plus"}
              buttonIconPosition={"left"}
              buttonClasses={
                "!bg-secondary !h-10 !text-white !text-bold hover:!bg-secondary !border-org-theme  hover:!text-white"
              }
              buttonLabel={"Add"}
              buttonLabelClasses={"font-medium !text-md"}
              buttonFunction={addNew}
            />
          </div>
        </div>
        <div className="md:-mx-8 -mx-6">
          <Table
            tableHeadData={tableHeadData}
            tableData={tableData}
            containerClasses={"px-6 "}
            isLoder={list?.loading}
            pageCount={list?.pageCount}
            currentPage={currentPage}
            onPageChange={(val) => {
              setOffset(limit * val?.selected);
              setCurrentPage(val?.selected);
              setSelectedItems([]);
              navigate(`?page=${val?.selected}`);
            }}
            isOrderingDisabled={true}
            originalData={[...list.data]}
            // handleOrdering={handleOrdering}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            isCheckbox={false}
            setOriginalData={(data) => setList((cur) => ({ ...cur, data }))}
          />
        </div>
      </div>
      <DeleteModal
        open={deleteModal?.isOpen}
        close={() => setDeleteModal({ isOpen: false })}
        loading={deleteComplete}
        deleteHandler={onDelete}
      />
      <Modal
        size="xl3"
        modalTitle=""
        open={openImage.isOpen}
        // onClose={setOpenImage({ isOpen: false, data: [] })}
      >
        <ProductImageSlider setOpenImage={setOpenImage} data={openImage?.data} />
      </Modal>
      <Modal open={isOpen} size="xl3">
        <AddEditProductReview onClose={() => setIsOpen(false)} loadList={loadList} productDetails={productDetails} />
      </Modal>
    </>
  );
};

export default RJSLabels;
