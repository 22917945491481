import React, { useCallback, useEffect, useState } from "react";
import RecentOrders from "../../../components/sections/AdminSection/RecentOrders";
import LineChart from "../../../components/Items/AdminPanelItems/LineChart";
import RecentUsers from "../../../components/sections/AdminSection/RecentUsers";
import Button from "../../../components/form/Button";
import { getUserList } from "../../../services/userService";
import { getProductList } from "../../../services/productService";
import { getOrderList } from "../../../services/orderService";
import { getReviewList } from "../../../services/reviewService";
import { Link } from "react-router-dom";
import PageLoader from "../../../components/common/PageLoader";
import { getChartList } from "../../../services/dashboardService";

const Dashboard = () => {
  const [userList, setUserList] = useState({ data: [] });
  const [productList, setProductList] = useState({ data: [] });
  const [orderList, setOrderList] = useState({ data: [] });
  const [reviewList, setReviewList] = useState({ data: [] });
  const [chartList, setChartList] = useState({ data: [] });

  const loadUserList = useCallback(() => {
    setUserList((pre) => ({
      ...pre,
      data: [],
      loading: true,
    }));
    getUserList({
      role: "user",
    }).then((res) => {
      if (res && res?.docs?.success) {
        setUserList({
          loading: false,
          data: res?.docs?.metadata?.totalDocs,
        });
      }
    });
  }, []);

  useEffect(() => {
    loadUserList();
  }, [loadUserList]);

  const loadProductList = useCallback(() => {
    setProductList((pre) => ({
      ...pre,
      data: [],
      loading: true,
    }));
    getProductList().then((res) => {
      if (res && res?.docs?.success) {
        setProductList({
          loading: false,
          data: res?.docs?.metadata?.totalDocs,
        });
      }
    });
  }, []);

  useEffect(() => {
    loadProductList();
  }, [loadProductList]);

  const loadOrderList = useCallback(() => {
    setOrderList((pre) => ({
      ...pre,
      data: [],
      loading: true,
    }));
    getOrderList().then((res) => {
      if (res && res?.docs?.success) {
        setOrderList({
          loading: false,
          data: res?.docs?.metadata?.totalDocs,
        });
      }
    });
  }, []);

  useEffect(() => {
    loadOrderList();
  }, [loadOrderList]);

  const loadReviewList = useCallback(() => {
    setReviewList((pre) => ({
      ...pre,
      data: [],
      loading: true,
    }));
    getReviewList().then((res) => {
      if (res && res?.docs?.success) {
        setReviewList({
          loading: false,
          data: res?.docs?.metadata?.totalDocs,
        });
      }
    });
  }, []);

  useEffect(() => {
    loadReviewList();
  }, [loadReviewList]);

  const loadChartList = useCallback(() => {
    setChartList((pre) => ({
      ...pre,
      data: [],
      loading: true,
    }));

    getChartList({
      dateSearchMin: new Date("2024-01-01").toISOString(),
      dateSearchMax: new Date(new Date("2024-12-31").setUTCHours(23, 59, 59, 999)).toISOString(),
    }).then((res) => {
      if (res && res?.success) {
        setChartList({
          loading: false,
          data: res?.data,
        });
      }
    });
  }, []);

  useEffect(() => {
    loadChartList();
  }, [loadChartList]);

  return (
    <>
      {userList?.loading ? (
        <PageLoader />
      ) : (
        <div className="flex flex-col gap-4 xl:p-2.5 p-0 xl:pl-4 pl-0 mt-4">
          <div className={"relative flex justify-between items-center"}>
            <div className="text-3xl  font-bold">Dashboard</div>
          </div>
          <div className=" grid md:grid-cols-2 grid-cols-1 lg:flex flex-wrap flex-row 2xl:gap-8 gap-4">
            <Link to={"/admin/user-list"}>
              <div className="w-full shadow-md bg-sky-500 rounded-lg px-10 p-4 flex justify-between md:col-span-1 h-full lg:h-32 items-center 2xl:gap-10 gap-4">
                <div className="flex items-center text-white 3xl:w-20 3xl:h-20 w-16 h-16 rounded-full bg-sky-200/50 justify-center">
                  <i className="fa-solid fa-user text-4xl"></i>
                </div>
                <div className="flex flex-col items-end text-white">
                  <h2 className="text-lg font-semibold mb-0 lg:mb-2">User</h2>
                  <p className="text-3xl 3xl:text-4xl font-bold">{userList?.data}</p>
                </div>
              </div>
            </Link>
            <Link to={"/admin/product-list"}>
              <div className="w-full shadow-md bg-pink-500 rounded-lg px-10 p-4 flex justify-between md:col-span-1 h-auto lg:h-32 items-center 2xl:gap-10 gap-4">
                <div className="flex items-center text-white w-20 h-20 rounded-full bg-pink-200/50 justify-center">
                  <i className="fa-brands fa-product-hunt text-5xl"></i>
                </div>
                <div className="flex flex-col items-end text-white">
                  <h2 className="text-lg  font-semibold mb-0 lg:mb-2">Products</h2>
                  <p className="text-3xl 3xl:text-4xl font-bold ">{productList?.data}</p>
                </div>
              </div>
            </Link>
            <Link to={"/admin/order-list"}>
              <div className="w-full bg-blue-800 shadow-md rounded-lg px-10 p-4 flex justify-between md:col-span-1 h-auto lg:h-32 items-center 2xl:gap-10 gap-4">
                <div className="flex items-center text-white w-20 h-20 rounded-full bg-blue-200/50 justify-center">
                  <i class="fa-sharp fa-solid fa-cart-shopping text-4xl"></i>
                </div>
                <div className="flex flex-col items-end text-white">
                  <h2 className="text-lg font-semibold mb-0 lg:mb-2">Orders</h2>
                  <p className="text-3xl 3xl:text-4xl font-bold ">{orderList?.data}</p>
                </div>
              </div>
            </Link>
            <Link to={"/admin/review-list"}>
              <div className="w-full bg-yellow-500 shadow-md rounded-lg px-10 p-4 flex justify-between md:col-span-1 h-auto lg:h-32 items-center 2xl:gap-10 gap-4">
                <div className="flex items-center text-white w-20 h-20 rounded-full bg-yellow-600 justify-center">
                  {/* <i className="fa-solid fa-layer-group text-4xl"></i> */}
                  <i className="fa-solid fa-star-sharp text-4xl"></i>
                </div>
                <div className="flex flex-col items-end text-white">
                  <h2 className="text-lg font-semibold mb-0 lg:mb-2">Reviews</h2>
                  <p className="text-3xl 3xl:text-4xl font-bold ">{reviewList?.data}</p>
                </div>
              </div>
            </Link>
          </div>

          <div className="mt-8 bg-gray-100 flex justify-center items-center">
            <LineChart chartList={chartList} />
          </div>
          {/* <h1 className="text-2xl font-bold text-center my-8">Rounded Pie Chart</h1>
      <RoundChart /> */}

          <div className=" md:flex block gap-4 mt-10">
            <div className="w-full 3xl:w-1/3 xl:w-[47%] p-4 bg-white shadow-lg rounded-lg px-5 min-h-32">
              <div className="flex w-full">
                <p className="w-full text-2xl font-normal mb-3 text-black">
                  Recent <span className="text-red-600 font-bold">User</span>
                </p>
                <div className="w-full flex justify-end">
                  <Button
                    buttonClasses={"!h-8 !px-3 bg-red-600 !font-bold !text-white"}
                    buttonLabel="View All"
                    buttonLabelClasses="  !text-base"
                    buttonIcon={"fa-fw fa-regular fa-eye"}
                    buttonIconPosition={"left"}
                    buttonEffect={"filled"}
                    buttonHasLink={true}
                    buttonLink={"/admin/user-list"}
                  />
                </div>
              </div>
              <hr className="w-full border-1 border-slate-500 mb-4"></hr>
              <div className="max-h-[40rem] overflow-auto">
                <RecentUsers />
              </div>
            </div>

            <div className="w-full 3xl:w-8/12 xl:w-3/5 p-4 bg-white shadow-lg rounded-lg lg:mt-0 mt-4">
              <div className="flex w-full">
                <p className="w-full text-2xl font-normal mb-3 text-black">
                  Recent <span className="text-red-600 font-bold">Orders</span>
                </p>
                <div className="w-full flex justify-end">
                  <Button
                    buttonClasses={"!h-8 !bg-red-600 !text-white !font-bold !px-3"}
                    buttonLabel="View All"
                    buttonLabelClasses="  !text-base  "
                    buttonEffect={"filled"}
                    buttonIcon={"fa-fw fa-regular fa-eye"}
                    buttonIconPosition={"left"}
                    buttonHasLink={true}
                    buttonLink={"/admin/order-list"}
                  />
                </div>
              </div>
              <hr className="w-full border-1 border-slate-500 mb-4"></hr>
              <div className="">
                {/* <RecentOrder/> */}
                <div className="max-h-[40rem] overflow-auto">
                  <RecentOrders />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
