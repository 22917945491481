import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import { setTitle } from "../../../helpers/MetaTag";
import { editSettings, settingsDetails } from "../../../services/sitesettingService";
import TextareaAutoSize from "../../../components/form/TextareaAutoSize";
import TextEditor from "../../../components/form/TextEditor";
import { useDispatch } from "react-redux";
import { getSettings } from "../../../redux/slice/settingsSlice";
import useWindowDimension from "../../../hooks/useWindowDimensions";
import PageLoader from "../../../components/common/PageLoader";

const EditSiteSettings = () => {
  setTitle("HJL DESIGNS");
  const validation = {
    title: { required: true, message: "Please enter your name !!!" },
    email: { required: true, message: "Please enter your email !!!" },
    copyright: { required: true, message: "Please enter your copyright !!!" },
    address: { required: true, message: "Please enter your address !!!" },
    announcement_text: { required: true, message: "Please enter your announcement !!!" },
    order_delivery_charge: { required: true, message: "Please enter your order delivery charge !!!" },
    gst_number: { required: true, message: "Please enter your gst number !!!" },
    minimum_order_quantity: { required: true, message: "Please enter your minimum order quantity !!!" },
    minimum_order: { required: true, message: "Please enter your minimum order !!!" },
    high_light: { required: true, message: "Please enter your high light !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);

  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);
  const [logoImageUrl, setLogoImageUrl] = useState();
  const [faviconImageUrl, setFavIconImageUrl] = useState();
  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });
  const [aboutUs, setAboutUs] = useState();
  const [ourDesign, setOurDesign] = useState();
  const [ourRjsDesign, setOurRjsDesign] = useState();
  const { width, height } = useWindowDimension();

  const getDetails = useCallback(() => {
    setDetailsData({ loading: true, data: {} });
    settingsDetails().then((res) => {
      // dispatch(getSettings(res?.docs));
      if (res?.status === 200) {
        setDetailsData({ loading: false, data: res?.docs });
        setFieldsValue({
          title: res?.docs?.title || "",
          email: res?.docs?.email || "",
          copyright: res?.docs?.copyright || "",
          facebook: res?.docs?.facebook || "",
          instagram: res?.docs?.instagram || "",
          youtube: res?.docs?.youtube || "",
          twitter: res?.docs?.twitter || "",
          google_plus: res?.docs?.google_plus || "",
          pinterest: res?.docs?.pinterest || "",
          order_delivery_charge: res?.docs?.order_delivery_charge || "",
          gst_number: res?.docs?.gst_number || "",
          minimum_order_quantity: res?.docs?.minimum_order_quantity || "",
          minimum_order: res?.docs?.minimum_order || "",
          high_light: res?.docs?.high_light || "",
          about_us: res?.docs?.about_us || "",
          our_design: res?.docs?.our_design || "",
          our_rjs_design: res?.docs?.our_rjs_design || "",
          announcement_text: res?.docs?.announcement_text || "",
          address: res?.docs?.address || "",
          // status: { name: res?.docs?.status, _id: res?.docs?.status } || "",
          // announcement_type: { name: res?.docs?.announcement_type, _id: res?.docs?.announcement_type } || "",
        });
        setAboutUs(res?.docs?.about_us || "");
        setOurDesign(res?.docs?.our_design || "");
        setOurRjsDesign(res?.docs?.our_rjs_design || "");
        setLogoImageUrl(res?.docs?.logoUrl);
        // setFavIconImageUrl(res?.docs?.imageUrl);
      }
    });
  }, []);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await editSettings({
        id: detailsData?.data?.id,
        title: values?.title || "",
        email: values?.email || "",
        copyright: values?.copyright || "",
        facebook: values?.facebook || "",
        instagram: values?.instagram || "",
        youtube: values?.youtube || "",
        twitter: values?.twitter || "",
        google_plus: values?.google_plus || "",
        pinterest: values?.pinterest || "",
        order_delivery_charge: values?.order_delivery_charge || "",
        gst_number: values?.gst_number || "",
        minimum_order_quantity: values?.minimum_order_quantity || "",
        minimum_order: values?.minimum_order || "",
        high_light: values?.high_light || "",
        about_us: aboutUs || "",
        our_design: ourDesign || "",
        our_rjs_design: ourRjsDesign || "",
        announcement_text: values?.announcement_text || "",
        address: values?.address || "",
        // status: values?.status?._id,
        // announcement_type: values?._id,
        logo: logoImageUrl,
        // imageUrl: faviconImageUrl,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.info(res?.data?.message);
        dispatch(getSettings(res?.data));
      } else {
        toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <div>
      {detailsData?.loading ? (
        <PageLoader />
      ) : (
        <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
          <PageHeader
            title={"Site Settings"}
            headerActions={() => (
              <div className="flex items-center space-x-3">
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-primary !text-org-silver"}
                  buttonLabel={"Save"}
                  buttonLabelClasses={"md:block hidden"}
                  isDisable={loaded}
                />
              </div>
            )}
          />
          <div className="py-2 md:px-6 px-0">
            <div className="w-full bg-white rounded-md shadow  py-5 px-4">
              <div className="grid grid-cols-5 gap-4 divide-x divide-slate-200 md:px-5 px-0">
                <div className="xl:col-span-4 col-span-5">
                  <div className="space-y-3">
                    <div className="grid grid-cols-12 gap-4">
                      <div className="relative md:col-span-6 col-span-12">
                        <Input
                          isInputGroup={true}
                          inputGroupIcon={"fa-regular fa-user"}
                          inputGroupPosition={"left"}
                          label={"Title"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.title}
                          inputName={"title"}
                          onChange={handleChange}
                          inputPlaceholder={"Enter Title"}
                          {...formValidate(errors, "title")}
                        />
                      </div>
                      <div className="relative md:col-span-6 col-span-12">
                        <Input
                          isInputGroup={true}
                          inputGroupIcon={"fa-solid fa-envelope"}
                          inputGroupPosition={"left"}
                          label={"Email"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.email}
                          inputName={"email"}
                          onChange={handleChange}
                          inputPlaceholder={"Enter Email"}
                          {...formValidate(errors, "email")}
                        />
                      </div>
                      <div className="relative md:col-span-3 col-span-12">
                        <Input
                          isInputGroup={false}
                          inputGroupIcon={false}
                          inputGroupPosition={"left"}
                          label={"Delivary Charge"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.order_delivery_charge}
                          inputName={"order_delivery_charge"}
                          onChange={handleChange}
                          inputPlaceholder={"Enter Delivary Charge"}
                          {...formValidate(errors, "order_delivery_charge")}
                        />
                      </div>
                      <div className="relative md:col-span-3 col-span-12">
                        <Input
                          isInputGroup={false}
                          inputGroupIcon={false}
                          inputGroupPosition={"left"}
                          label={"GST Number"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.gst_number}
                          inputName={"gst_number"}
                          onChange={handleChange}
                          inputPlaceholder={"Enter GST Number"}
                          {...formValidate(errors, "gst_number")}
                        />
                      </div>
                      <div className="relative md:col-span-3 col-span-12">
                        <Input
                          isInputGroup={false}
                          inputGroupIcon={false}
                          inputGroupPosition={"left"}
                          label={"Per Package Quantity"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.minimum_order_quantity}
                          inputName={"minimum_order_quantity"}
                          onChange={handleChange}
                          inputPlaceholder={"Enter Per Package Quantity"}
                          {...formValidate(errors, "minimum_order_quantity")}
                        />
                      </div>
                      <div className="relative md:col-span-3 col-span-12">
                        <Input
                          isInputGroup={false}
                          inputGroupIcon={false}
                          inputGroupPosition={"left"}
                          label={"Minimum Order Size"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.minimum_order}
                          inputName={"minimum_order"}
                          onChange={handleChange}
                          inputPlaceholder={"Enter Minimum Order Size"}
                          {...formValidate(errors, "minimum_order")}
                        />
                      </div>
                      <div className="relative md:col-span-6 col-span-12">
                        <Input
                          isInputGroup={true}
                          inputGroupIcon={"fa-solid fa-highlighter"}
                          inputGroupPosition={"left"}
                          label={"High Light"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.high_light}
                          inputName={"high_light"}
                          onChange={handleChange}
                          inputPlaceholder={"Enter High Light"}
                          {...formValidate(errors, "high_light")}
                        />
                      </div>
                      <div className="relative md:col-span-6 col-span-12">
                        <Input
                          isInputGroup={true}
                          inputGroupIcon={"fa-solid fa-copyright"}
                          inputGroupPosition={"left"}
                          label={"Copyright"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.copyright}
                          inputName={"copyright"}
                          onChange={handleChange}
                          inputPlaceholder={"Enter Copyright"}
                          {...formValidate(errors, "copyright")}
                        />
                      </div>

                      <div className="relative md:col-span-12 col-span-12">
                        <TextEditor
                          label={"About Us"}
                          labelClasses={"!text-base"}
                          value={aboutUs}
                          onChange={(e) => setAboutUs(e)}
                        />
                      </div>
                      <div className="relative md:col-span-12 col-span-12">
                        <TextEditor
                          label={"Our Design Content "}
                          labelClasses={"!text-base"}
                          value={ourDesign}
                          onChange={(e) => setOurDesign(e)}
                        />
                      </div>
                      <div className="relative md:col-span-12 col-span-12">
                        <TextEditor
                          label={"RJS Design Content"}
                          labelClasses={"!text-base"}
                          value={ourRjsDesign}
                          onChange={(e) => setOurRjsDesign(e)}
                        />
                      </div>
                      {/* <div className="relative md:col-span-6 col-span-12">
                      <div className="relative col-span-6">
                        <SelectDropdownSearch
                          label={"Announcement Status  "}
                          placeholder={"Select Announcement Status  "}
                          xPlacement={"bottom"}
                          buttonArrowIcon={""}
                          selectName={"announcement_type"}
                          isSearch={true}
                          dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                          onChange={handleChange}
                          onSearch={(key) => setKeyword(key)}
                          value={values?.announcement_type}
                          dropdownData={data}
                        />
                      </div>
                    </div> */}
                      <div className="relative md:col-span-12 col-span-12">
                        <TextareaAutoSize
                          isInputGroup={true}
                          inputGroupIcon={"fa-solid fa-user-tie"}
                          inputGroupPosition={"left"}
                          label={"Announcement Text "}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          inputValue={values.announcement_text}
                          inputName={"announcement_text"}
                          onChange={handleChange}
                          inputPlaceholder={"Enter Announcement Text"}
                          {...formValidate(errors, "announcement_text")}
                        />
                      </div>
                      <div className="relative md:col-span-12 col-span-12">
                        <TextareaAutoSize
                          isInputGroup={true}
                          inputGroupIcon={"fa-solid fa-user-tie"}
                          inputGroupPosition={"left"}
                          label={"Company Address "}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          inputValue={values.address}
                          inputName={"address"}
                          onChange={handleChange}
                          inputPlaceholder={"Enter Company Address"}
                          {...formValidate(errors, "address")}
                        />
                      </div>
                      {/* <div className="relative md:col-span-6 col-span-12">
                      <div className="relative col-span-6">
                        <SelectDropdownSearch
                          label={"Status  "}
                          placeholder={"Select Status"}
                          xPlacement={"bottom"}
                          buttonArrowIcon={""}
                          selectName={"status"}
                          isSearch={false}
                          dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                          onChange={handleChange}
                          onSearch={(key) => setKeyword(key)}
                          value={values?.status}
                          dropdownData={data}
                        />
                      </div>
                    </div> */}
                      <div className="relative md:col-span-4 col-span-12">
                        <Input
                          isInputGroup={true}
                          inputGroupIcon={"fa-brands fa-facebook"}
                          inputGroupPosition={"left"}
                          label={"Facebook"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.facebook}
                          inputName={"facebook"}
                          onChange={handleChange}
                          inputPlaceholder={"Enter Facebook Link"}
                          {...formValidate(errors, "facebook")}
                        />
                      </div>
                      <div className="relative md:col-span-4 col-span-12">
                        <Input
                          isInputGroup={true}
                          inputGroupIcon={"fa-brands fa-instagram"}
                          inputGroupPosition={"left"}
                          label={"Instagram"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.instagram}
                          inputName={"instagram"}
                          onChange={handleChange}
                          inputPlaceholder={"Enter Instagram Link"}
                          {...formValidate(errors, "instagram")}
                        />
                      </div>
                      <div className="relative md:col-span-4 col-span-12">
                        <Input
                          isInputGroup={true}
                          inputGroupIcon={"fa-brands fa-youtube"}
                          inputGroupPosition={"left"}
                          label={"Youtube"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.youtube}
                          inputName={"youtube"}
                          onChange={handleChange}
                          inputPlaceholder={"Enter Youtube Link"}
                          {...formValidate(errors, "youtube")}
                        />
                      </div>
                      <div className="relative md:col-span-4 col-span-12">
                        <Input
                          isInputGroup={true}
                          inputGroupIcon={"fa-brands fa-twitter"}
                          inputGroupPosition={"left"}
                          label={"Twitter"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.twitter}
                          inputName={"twitter"}
                          onChange={handleChange}
                          inputPlaceholder={"Enter Twitter Link"}
                          {...formValidate(errors, "twitter")}
                        />
                      </div>
                      <div className="relative md:col-span-4 col-span-12">
                        <Input
                          isInputGroup={true}
                          inputGroupIcon={"fa-brands fa-google-plus-g"}
                          inputGroupPosition={"left"}
                          label={"Google Plus"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.google_plus}
                          inputName={"google_plus"}
                          onChange={handleChange}
                          inputPlaceholder={"Enter Google Plus Link"}
                          {...formValidate(errors, "google_plus")}
                        />
                      </div>
                      <div className="relative md:col-span-4 col-span-12">
                        <Input
                          isInputGroup={true}
                          inputGroupIcon={"fa-brands fa-pinterest"}
                          inputGroupPosition={"left"}
                          label={"Pinterest"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.pinterest}
                          inputName={"pinterest"}
                          onChange={handleChange}
                          inputPlaceholder={"Enter Pinterest Link"}
                          {...formValidate(errors, "pinterest")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-1 col-span-5 pl-4 gap-3 sm:flex xl:block">
                  <div className="relative">
                    <SingleImageUpload
                      label={"Logo"}
                      size={width > 640 && width < 1025 ? "lg" : "full"}
                      image={logoImageUrl}
                      setImage={setLogoImageUrl}
                      accept={["jpg", "png", "jpeg"]}
                    />
                  </div>
                  {/* <div className="relative">
                  <SingleImageUpload
                    label={"Fav Icon"}
                    size={width > 640 && width < 1025 ? "lg" : "full"}
                    image={faviconImageUrl}
                    setImage={setFavIconImageUrl}
                    accept={["jpg", "png", "jpeg"]}
                  />
                </div> */}
                </div>
              </div>
              <div className="mt-8 pt-4 border-t border-slate-200">
                <div className="flex items-center space-x-3">
                  {/* <Button
                  buttonHasLink={true}
                  buttonLink={"/admin/dashboard"}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={"!bg-white !text-primary !border-primary !border-2"}
                /> */}
                  <Button
                    buttonHasLink={false}
                    buttonType={"submit"}
                    buttonIcon={"fa-light fa-floppy-disk"}
                    buttonIconPosition={"left"}
                    buttonClasses={"!bg-primary !text-org-silver"}
                    buttonLabel={"Save"}
                    isDisable={loaded}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditSiteSettings;
