import React, { useCallback, useEffect, useState } from "react";
import Image from "../../../src/components/elements/Image";
import Banner from "../../assets/images/banner/banner.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import MainBannerItems from "../Items/MainBannerItems";
import { getPageData } from "../../services/frontService/PageService";
import BannerLoader from "../loader/BannerLoader";

const MainBanner = () => {
  const [homeData, setHomeData] = useState({});
  const [loading, setLoading] = useState(false);
  const loadPageData = useCallback(() => {
    setLoading(true);
    getPageData({
      slug: "home",
    }).then((res) => {
      if (res?.status === 200) {
        setHomeData(res?.data);
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
  }, []);

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);

  // const MainBannerImg = [
  //   {
  //     _id: 1,
  //     image: Banner,
  //   },
  //   {
  //     _id: 2,
  //     image: Banner,
  //   },
  //   {
  //     _id: 3,
  //     image: Banner,
  //   },
  //   {
  //     _id: 3,
  //     image: Banner,
  //   },
  // ];

  const MainBannerImg =
    homeData && Array.isArray(homeData?.bannerList) && homeData?.bannerList?.length > 0 ? homeData?.bannerList : [];

  return (
    <>
      {/* {loading ? (
        <>
          <div className="!w-full relative pt-32"><BannerLoader></BannerLoader></div>
        </>
      ) : ( */}
      {MainBannerImg?.length > 0 && (
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mainbannerslide relative !h-full !text-black">
          {MainBannerImg?.map((item, index) => (
            <SwiperSlide key={index} className="relative !h-full">
              <MainBannerItems key={index} data={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {/* )} */}
    </>
  );
};

export default MainBanner;
