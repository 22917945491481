import React from "react";
import Image from "../../elements/Image";
import { Link } from "react-router-dom";
import avatar from "../../../assets/images/avatar.png";

const RecentUserItems = ({ data }) => {
  return (
    <>
      <Link
        to={`/admin/user-details/${data?.id}`}
        className="relative flex flex-col md:flex-row items-center md:items-start md:space-x-4 space-y-4 md:space-y-0 p-4 border-b hover:bg-gray-50 transition duration-200">
          <div className="w-2/12">
        <div className="w-16 h-16 md:w-12 md:h-12 rounded-full overflow-hidden">
          <Image
            src={data?.image_name_url || avatar}
            alt={`${data.name}'s avatar`}
            classes="w-full h-full object-cover"
            effect="blur"
          />
        </div>
        </div>

        <div className="flex flex-col md:w-9/12 w-full space-y-2 flex-wrap">
          <div className="text-center md:text-left">
            <div className="text-lg md:text-xl font-bold text-black">{data.name}</div>
            {/* <div className="flex items-end space-x-2">
        <Review
          rating={data?.rating}
          totalReviews="120"
          isReviewShow={false}
          classes="md:flex block"
        />
        <div className="text-xs">{data.date}</div>
      </div> */}
          </div>
          <div className="text-center md:text-left text-sm text-gray-600 break-all">{data.email}</div>
        </div>

        <div
          className={`md:text-sm text-xs font-semibold px-3 py-1 rounded-full  ${
            data.active === 1 ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"
          }`}>
          {data.active === 1 ? (
            <span className="flex items-center gap-1">
              <i className={`fa-solid fa-badge-check text-green-700 text-md`} />
              Active
            </span>
          ) : (
            <span className="flex items-center w-20 gap-1">
              <i className={`fa-solid fa-badge-check text-red-700 text-md`} />
              In-Active
            </span>
          )}
        </div>
      </Link>
    </>
  );
};

export default RecentUserItems;
