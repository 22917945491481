import React, { useCallback, useEffect, useRef, useState } from "react";
import Image from "../../../components/elements/Image";
import UserDetailsTabs from "../../../components/sections/AdminSection/UserDetailsTabs";
import { userViewDetails } from "../../../services/userService";
import { useLocation, useParams } from "react-router-dom";
import { getOrderList } from "../../../services/orderService";
import { getReviewList } from "../../../services/reviewService";
import avatar from "../../../assets/images/avatar.png";
import PageLoader from "../../../components/common/PageLoader";

const UserDetails = () => {
  const location = useLocation();
  const { id } = useParams();
  const [detailsData, setDetailsData] = useState({});
  const [orderList, setOrderList] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [limit, setLimit] = useState({ name: "3 Items", value: 3 });

  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const cardRef = useRef(null);

  const getDetails = useCallback(() => {
    setDetailsData({ loading: true, data: {} });
    userViewDetails(id).then((res) => {
      if (res?.status === 200) {
        setDetailsData({
          loading: false,
          data: res?.docs,
        });
      }
      setDetailsData({ loading: false, data: res?.docs });
    });
  }, []);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const getOrderDetails = useCallback(() => {
    setOrderList({ loading: true, data: [] });
    getOrderList({ user_id: id, perPage: limit?.value, page: currentPage }).then((res) => {
      if (res?.docs?.status === 200) {
        setOrderList({
          loading: false,
          data: res?.docs?.data,
        });
        setCount(res?.docs?.metadata?.totalDocs);
        setPagination({
          hasNextPage: res?.docs?.metadata?.hasNextPage,
          hasPrevPage: res?.docs?.metadata?.hasPrevPage,
        });
      }
      setOrderList({ loading: false, data: res?.docs?.data });
    });
  }, [id, limit, currentPage]);

  useEffect(() => {
    getOrderDetails();
  }, [getOrderDetails]);

  const getRiviewDetails = useCallback(() => {
    setReviewList({ loading: true, data: [] });
    getReviewList({ customer_id: id, perPage: 9999 }).then((res) => {
      if (res?.docs?.status === 200) {
        setReviewList({
          loading: true,
          data: res?.docs?.data,
        });
      }
    });
  }, [id]);

  useEffect(() => {
    getRiviewDetails();
  }, [getRiviewDetails]);

  const billingAddressParts = [
    detailsData?.data?.userBillingAdderssInfo?.address,
    detailsData?.data?.userBillingAdderssInfo?.city,
    detailsData?.data?.userBillingAdderssInfo?.stateInfo?.name,
    detailsData?.data?.userBillingAdderssInfo?.pin,
  ];

  const fullBillingAddress = billingAddressParts.filter(Boolean).join(" , ");

  const shippingAddressParts = [
    detailsData?.data?.userShippingAddressInfo?.location,
    detailsData?.data?.userShippingAddressInfo?.city,
    detailsData?.data?.userShippingAddressInfo?.stateInfo?.name,
    detailsData?.data?.userShippingAddressInfo?.pin,
  ];

  const fullShippingAddress = shippingAddressParts.filter(Boolean).join(" , ");

  const scrollHandler = (type) => {
    if (cardRef.current) {
      const scrollElement = document.querySelector("body");
      switch (type) {
        case "order":
          if (scrollElement) {
            cardRef.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
          }
          setActiveTab(2);
          break;
        case "address":
          if (scrollElement) {
            cardRef.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
          }
          setActiveTab(1);
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      {detailsData?.loading ? (
        <PageLoader />
      ) : (
        <div>
          <div className="text-2xl font-bold mt-8 uppercase">User Details</div>
          <div className="w-full xl:flex block xl:space-y-0 space-y-6 gap-6 mt-6 items-center ">
            <div className="w-full md:flex block lg:space-x-10 md:space-x-5 space-x-0 bg-white lg:px-10 px-5 py-8 rounded-md shadow-md border border-slate-300 items-start">
              <div className="flex justify-center">
                <div className="4xl:w-40 3xl:w-36 lg:w-44 md:w-36 w-32 !aspect-square overflow-hidden rounded-md flex-shrink-0">
                  <Image
                    src={detailsData?.data?.image_name_url || avatar}
                    alt=""
                    className="w-full h-full object-cover"
                    effect="blur"
                  />
                </div>
              </div>
              <div className="flex-shrink flex-grow md:mt-0 mt-2">
                <div className="md:flex block justify-between md:space-y-0 space-y-3">
                  <div className="flex gap-4 items-center lg:justify-start justify-center">
                    <div className="text-red-600 font-bold md:text-3xl text-2xl">{detailsData?.data?.name || ""}</div>
                  </div>
                  <div className="bg-red-100 px-4 py-1 rounded-lg text-red-600 font-bold text-xl text-center">
                    Orders : {orderList?.data?.length || "0"}
                  </div>
                </div>
                <div className=" block 3xl:space-y-4 space-y-2 mt-8 items-center">
                  <div className="3xl:flex block gap-4">
                    <div className="w-full flex 2xl:gap-4 xl:gap-1 gap-2">
                      <div className="md:text-xl text-xl text-red-600 pr-2">
                        <i class="fa-solid fa-envelope"></i>
                      </div>
                      <div className="md:flex block 2xl:gap-4 xl:gap-1 gap-2">
                        <div className="md:text-lg text-sm font-semibold">Email Address:-</div>
                        <div className="md:text-lg text-sm">{detailsData?.data?.email || ""}</div>
                      </div>
                    </div>
                    <div className="w-full flex 2xl:gap-4 xl:gap-1 gap-2 3xl:mt-0 mt-2">
                      <div className="md:text-xl text-xl text-red-600 pr-2">
                        <i class="fa-solid fa-phone"></i>
                      </div>
                      <div className="md:flex block 2xl:gap-4 xl:gap-1 gap-2">
                        <div className="md:text-lg text-sm font-semibold">Phone Number:-</div>
                        <div className="md:text-lg text-sm">{detailsData?.data?.phone || ""}</div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex 2xl:gap-4 xl:gap-1 gap-2 items-center">
                    <div className="md:text-xl text-xl text-red-600 pr-2">
                      <i class="fa-solid fa-location-dot"></i>
                    </div>
                    <div className="md:flex block 2xl:gap-4 xl:gap-1 gap-2">
                      <div className="md:text-lg text-sm font-semibold" onClick={() => scrollHandler("address")}>
                        Address:-
                      </div>
                      <div className="md:text-lg text-sm" onClick={() => scrollHandler("address")}>
                        {fullBillingAddress ? fullBillingAddress : fullShippingAddress}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="text-red-600 font-bold mt-6 text-xl flex">
            <div className="bg-red-100 px-4 py-1 rounded-lg">
              Orders : {orderList?.data?.length || "0"}
            </div>
          </div> */}
          <hr className="border-b-0 border-slate-300 mt-7"></hr>
          <div ref={cardRef}>
            <UserDetailsTabs
              data={detailsData?.data}
              orderList={orderList}
              setOrderList={setOrderList}
              getOrderDetails={getOrderDetails}
              reviewList={reviewList}
              setDetailsData={setDetailsData}
              getDetails={getDetails}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              limit={limit}
              count={count}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default UserDetails;
