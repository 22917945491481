import React, { useCallback, useEffect, useState } from "react";
import ProductInformation from "../components/sections/ProductInformation";
import { useParams } from "react-router-dom";
import { getProductDetails } from "../services/frontService/ProductService";
import { useSelector } from "react-redux";


const ProductDetails = () => {
  const { slug } = useParams();
 
  const [details, setDetails] = useState({ loading: true, data: {} });
  const isLogin = useSelector((state) => state?.auth?.isLogin);


  const getDetails = useCallback(() => {
    if (slug) {
      setDetails({ loading: true, data: {} });
      getProductDetails({ slug }).then((res) => {
        if (res?.status === 200) {
          setDetails({ loading: false, data: res?.docs });
        }else{
          setDetails({ loading: true, data: {} });
        }
      });
    }
  }, [slug, isLogin]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);


  return (
    <div className="xl:pt-44 md:pt-32 pt-20 md:px-auto px-5 ">
      <ProductInformation slug={slug} details={details}/>
    </div>
  );
};

export default ProductDetails;
