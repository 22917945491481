import React, { useState } from "react";
import Input from "../form/Input";
import Select from "../form/Select";
import Checkbox from "../form/Checkbox";
import Button from "../form/Button";
import Modal from "../elements/Modal/Modal";
import BillingAddressModal from "./BillingAddressModal";
import ShippingSavedAddress from "../Items/ShippingSavedAddress";
import Radio from "../form/Radio";
import BillingAddressForm from "./BillingAddressForm";
import { classNames } from "../../helpers/classNames";

const BillingAddressHeading = {
  subheading:
    "Billing <span class='text-red-600 font-extrabold'>Address</span>",
};

const BillingAddress = ({
  billingAddressData,
  sameBillingAddress,
  setSameBillingAddress = () => {},
}) => {
  const [addressDetails, setAddressDetails] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div>
        <div className="flex items-center pt-14 md:pb-3 pb-1 space-x-2">
          <div
            className=" uppercase text-black md:text-2xl text-xl font-medium rounded-md"
            dangerouslySetInnerHTML={{
              __html: BillingAddressHeading?.subheading,
            }}
          ></div>

          <div className="text-2xl">
            <i className="fa-solid fa-circle-question"></i>
          </div>
        </div>
        <hr className="w-full border-1 border-slate-500 "></hr>

        <div className="md:flex block md:space-x-8 space-x-0">
          <div className="py-6">
            <Radio
              radioName={"filter_Radio"}
              radioInputClass={""}
              isChecked={sameBillingAddress}
              radioLabel={"Same as shipping address"}
              radioLableClass={"!text-black"}
              onChange={() => setSameBillingAddress(true)}
            />
          </div>
          <div className="md:py-6 pb-5">
            <Radio
              radioName={"filter_Radio"}
              radioInputClass={""}
              isChecked={!sameBillingAddress}
              radioLabel={"Add Another Billing address"}
              radioLableClass={"!text-black"}
              onChange={() => {
                setSameBillingAddress(false);
              }}
            />
          </div>
        </div>
        {!sameBillingAddress && (
          <>
            {/* <div className="py-3">
              {BillingAddressData?.changeaddress?.length > 0 && (
                <div className="flex space-x-5">
                  {BillingAddressData.changeaddress.map((item, index) => (
                    <ShippingSavedAddress key={index} data={item} inputName={"savedBillingAddress"}  setData={setShippingAddressData}/>
                  ))}
                </div>
              )}
            </div>

            <div className="pt-8">
              <Button
                buttonClasses={"bg-red-600 hover:!bg-black !px-5"}
                buttonLabel="Add Another Billing Address"
                buttonLabelClasses="  !text-base  "
                buttonIcon={"fa-regular fa-plus"}
                buttonIconPosition={"left"}
                buttonEffect={"filled"}
                buttonFunction={() => setIsOpen(true)}
              />
            </div> */}
            <div className="">
              {billingAddressData && (
                <>
                  <div className="flex space-x-5">
                    <div
                      className={classNames(
                        "relative overflow-hidden bg-slate-50 border border-red-600 !shadow-lg rounded-md p-3 z-0"
                      )}
                    >
                      <div className="flex items-center">
                        <div className="flex-1 space-y-1">
                          <div className="flex flex-wrap items-center md:space-x-2 space-x-1">
                            <div className="text-xs font-bold">Address :</div>
                            <div className="md:text-sm text-xs">
                              {billingAddressData?.address || "N/A"}
                            </div>
                          </div>
                          <div className="flex flex-wrap items-center md:space-x-2 space-x-1">
                            <div className="text-xs font-bold">Address Line 2:</div>
                            <div className="md:text-sm text-xs">
                              {billingAddressData?.address2 || "N/A"}
                            </div>
                          </div>
                          <div className="flex flex-wrap items-center md:space-x-2 space-x-1">
                            <div className="text-xs font-bold">City :</div>
                            <div className="md:text-sm text-xs">
                              {billingAddressData?.city || "N/A"}
                            </div>
                          </div>
                          <div className="flex flex-wrap items-center md:space-x-2 space-x-1">
                            <div className="text-xs font-bold">State :</div>
                            <div className="md:text-sm text-xs">
                              {billingAddressData?.stateName || "N/A"}
                            </div>
                          </div>
                          <div className="flex flex-wrap items-center md:space-x-2 space-x-1">
                            <div className="text-xs font-bold">
                              Postalcode :
                            </div>
                            <div className="md:text-sm text-xs">
                              {billingAddressData?.pin || "N/A"}
                            </div>
                          </div>
                        </div>
                        <div className="ml-4 mt-12">
                          <Button
                            buttonClasses={"!bg-transparent !text-red-600 "}
                            buttonLabel=""
                            buttonLabelClasses="!text-base"
                            buttonIcon={"fa-regular fa-pen"}
                            buttonIconPosition={"left"}
                            buttonEffect={"filled"}
                            buttonFunction={() => {
                              setIsOpen(true);
                              setAddressDetails(billingAddressData);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* <ShippingAddressForm></ShippingAddressForm> */}
              {!billingAddressData && (
                <Button
                  buttonClasses={"bg-red-600 hover:!bg-black !px-5"}
                  buttonLabel="Add Address"
                  buttonLabelClasses="!text-base"
                  buttonIcon={"fa-regular fa-plus"}
                  buttonIconPosition={"left"}
                  buttonEffect={"filled"}
                  buttonFunction={() => setIsOpen(true)}
                />
              )}
            </div>
            {/* <BillingAddressForm></BillingAddressForm> */}
          </>
        )}
        <Modal size="xl4" title="Billing Address" open={isOpen} titleClass={"!pt-5 !ps-3"}>
          <BillingAddressModal
            onClose={() => setIsOpen(false)}
            setDetails={setAddressDetails}
            details={addressDetails}
          />
        </Modal>
      </div>
    </>
  );
};

export default BillingAddress;
