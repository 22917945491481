import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import Button from "../../../components/form/Button";
import Image from "../../../components/elements/Image";
import img from "../../../assets/images/all label/alllabel-1.webp";
import { classNames } from "../../../helpers/classNames";

const ProductImageSlider = ({ setOpenImage, data ,imgWidth=""}) => {
  return (
    <div>
      <div className="absolute top-2 right-3">
        <Button
          buttonClasses="!bg-white !h-8 !text-3xl !text-black hover:!text-black !text-xl hover:!bg-gray-200 hover:!border-gray-200 !text-center border border-gray-400 gap-3 transition duration-200 rounded-none leading-none !rounded-md"
          buttonIcon="fa-regular fa-xmark"
          buttonIconPosition="right"
          buttonEffect="filled"
          buttonFunction={() => setOpenImage({ isOpen: false, data: [] })}
        />
      </div>
      <div className="text-2xl text-red-600 border-b border-slate-200 uppercase font-bold">Product Image</div>
      <div>
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className={classNames("mySwiper !w-[32rem] !aspect-square !overflow-hidden !my-6",imgWidth)}>
          {data &&
            Array?.isArray(data) &&
            data?.length > 0 &&
            data?.map((item, index) => (
              <>
                <SwiperSlide>
                  <Image src={item?.imageUrl} alt="" classes="!w-full !h-full !object-contain" />
                </SwiperSlide>
              </>
            ))}
        </Swiper>
      </div>

      {/* Custom Swiper Navigation Styling */}
      <style jsx>{`
        .swiper-button-next,
        .swiper-button-prev {
          color: #1f2937; /* Use Tailwind's gray-800 color */
        }
        .swiper-button-next:hover,
        .swiper-button-prev:hover {
          color: #9ca3af; /* Tailwind's gray-400 color on hover */
        }
      `}</style>

      {/* <div className="text-slate-900 font-bold text-center text-lg">Image Name</div> */}
    </div>
  );
};

export default ProductImageSlider;
