import { apiRequest } from "../util/apiUtils";

export const getChartList = async (params) => {
  try {
    const res = await apiRequest("get", "admin/order/saleReportSummary", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
