import React from "react";
import Button from "../form/Button";
import Image from "../elements/Image";
import { country } from "../../helpers";

const OrderSummeryProductDetails = ({ data }) => {
  const imageData =
    data &&
    Array.isArray(data?.productInfo?.productImages) &&
    data?.productInfo?.productImages.length > 0 &&
    data?.productInfo?.productImages[0];
  return (
    <div>
      <div className="relative flex w-full border-b border-gray-200 pb-4">
        <div className="w-16">
          <div className="w-16 aspect-square overflow-hidden border border-slate-200 shadow-md">
            <Image src={imageData?.imageUrl} alt="product summary" classes="!w-full !h-full !object-cover" />
          </div>
        </div>
        <div className="pl-3 w-full space-y-1">
          <div className="md:text-base !leading-5 text-base text-red-600 font-semibold">
            {data?.productInfo?.title || ""}
          </div>
          <div className="flex items-center space-x-2">
            <div className="md:text-xs text-xs text-gray-900">Quantity :</div>
            <div className="md:text-xs text-xs text-gray-900 uppercase">{data?.quantity || ""}</div>
          </div>
          {/* <div className="flex items-center space-x-2">
            <div className="md:text-xs text-xs text-gray-9  00">Category :</div>
            <div className="md:text-xs text-xs text-gray-900 uppercase">
              Card
            </div>
          </div> */}
        </div>
        <div className="md:text-sm text-sm font-bold text-black flex justify-end w-44 my-auto uppercase pr-7">
          {country + " " + (data?.actual_price.toFixed(2) || 0)}
        </div>
      </div>
    </div>
  );
};

export default OrderSummeryProductDetails;
