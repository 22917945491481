import React, { useState } from "react";
import RecentProductItems from "./RecentProductItems";
import { Link } from "react-router-dom";
import image1 from "../../../assets/images/cards/card-inside-5.webp";
import image2 from "../../../assets/images/cards/card-inside-4.webp";
import nodatafound from "../../../assets/images/nodatafound.png";
import Image from "../../elements/Image";
import Pagination from "../../form/Pagination";

const UserOrders = ({
  orderList,
  setOrderList = () => {},
  getOrderDetails = () => {},
  count,
  limit,
  setCurrentPage = () => {},
  currentPage,
  selectedTabId,
  setSelectedTabId = () => {},
}) => {
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedTabId(2);
  };

  return (
    <>
      {orderList?.data?.length > 0 && (
        <div className="xl:w-full w-full md:px-10 px-5 rounded-md bg-white shadow-md">
          <div className="relative divide-y divide-slate-200">
            {orderList?.data?.map((item, index) => (
              <RecentProductItems
                key={index}
                data={item}
                orderList={orderList}
                setOrderList={setOrderList}
                getOrderDetails={getOrderDetails}
              />
            ))}
          </div>
        </div>
      )}
      {orderList?.data?.length > 0 && (
        <Pagination
          totalItems={count}
          itemsPerPage={limit?.value}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
      {count === 0 && (
        <>
          <div className="flex flex-col items-center md:space-y-4 space-y-2 md:mt-8 mt-4">
            <div className="md:w-28 md:h-28 w-20 h-20 rounded-full bg-[#F0F9F4] overflow-hidden flex items-center justify-center">
              <div className="text-[#e73737] md:text-7xl text-5xl">
                <i className="fa-regular fa fa-exclamation"></i>
              </div>
            </div>
            <div className="text-cape-cod md:text-3xl text-2xl text-center">No Orders Available.</div>
            <div className="text-concord text-base max-w-md text-center">
              Order information not found. Please check the order number or try again later.
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserOrders;
