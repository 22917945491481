import React, { useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";

const Pagination = ({ pageCount = 0, onPageChange = () => {}, currentPage = 1, paginationClasses }) => {
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalPagesList, setTotalPagesList] = useState([]);

  useEffect(() => {
    if (currentPage) {
      setSelectedPage(currentPage);
    } else {
      setSelectedPage(1);
    }
  }, [currentPage]);

  function generatePaginationList(selectedPage, totalPages) {
    const maxPagesToShow = 7;
    const pages = [];

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      const pagesToShow = maxPagesToShow - 2;

      let startPage = Math.max(1, selectedPage - Math.floor(pagesToShow / 2));
      let endPage = Math.min(totalPages, startPage + pagesToShow - 1);

      if (endPage - startPage < pagesToShow - 1) {
        startPage = Math.max(1, endPage - pagesToShow + 1);
      }

      if (startPage > 1) {
        pages.push(1);
        if (startPage > 2) pages.push("...");
      }
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < totalPages) {
        if (endPage < totalPages - 1) pages.push("...");
        pages.push(totalPages);
      }
    }

    return pages;
  }

  useEffect(() => {
    setTotalPagesList(generatePaginationList(selectedPage, pageCount));
  }, [selectedPage, pageCount]);

  const handlePageClick = (page) => {
    if (page === "...") return;
    setSelectedPage(page);
    if (typeof onPageChange === "function") {
      onPageChange({ selected: page });
    }
  };

  const handlePrevClick = () => {
    if (selectedPage > 1) {
      const prevPage = selectedPage - 1;
      setSelectedPage(prevPage);
      if (typeof onPageChange === "function") {
        onPageChange({ selected: prevPage });
      }
    }
  };

  const handleNextClick = () => {
    if (selectedPage < pageCount) {
      const nextPage = selectedPage + 1;
      setSelectedPage(nextPage);
      if (typeof onPageChange === "function") {
        onPageChange({ selected: nextPage });
      }
    }
  };

  return (
    <div className={classNames("flex", paginationClasses)}>
      <div className="flex gap-1 w-fit">
        <div
          className="flex justify-center items-center text-org-theme text-lg cursor-pointer"
          onClick={handlePrevClick}>
          ⏴
        </div>

        {totalPagesList &&
          Array.isArray(totalPagesList) &&
          totalPagesList?.length > 0 &&
          totalPagesList.map((number) => (
            <div
              key={number}
              onClick={() => handlePageClick(number)}
              className={`flex justify-center items-center ${
                number === "..." ? "" : "cursor-pointer font-semibold border border-org-theme w-10 h-10 rounded-md"
              } ${selectedPage === number ? "bg-org-theme text-org-border" : "text-org-theme"}`}>
              {number}
            </div>
          ))}

        <div
          className="cursor-pointer flex justify-center items-center text-org-theme text-lg"
          onClick={handleNextClick}>
          ⏵
        </div>
      </div>
    </div>
  );
};

export default Pagination;
