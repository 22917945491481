import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import LOGO from "../../assets/images/logo.png";

const Image = ({
  src,
  width,
  height,
  alt,
  effect,
  classes,
  isLoader = true,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="relative w-full h-full flex">
      {isLoading && (
        <div
          className={`absolute inset-0 flex items-center justify-center ${
            !isLoader && "hidden"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            width="50"
            height="50"
          >
            <g>
              <rect fill="#D62626" height="40" width="15" y="30" x="17.5">
                <animate
                  begin="-0.2s"
                  keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                  values="18;30;30"
                  keyTimes="0;0.5;1"
                  calcMode="spline"
                  dur="1s"
                  repeatCount="indefinite"
                  attributeName="y"
                ></animate>
                <animate
                  begin="-0.2s"
                  keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                  values="64;40;40"
                  keyTimes="0;0.5;1"
                  calcMode="spline"
                  dur="1s"
                  repeatCount="indefinite"
                  attributeName="height"
                ></animate>
              </rect>
              <rect fill="#030712" height="40" width="15" y="30" x="42.5">
                <animate
                  begin="-0.1s"
                  keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                  values="20.999999999999996;30;30"
                  keyTimes="0;0.5;1"
                  calcMode="spline"
                  dur="1s"
                  repeatCount="indefinite"
                  attributeName="y"
                ></animate>
                <animate
                  begin="-0.1s"
                  keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                  values="58.00000000000001;40;40"
                  keyTimes="0;0.5;1"
                  calcMode="spline"
                  dur="1s"
                  repeatCount="indefinite"
                  attributeName="height"
                ></animate>
              </rect>
              <rect fill="#D62626" height="40" width="15" y="30" x="67.5">
                <animate
                  keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                  values="20.999999999999996;30;30"
                  keyTimes="0;0.5;1"
                  calcMode="spline"
                  dur="1s"
                  repeatCount="indefinite"
                  attributeName="y"
                ></animate>
                <animate
                  keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                  values="58.00000000000001;40;40"
                  keyTimes="0;0.5;1"
                  calcMode="spline"
                  dur="1s"
                  repeatCount="indefinite"
                  attributeName="height"
                ></animate>
              </rect>
              <g></g>
            </g>
          </svg>
        </div>
      )}
      <LazyLoadImage
        src={src}
        width={width}
        height={height}
        crossOrigin="anonymous"
        alt={alt}
        effect={effect}
        className={`w-full h-full ${classes} ${
          isLoading ? "opacity-0" : "opacity-100"
        } transition-opacity duration-500`}
        afterLoad={() => setIsLoading(false)}
        onError={(e) => {
          e.target.src = LOGO;
        }}
      />
    </div>
  );
};

export default Image;
