import React from "react";
import Image from "../../../components/elements/Image";
import Button from "../../../components/form/Button";

export const AdminDetailsModal = ({ onClose = () => {}, details }) => {
  return (
    <>
      <div>
        <div className="flex justify-end">
          <Button
            buttonClasses="!bg-white !h-4 !text-2xl !text-black hover:!text-red-600 !text-center  gap-3 transition duration-200 rounded-md mt-0 sm:mt-0"
            buttonIcon="fa-regular fa-xmark"
            buttonIconPosition="right"
            buttonEffect="filled"
            buttonFunction={onClose}
          />
        </div>
        <div className="max-w-xl mx-auto ">
          <div className=" w-full flex items-center justify-center">
            <div className="relative w-full max-w-8xl mt-4 mb-9  flex flex-col items-start space-y-4 sm:flex-row sm:space-y-0 sm:space-x-6 px-8 py-8 border-2 border-dashed border-gray-400 dark:border-gray-400 shadow-lg rounded-lg justify-center">
              {/* <span className="absolute text-sm font-medium top-0 left-0 rounded-br-lg rounded-tl-lg px-1 py-0 bg-primary-100 dark:bg-red-600 dark:text-gray-300 border-gray-400 dark:border-gray-400 border-b-2 border-r-2 border-dashed ">
              admin
            </span> */}

              <div class="!w-1/3 flex justify-center sm:justify-start sm:w-36">
                <Image
                  src={details?.image_name_url || ""}
                  alt=""
                  className="w-full h-full object-cover"
                  effect="blur"
                />
              </div>

              <div className="!w-full sm:w-auto flex flex-col items-center sm:items-start">
                <p className="font-display mb-2 text-2xl font-bold dark:text-gray-200 text-red-600" itemprop="author">
                  {details?.name || ""}
                </p>

                <div className="mb-4 md:text-lg text-gray-600">
                  <p className="flex items-center">
                    <span className="text-xl md:text-xl text-red-600 pr-4">
                      <i className="fa-solid fa-envelope"></i>
                    </span>
                    <span>{details?.email || ""}</span>
                  </p>
                  <p className="flex items-center">
                    <span className="text-xl md:text-xl text-red-600 pr-4">
                      <i className="fa-solid fa-phone"></i>
                    </span>
                    <span>{details?.phone || ""}</span>
                  </p>
                </div>
                <div className="flex gap-2 !justify-end !w-full">
                  <Button
                    buttonHasLink={true}
                    buttonLink={`/admin/admin-edit/${details?.id}`}
                    buttonType={"submit"}
                    buttonIcon={"fa-light fa-pen-to-square"}
                    buttonIconPosition={"left"}
                    buttonClasses={"!bg-red-600 !text-org-silver"}
                    buttonLabel={"Edit"}
                    buttonLabelClasses={"md:block hidden"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
