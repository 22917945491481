import React, { useEffect, useState } from "react";
import PaymentMethod from "../components/sections/PaymentMethod";
import OrderSummary from "../components/sections/OrderSummary";
import ShippingAddress from "../components/sections/ShippingAddress";
import DetailsBreadcrumb from "../components/Items/DetailsBreadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { loginModalOpen } from "../redux/slice/loginSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addWishlistProduct, newPlaseOrder } from "../services/frontService/ProductService";
import { setCartDetails } from "../redux/slice/cartSlice";

const items = [
  { label: "Home", href: "/" },
  { label: "Shipping Address", href: "/checkout" },
];

const PaymentInformation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = location;
  const myCheckoutData = useSelector((state) => state?.checkout?.checkoutData);
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const [loaded, setLoaded] = useState(false);
  const myCartList = useSelector((state) => state?.cart?.cartListData?.cartList);

  const orderSummary = myCheckoutData ? myCheckoutData?.orderSummary : {};

  const TOKEN_LENGTH = 22;

  function removeHelcimPayIframe() {
    const frame = document.getElementById("helcimPayIframe");
    if (frame instanceof HTMLIFrameElement) {
      frame.remove();
      window.removeEventListener("message", watchForExit, false);
    }
  }

  function watchForExit(event) {
    if (typeof event?.data?.eventName === "string" && !event.data.eventName.includes("helcim-pay-js")) {
      return;
    }
    if (typeof event?.data?.eventStatus === "string" && event?.data?.eventStatus === "HIDE") {
      removeHelcimPayIframe();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function appendHelcimPayIframe(token, showExitButton = true, phone = "", email = "", csrf = "") {
    if (token === null || token === "undefined" || token.length !== TOKEN_LENGTH) {
      console.error("Invalid checkout token.");
      return;
    }

    const dev =
      window.location.hostname.endsWith(".helcim.test") || window.location.hostname.endsWith(".myhelcim.test");

    const domain = !dev ? "secure.helcim.app" : "secure-app.helcim.test";
    const hasExitButtonQuery = showExitButton ? "?allowExit" : "";
    const phoneQuery = phone ? `&phone=${phone}` : "";
    const emailQuery = email ? `&email=${email}` : "";
    const url = `${window.location.protocol}//${domain}/helcim-pay/${token}${hasExitButtonQuery}${phoneQuery}${emailQuery}`;

    const iframeElem = document.createElement("iframe");
    iframeElem.name = csrf;
    iframeElem.id = "helcimPayIframe";
    iframeElem.src = url;
    iframeElem.width = "100%";
    iframeElem.height = "100%";
    iframeElem.frameBorder = "0";
    iframeElem.style = "position: fixed; top: 0; left: 0; border: none; z-index: 998;";

    if (showExitButton) {
      window.addEventListener("message", watchForExit, false);
    }

    return document.body.appendChild(iframeElem);
  }

  const onPlaceOrder = async () => {
    if (!orderSummary?.placeOrder) {
      toast.error(
        `Minimum order size should ${orderSummary?.minimumOrderSize} or more than ${orderSummary?.minimumOrderSize} to place an order.`
      );
      return;
    }

    if (isLogin) {
      setLoaded(true);
      try {
        const res = await newPlaseOrder({
          shippingLocation: state?.shippingAddress?.location,
          shippingState: state?.shippingAddress?.state,
          shippingCity: state?.shippingAddress?.city,
          shippingPin: state?.shippingAddress?.pin,
          billingAddress: state?.billingAddress?.address
            ? state?.billingAddress?.address
            : state?.billingAddress?.location,
          billingAddress2: state?.billingAddress?.address2 ? state?.billingAddress?.address2 : null,
          billingState: state?.billingAddress?.state,
          billingCity: state?.billingAddress?.city,
          billingPin: state?.billingAddress?.pin,
          cardHolderName: "Pilu Malo",
          cardNumber: "5492974492545090",
          validThrough: "10/28",
          cvvCode: "790",
          cardHolderAddress: "1/18 Poddarnagar, Jadavpur",
          cardHolderPostalcode: "700025",
          name: state?.contactDetails?.name,
          email: state?.contactDetails?.email,
          phone: state?.contactDetails?.phone,
        });

        if (res?.data?.status === 200 || res?.data?.status === 201) {
          toast.info(res?.data?.message);
          navigate("/user-orders");
          dispatch(setCartDetails(res?.data));
          const paymentData = res?.data?.data;
          // appendHelcimPayIframe(paymentData?.checkoutToken);
          // window.addEventListener('message', handleHelcimPayResponse(paymentData?.checkoutToken));
        } else {
          toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
        }

        setLoaded(false);
      } catch (err) {
        setLoaded(false);
        // console.error(err);
        toast.error("Somthing went wrong !!!");
      }
    } else {
      dispatch(loginModalOpen({ isOpen: true }));
    }
  };

  const handleHelcimPayResponse = (checkoutToken) => (event) => {
    const helcimPayJsIdentifierKey = "helcim-pay-js-" + checkoutToken;

    if (event.data.eventName === helcimPayJsIdentifierKey) {
      if (event.data.eventStatus === "ABORTED") {
        console.error("Transaction failed!", event.data.eventMessage);
        toast.error("Transaction failed: " + event.data.eventMessage);
      }

      if (event.data.eventStatus === "SUCCESS") {
        console.log("Transaction success!", event.data.eventMessage);
        toast.success("Transaction success: " + event.data.eventMessage);
        // navigate('/user-orders');
      }
    }
  };

  useEffect(() => {
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("message", handleHelcimPayResponse);
    };
  }, []);

  return (
    <>
      <div className="relativew-full sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto xl:mt-40 md:mt-32 mt-20">
        {/* <div className="pb-5 md:mx-0 mx-5">
          <DetailsBreadcrumb items={items} />
        </div> */}
        <div className=" xl:flex block ">
          <PaymentMethod
            onPlaceOrder={onPlaceOrder}
            loaded={loaded}
            setLoaded={setLoaded}
            myCartList={myCartList}
            myCheckoutData={myCheckoutData}
          />
          <OrderSummary myCheckoutData={myCheckoutData} />
        </div>
      </div>
    </>
  );
};

export default PaymentInformation;
