import React from "react";
import MediaSection from "./MediaSection";

const MediaList = () => {
  return (
    <div className="overflow-hidden flex-grow w-full">
      <MediaSection />
      {/* <div className="w-full bg-slate-400 py-5">kjscjn</div>
      <div className="flex">
        <div className="w-60 h-screen !bg-slate-700 p-4">kdchjscn</div>
        <div className="w-full h-screen !bg-slate-200 p-4">kdchjscn</div>
        <div className="w-60 h-screen !bg-slate-400 p-4">kdchjscn</div>
      </div> */}
    </div>
  );
};

export default MediaList;
