import React, { useCallback, useEffect, useState } from "react";
import Image from "../../elements/Image";
import img from "../../../assets/images/all label/alllabel-10.jpg";
import img2 from "../../../assets/images/admin/folder.avif";
import Button from "../../form/Button";
import moment from "moment/moment";
import {
  getFolderDetails,
  getMediaDetails,
  updateFile,
} from "../../../services/mediaService";
import HideableInput from "../../form/HideableInput";
import { toast } from "react-toastify";
import { classNames } from "../../../helpers/classNames";
import Modal from "../../elements/Modal/Modal";
import ProductImageSlider from "../../../pages/adminPannel/product/ProductImageSlider";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const ShowMediaDetails = ({
  setIsOpen = () => {},
  mediaDetailsId,
  setMediaDetailsId = () => {},
  loadList = () => {},
  modalDescriptionHeight,
}) => {
  const [copied, setCopied] = useState(false);
  const [mediaDetails, setMediaDetails] = useState({});
  const [folderDetails, setFolderDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [fileName, setFileName] = useState(mediaDetails?.name);
  // const fancyboxParameter = fancybox
  //   ? { "data-fancybox": fancyboxAttribute }
  //   : {};
  useEffect(() => {
    Fancybox.bind("[data-fancybox='gallery']");
  }, []);

  const getDetails = useCallback(() => {
    setMediaDetails({});
    const id = mediaDetailsId?.id;
    if (id && mediaDetailsId?.type === "file") {
      setLoading(true);
      getMediaDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setMediaDetails(res?.data);
          setFileName(res?.data?.name);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }
  }, [mediaDetailsId]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const viewFolderDetails = useCallback(() => {
    setFolderDetails({});
    const id = mediaDetailsId?.id;
    if (id && mediaDetailsId?.type === "folder") {
      setLoading(true);
      getFolderDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setFolderDetails(res?.data);
          setLoading(false);
        }
      });
    }
  }, [mediaDetailsId]);

  useEffect(() => {
    viewFolderDetails();
  }, [viewFolderDetails]);

  const handleCopy = () => {
    if (mediaDetails?.mediaurl) {
      navigator.clipboard.writeText(mediaDetails.mediaurl).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
    }
  };

  const onSave = async (e) => {
    const value = e.value;
    if (value) {
      setFileName(value);
    }
    // setLoading(true);
    try {
      const res = await updateFile({
        id: mediaDetails?.id,
        name: value,
      });
      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.info(res?.data?.message);
        loadList();
      } else {
        toast.info(
          res?.data?.message || res?.data?.errors || "Something went wrong"
        );
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };
  // const [openImage, setOpenImage] = useState({ data: [], isOpen: false });

  return (
    <>
      {mediaDetailsId?.type === "file" ? (
        <div className="relative w-full">
          <div
            className={`border-l border-slate-200 bg-white p-3 shadow-lg rounded-l-md !h-full transition-all duration-300`}
          >
            {loading ? (
              <div className="flex items-center justify-center h-screen">
                <div className="w-16 h-16 border-4 border-red-500 border-t-transparent border-solid rounded-full animate-spin"></div>
              </div>
            ) : (
              <>
                <div className="mb-3">
                  <Button
                    buttonIcon={"fa-regular fa-arrow-right"}
                    buttonIconPosition={"left"}
                    buttonClasses={
                      "!bg-transparent !text-2xl !text-red-600 !rounded-full !shadow-md !h-8 !w-8 !border !border-slate-400"
                    }
                    buttonHasLink={false}
                    buttonFunction={() => setIsOpen(false)}
                  />
                </div>
                <div
                  className={classNames(
                    "overflow-auto max-h-[calc(100vh-220px)]",
                    modalDescriptionHeight
                  )}
                >
                  <div className="w-full aspect-square overflow-hidden border border-slate-200 rounded-md p-3">
                    <a href={mediaDetails?.mediaurl} data-fancybox='gallery'>
                    <Image
                      src={mediaDetails?.mediaurl}
                      alt=""
                      classes="w-full h-full object-cover"
                    />
                    </a>
                  </div>
                  <div className="px-2 pt-3 text-center">
                    <div
                      className="flex text-base font-bold break-all"
                      onClick={() => setIsEditing(true)}
                    >
                      {isEditing ? (
                        <HideableInput
                          value={fileName}
                          onSave={onSave}
                          type={"transparent"}
                          rename={isEditing}
                          setRename={setIsEditing}
                          inputClasses={
                            "!text-base !bg-transparent !border !border-red-500 !w-64 !text-org-theme"
                          }
                        />
                      ) : (
                        <div> {fileName || ""}</div>
                      )}
                    </div>
                    {/* {/ <div className="flex justify-between"> /} */}
                    <div className="flex items-center gap-4 mt-2">
                      <div className="font-bold text-black text-sm">Size:</div>
                      <div className=" text-black text-sm">
                        {" "}
                        {mediaDetails?.size || "0 KB"}
                      </div>
                    </div>
                    <div className="xl:flex block xl:items-center text-start gap-4 mt-2">
                      <div className="font-bold text-black text-sm">
                        Type/Format :
                      </div>
                      <div className=" text-black text-sm">
                        {mediaDetails?.mime_type || ""}
                      </div>
                    </div>
                    {mediaDetails?.created_at && (
                      <div className="xl:!flex block xl:items-center text-start gap-4 mt-2">
                        <div className="font-bold text-black text-sm">
                          {" "}
                          Created Date :
                        </div>
                        <div className=" text-black text-sm">
                          {mediaDetails?.created_at
                            ? moment(mediaDetails?.created_at).format(
                                "MM-DD-YYYY"
                              )
                            : "N/A"}
                        </div>
                      </div>
                    )}
                    {mediaDetails?.updated_at && (
                      <div className="xl:!flex block xl:items-center text-start gap-4 mt-2">
                        <div className="font-bold text-black text-sm">
                          {" "}
                          Modified Date :
                        </div>
                        <div className=" text-black text-sm">
                          {mediaDetails?.updated_at
                            ? moment(mediaDetails?.updated_at).format(
                                "MM-DD-YYYY"
                              )
                            : "N/A"}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="px-2 pt-1 text-center">
                    <div className="flex items-center gap-4">
                      <div className="flex text-sm font-bold break-all mt-4 text-gray-500 border border-slate-300 bg-white shadow-lg p-2 rounded-md">
                        {mediaDetails?.mediaurl || ""}
                      </div>
                      <div>
                        {mediaDetails?.mediaurl && (
                          <button
                            onClick={handleCopy}
                            className="text-blue-500 hover:text-blue-700 focus:outline-none 3xl:!text-xl text-lg mt-1"
                          >
                            <i className="fa-solid fa-copy"></i>
                          </button>
                        )}
                      </div>
                    </div>
                    {copied && <p className="text-green-500 mt-2">Copied!</p>}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="relative w-full">
          <div
            className={`border-l border-slate-200 bg-white p-3 shadow-lg rounded-l-md h-full transition-all duration-300`}
          >
            {loading ? (
              <div className="flex items-center justify-center h-screen">
                <div className="w-16 h-16 border-4 border-red-500 border-t-transparent border-solid rounded-full animate-spin"></div>
              </div>
            ) : (
              <>
                <div className="mb-3">
                  <Button
                    buttonIcon={"fa-regular fa-arrow-right"}
                    buttonIconPosition={"left"}
                    buttonClasses={
                      "!bg-transparent !text-2xl !text-red-600 !rounded-full !shadow-md !h-8 !w-8 !border !border-slate-400"
                    }
                    buttonHasLink={false}
                    buttonFunction={() => setIsOpen(false)}
                  />
                </div>
                <div
                  className={classNames(
                    "overflow-auto max-h-[calc(100vh-220px)]",
                    modalDescriptionHeight
                  )}
                >
                <div className="w-full aspect-square overflow-hidden border border-slate-200 rounded-md p-3">
                  <Image
                    src={img2}
                    alt=""
                    classes="w-full h-full object-cover"
                  />
                </div>
                <div className="px-2 pt-3 text-center">
                  <div className="flex text-base font-bold break-all">
                    {folderDetails?.name || ""}
                  </div>
                  {/* {/ <div className="flex justify-between"> /} */}
                  <div className="flex items-center gap-4 mt-2">
                    <div className="font-bold text-black text-sm">Size:</div>
                    <div className=" text-black text-sm">
                      {" "}
                      {folderDetails?.totalFileSize || "0 KB"}
                    </div>
                  </div>
                  <div className="flex items-center gap-4 mt-2">
                    <div className="font-bold text-black text-sm">Folder :</div>
                    <div className=" text-black text-sm">
                      {folderDetails?.totalFolder || 0}
                    </div>
                  </div>
                  <div className="flex items-center gap-4 mt-2">
                    <div className="font-bold text-black text-sm">Files :</div>
                    <div className=" text-black text-sm">
                      {folderDetails?.totalFile || 0}
                    </div>
                  </div>
                  {folderDetails?.created_at && (
                    <div className="xl:flex block xl:items-center text-start gap-4 mt-2">
                      <div className="font-bold text-black text-sm">
                        Created Date :
                      </div>
                      <div className=" text-black text-sm">
                        {" "}
                        {folderDetails?.created_at
                          ? moment(folderDetails?.created_at).format(
                              "MM-DD-YYYY"
                            )
                          : "N/A"}
                      </div>
                    </div>
                  )}
                  {folderDetails?.updated_at && (
                    <div className="xl:flex block xl:items-center text-start gap-4 mt-2">
                      <div className="font-bold text-black text-sm">
                        {" "}
                        Modified Date :
                      </div>
                      <div className=" text-black text-sm">
                        {" "}
                        {folderDetails?.updated_at
                          ? moment(folderDetails?.updated_at).format(
                              "MM-DD-YYYY"
                            )
                          : "N/A"}
                      </div>
                    </div>
                  )}
                </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      
      {/* <Modal
        size="xl3"
        modalTitle=""
        open={openImage.isOpen}
        // onClose={setOpenImage({ isOpen: false, data: [] })}
      >
        <ProductImageSlider setOpenImage={setOpenImage} data={openImage?.data}/>
      </Modal> */}
       
    </>
  );
};

export default ShowMediaDetails;
