import React, { useCallback, useEffect, useState } from "react";
import MyOrderList from "./MyOrderList";
import { getUserOrders } from "../../../services/frontService/ProfileService";
import Button from "../../form/Button";
import PageLoader from "../../common/PageLoader";
import ImageTextSkeletonLoader from "../../elements/loader/ImageTextSkeletonLoader";
import MyOrderLoader from "../../loader/MyOrderLoder";
import Input from "../../form/Input";
import { useDebounce } from "../../../hooks/useDebounce";
import DatePickerInput from "../../../components/form/DatePickerInput";

const MyOrders = () => {
  const [myorderList, setMyOrderList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const search = useDebounce(keyword, 200);
  const [selectedTabId, setSelectedTabId] = useState(null);
  const [maxDate, setMaxDate] = useState({ startDate: null, endDate: null });

  const orderList = useCallback(() => {
    setLoading(true);
    let payload = {
      order_id: search,
    };
    getUserOrders(
      maxDate?.startDate
        ? {
            ...payload,
            dateSearchMin: new Date(maxDate?.startDate).toISOString(),
            dateSearchMax: new Date(new Date(maxDate?.endDate).setUTCHours(23, 59, 59, 999)).toISOString(),
          }
        : payload
    ).then((res) => {
      if (res?.status === 200) {
        setMyOrderList(res?.docs?.data);
        setLoading(false);
      } else {
        setMyOrderList(res?.docs?.data);
        setLoading(false);
      }
    });
  }, [search, maxDate?.startDate, maxDate?.endDate]);

  useEffect(() => {
    orderList();
  }, [orderList]);

  useEffect(() => {
    if (keyword) {
      setSelectedTabId(myorderList[0]?.status);
    }
  }, [myorderList, keyword]);

  useEffect(() => {
    const storedTabId = localStorage.getItem("selectedTabId");
    if (storedTabId) {
      setSelectedTabId(Number(storedTabId));
    }
  }, []);

  useEffect(() => {
    if (selectedTabId !== null) {
      localStorage.setItem("selectedTabId", selectedTabId);
    }
  }, [selectedTabId]);

  return (
    <>
      <div className="mt-10 w-full h-full bg-slate-100 rounded-md md:px-10 px-5 py-10 pt-5 shadow-md">
        {/* {loading ? (
          <>
            <MyOrderLoader />
            <MyOrderLoader />
          </>
        ) : ( */}
        <div>
          {/* {Array?.isArray(myorderList) && myorderList?.length > 0 && ( */}
          <div>
            <div className="flex lg:flex-row flex-col items-center justify-between">
              <div className="text-black md:text-2xl text-xl font-bold pb-2">My Orders</div>
              <div className="grid md:grid-cols-2 grid-col-1 gap-4">
              <div className="md:w-64 w-full">
                <DatePickerInput
                datepickerClass={"!border-red-600 !rounded-full !shadow-md md:!text-sm !text-xs "}
                  asSingle={false}
                  useRange={true}
                  value={maxDate}
                  onChange={(val) => {
                    setMaxDate(val);
                  }}
                  
                />
              </div>
              <div className="flex justify-end !border !border-red-600 !rounded-full bg-white shadow-md">
                <Input
                  labelClasses={" !border-0 "}
                  inputType={"text"}
                  isInputGroup={true}
                  inputGroupPosition={"left"}
                  inputGroupIcon={"fa-regular fa-search"}
                  inputPlaceholder={"Search..."}
                  inputClasses={"!pl-3 !border-0 !rounded-full !bg-white"}
                  inputGroupClasses={" !border-0 !rounded-full !bg-white"}
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                />
              </div>
              </div>
            </div>
            <div className="mt-2">
              <MyOrderList
                myorderList={Array?.isArray(myorderList) && myorderList?.length > 0 && myorderList}
                keyword={keyword}
                setKeyword={setKeyword}
                selectedTabId={selectedTabId}
                setSelectedTabId={setSelectedTabId}
              />
            </div>
          </div>
          {/* )} */}
          {/* ) : (
              <div className="flex flex-col items-center space-y-8 mt-16">
                <div className="w-40 h-40 rounded-full bg-[#F0F9F4] overflow-hidden flex items-center justify-center ">
                  <div className="text-[#e73737] text-8xl">
                    <i className="fa-regular fa-bag-shopping"></i>
                  </div>
                </div>
                <div className="text-cape-cod text-3xl">You have no orders yet.</div>
                <div className="text-concord text-base max-w-md text-center">
                  You don’t have any products in the orderlist yet. Explore our wide range of products and start
                  shopping
                </div>
                <Button
                  buttonClasses={
                    "md:!px-4 !px-3 !py-1 md:!h-10 !h-7 !h-8 !text-white hover:!text-white !text-xl hover:!bg-black hover:!border-black !text-center bg-red-600 gap-3  transition duration-200 rounded-none leading-none !rounded-md"
                  }
                  buttonLabel="Continue Shopping"
                  buttonLabelClasses="md:!text-lg !text-sm"
                  buttonEffect={"filled"}
                  buttonHasLink={true}
                  buttonLink={"/"}
                />
              </div>
            )} */}
        </div>
        {/* )} */}
      </div>
    </>
  );
};

export default MyOrders;
