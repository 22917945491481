import { apiRequest } from "../util/apiUtils";

export const getMediaList = async (params) => {
  try {
    const res = await apiRequest(
      "get",
      `admin/mediaManagement/media/${params?.id}`,
      {
        queries: params,
      }
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const addNewFolder = async (params) => {
  try {
    const res = await apiRequest(
      params.id ? "post" : "post",
      params.id
        ? "/admin/mediaManagement/createFolder "
        : "/admin/mediaManagement/createFolder ",
      {
        body: { ...params },
      }
      // "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const addNewFile = async (params) => {
  try {
    const res = await apiRequest(
      params.id ? "post" : "post",
      params.id
        ? "/admin/mediaManagement/uploadMedia"
        : "/admin/mediaManagement/uploadMedia",
      {
        body: { ...params },
      },
      "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getMediaDetails = async (params) => {
  try {
    const res = await apiRequest(
      "get",
      `admin/mediaManagement/mediaDetails/${params?.id}`,
      {}
    );
    return res?.data;
  } catch (err) {
    return err;
  }
};

export const getFolderDetails = async (params) => {
  try {
    const res = await apiRequest(
      "get",
      `admin/mediaManagement/folderDetails/${params?.id}`,
      {}
    );
    return res?.data;
  } catch (err) {
    return err;
  }
};

export const deleteMediaFolder = async (payload = {}) => {
  try {
    const res = await apiRequest("post", "admin/mediaManagement/folderDelete", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const deleteMediaFile = async (payload = {}) => {
  try {
    const res = await apiRequest("post", "admin/mediaManagement/mediaDelete", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getTrashList = async (params) => {
  try {
    const res = await apiRequest("get", `admin/mediaManagement/deletedMedia`, {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const mediaRestore = async (payload = {}) => {
  try {
    const res = await apiRequest(
      "post",
      "/admin/mediaManagement/mediaRestore",
      {
        body: payload,
      }
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const mediaFolderRestore = async (payload = {}) => {
  try {
    const res = await apiRequest(
      "post",
      "admin/mediaManagement/folderRestore",
      {
        body: payload,
      }
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const updateFile = async (params) => {
  try {
    const res = await apiRequest(
      "post",
      "admin/mediaManagement/remaneMedia",
      {
        body: { ...params },
      }
      // "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};
