import React from "react";
import Image from "../components/elements/Image";
import footerlogo from "../assets/images/logo(white).png";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/form/Button";
import { useSelector } from "react-redux";

const DefaultFooter = ({ lableSubCategory, rjsLableSubCategory }) => {
  const navigate = useNavigate();
  const settings = useSelector((state) => state?.settings?.data);

  const rjsSubCategory = rjsLableSubCategory && rjsLableSubCategory?.data?.length > 0 ? rjsLableSubCategory?.data : [];

  const lableSubCategoryData = lableSubCategory && lableSubCategory?.data?.length > 0 ? lableSubCategory?.data : [];

  const routeChange = (slug, link) => {
    navigate(`/${link}/${slug}`);
  };

  return (
    <footer>
      <div className="bg-gray-950 p-8 py-16 md:mt-24 mt-14">
        <div className=" sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto justify-center 2xl:space-x-10 space-x-0">
          <div className="xl:flex block">
            <div className="text-white xl:block  xl:space-x-0 space-x-4 flex items-center 3xl:w-3/12 xl:w-1/3">
              <div className="md:w-24 w-14 ">
                <Link to="/">
                  <img
                    src={settings?.logoUrl || footerlogo}
                    alt={footerlogo}
                    className="w-full h-full object-cover"
                    onError={(e) => {
                      e.target.src = footerlogo;
                    }}
                  />
                </Link>
              </div>
              <div className="md:text-4xl text-xl xl:pt-6 leading-snug">
                <h2>{settings.title || "HJL Designs"}</h2>
                <h2 className="md:text-xl text-sm">At Best Price</h2>
              </div>
            </div>
            <div className="2xl:w-full md:w-full w-5/6 flex xl:justify-center justify-between 3xl:space-x-32 xl:space-x-24 md:space-x-8 flex-wrap xl:mt-auto my-8 gap-4">
              <div className="text-white justify-end">
                <div className="uppercase 3xl:text-2xl xl:text-xl text-base md:pb-2 pb-1">Company</div>
                <hr className="md:w-16 w-10 h-0.5 bg-red-600 md:mb-5 mb-2 border-0"></hr>
                <ul className="">
                  <li className="text-white md:text-lg text-xs px-0  py-1 rounded-md transition delay-150 duration-300">
                    <Link to={"/"}>Home</Link>
                  </li>
                  <li className="text-white md:text-lg text-xs px-0  py-1 rounded-md transition delay-150 duration-300 mt-auto">
                    <Link to={"/testimonial"}>Testimonial</Link>
                  </li>
                  <li className="text-white md:text-lg text-xs px-0  py-1 rounded-md transition delay-150 duration-300">
                    <Link to={"/aboutus"}>About Us</Link>
                  </li>
                  <li className="text-white md:text-lg text-xs px-0  py-1 rounded-md transition delay-150 duration-300">
                    <Link to={"/contactus"}>Contact</Link>
                  </li>
                  <li className="text-white md:text-lg text-xs px-0  py-1 rounded-md transition delay-150 duration-300 mt-auto">
                    <Link to={"/faq"}>FAQs</Link>
                  </li>
                  <li className="text-white md:text-lg text-xs px-0  py-1 rounded-md transition delay-150 duration-300 mt-auto">
                    <Link to={"/shippingpolicy"}>Shipping Policy</Link>
                  </li>
                  <li className="text-white md:text-lg text-xs px-0  py-1 rounded-md transition delay-150 duration-300 mt-auto">
                    <Link to={"/privacypolicy"}>Privacy Policy</Link>
                  </li>
                </ul>
              </div>

              <div className="text-white justify-end">
                <div className="uppercase 3xl:text-2xl xl:text-xl text-base md:pb-2 pb-1 md:mt-auto mt-4">Labels</div>
                <hr className="md:w-16 w-10 h-0.5 bg-red-600 md:mb-5 mb-2 border-0"></hr>
                <ul>
                  {lableSubCategoryData?.map((category, index) => (
                    <li
                      key={index}
                      className="text-white 3xl:text-lg md:text-base text-xs px-0 py-1 rounded-md transition delay-150 duration-300 cursor-pointer">
                      <div onClick={() => routeChange(category?.slug, "label")}>{category?.name}</div>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="text-white justify-end">
                <div className="uppercase 3xl:text-2xl xl:text-xl md:pb-2 pb-1">Rjs Labels</div>
                <hr className="md:w-16 w-10 h-0.5 bg-red-600 md:mb-5 mb-2 border-0"></hr>
                <ul>
                  {rjsSubCategory?.map((category, index) => (
                    <li
                      key={index}
                      className="text-white 3xl:text-lg md:text-base text-xs px-0 py-1 rounded-md transition delay-150 duration-300 cursor-pointer">
                      <div onClick={() => routeChange(category?.slug, "rjs-label")}>{category?.name}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="text-white justify-start w-3/12 md:items-start items-start flex flex-col">
              <div>
                <div className="uppercase  3xl:text-2xl xl:text-xl text-base pb-auto pb-2">Mail Us At</div>
                <hr className="md:w-16 w-10 h-0.5 bg-red-600 mb-2 border-0"></hr>
                <ul className=" ">
                  <li className="text-white text-base md:py-2 py-0 rounded-md transition delay-150 duration-300 flex space-x-3 items-center">
                    <div className="md:text-3xl text-xs">
                      <i className="fa-regular fa-envelope"></i>
                    </div>
                    <div className="3xl:text-xl md:text-base text-xs">
                      <Link to="mailto:info@hjldesigns.com" className="hover:underline">
                        {settings?.email || "info@hjldesigns.com"}
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <div className="flex space-x-4 md:text-2xl text-xl text-black xl:mt-5 mt-4">
                  <Link
                    to={`${settings?.facebook}`}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`${settings?.facebook}`, "_blank", "noopener,noreferrer");
                    }}>
                    <div className="flex bg-white md:w-10 md:h-10 w-8 h-8 justify-center items-center rounded-full">
                      <i class="fa-brands fa-facebook-f"></i>
                    </div>
                  </Link>
                  <Link
                    to={`${settings?.instagram}`}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`${settings?.instagram}`, "_blank", "noopener,noreferrer");
                    }}>
                    <div className="flex bg-white md:w-10 md:h-10 w-8 h-8 justify-center items-center rounded-full">
                      <i class="fa-brands fa-instagram"></i>
                    </div>
                  </Link>
                  <Link
                    to={`${settings?.twitter}`}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`${settings?.twitter}`, "_blank", "noopener,noreferrer");
                    }}>
                    <div className="flex bg-white md:w-10 md:h-10 w-8 h-8 justify-center items-center rounded-full">
                      <i class="fa-brands fa-twitter"></i>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="text-white w-1/4">
           <div>
              <ul className="pt-20">
                <li className="text-white text-lg px-3 py-2 rounded-md transition delay-150 duration-300 mt-auto">
                  <Link to={"/"}>Testimonial</Link>
                </li>
              </ul>
              </div> 
          </div>*/}
        </div>
      </div>

      {/* <div className="bg-gray-800 text-white py-6 justify-center flex px-6">
        <div className="container flex justify-between items-center sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="xl:text-sm text-xs leading-5 xl:leading-0">
            All En Primeur, Cru Select and Cru International labels are in stock and available to order.
          </div>
          <div className="flex space-x-4">
            <Button
              buttonClasses={"!px-5 !h-10 !text-3xl bg-gray-950 w-12"}
              buttonHasLink={true}
              buttonLabel={"OK"}
              buttonLabelClasses={""}
              buttonEffect={"filled"}
            />
          </div>
        </div>
      </div> */}
    </footer>
  );
};

export default DefaultFooter;
