import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { classNames } from "../../../helpers/classNames";
import Button from "../../form/Button";

const Modal = ({
  title,
  subtitle,
  children,
  open = false,
  onClose = () => {},
  size = "xl4",
  modalDialogueStyle = "",
  titleClass,
  modalPaddingClass,
}) => {
  const modalSize = {
    xs: "sm:max-w-xs",
    sm: "sm:max-w-sm",
    md: "sm:max-w-md",
    lg: "sm:max-w-lg",
    xl: "sm:max-w-xl",
    xl2: "sm:max-w-2xl",
    xl3: "sm:max-w-3xl",
    xl4: "sm:max-w-4xl",
    xl5: "sm:max-w-5xl",
    xl6: "sm:max-w-6xl",
    xl7: "sm:max-w-7xl",
    xl8: "sm:max-w-8xl",
    xl9: "sm:max-w-9xl",
    full: "max-w-full",
  };

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-60" onClose={() => onClose(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gradient-to-b from-org-darkGray/20 via-org-darkGray/20 to-org-darkGray/20" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel
                  className={`w-full ${modalSize[size]} transform text-left align-middle shadow-xl transition-all`}>
                  <div className={classNames("rounded-xl bg-white ", modalDialogueStyle)}>
                    <div className="relative pt-1 pb-1 mx-4 flex items-center justify-between">
                      <div className="mr-auto flex-shrink">
                        <h2 className={classNames("text-lg tracking-tight font-semibold text-slate-900 ", titleClass)}>{title}</h2>
                        <div className="text-sm font-medium text-org-grayW">{subtitle}</div>
                      </div>
                      {/* <div className="ml-auto flex-shrink-0">
                        <Button
                          buttonIcon={"fa-regular fa-xmark"}
                          buttonIconPosition={"left"}
                          buttonClasses={
                            "!w-8 !h-8 !p-0 !justify-center !text-xl !bg-transparent !border-transparent !text-org-grayW hover:!bg-org-silver hover:rotate-90"
                          }
                          buttonFunction={onClose}
                        />
                      </div> */}
                    </div>
                    <div className={classNames("relative py-4 px-4", modalPaddingClass)}>{children}</div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;
