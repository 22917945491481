import React, { useEffect, useState } from "react";
import ProfileSidebar from "../components/sections/ProfileSidebar";
import MyOrders from "../components/sections/userProfile/MyOrders";
import { useLocation } from "react-router-dom";

const MyOrder = () => {
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    if (location.pathname === "/user-orders") {
      setSelectedOption("user-orders");
    }
  }, [location.pathname]);
  return (
    <>
      <div className="sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
        <div className="lg:flex md:block md:mt-36 mt-24 xl:gap-10 gap-6">
          <div className="xl:w-1/5 lg:w-4/12 w-full md:px-0 px-5">
            <ProfileSidebar
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </div>
          <div className="xl:w-4/5 lg:w-[73%] w-full">
            <MyOrders />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyOrder;
