import React, { useCallback, useEffect, useState } from "react";
import Input from "../form/Input";
import PaymentMethod from "./PaymentMethod";
import Select from "../form/Select";
import BillingAddress from "./BillingAddress";
import Button from "../form/Button";
import Modal from "../elements/Modal/Modal";
import ShippingAddressModal from "./ShippingAddressModal";
import ShippingSavedAddress from "../Items/ShippingSavedAddress";
import ShippingAddressForm from "./ShippingAddressForm";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { classNames } from "../../helpers/classNames";
import useForm from "../../hooks/useForm";
import { formValidate } from "../../helpers/formValidate";
import { getProfileDetails } from "../../services/frontService/ProfileService";

const ShippingAddress = ({ addressData, myCheckoutData }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [addressDetails, setAddressDetails] = useState({});
  const [shippingAddressData, setShippingAddressData] = useState({
    heading: "Address Information",
    subheading: "Shipping <span class='text-red-600 font-extrabold'>Address</span>",
  });

  const [sameBillingAddress, setSameBillingAddress] = useState(true);
  const [profileDetailsData, setProfileDetailsData] = useState({
    loading: false,
    data: {},
  });
  const [contactInfo, setContactInfo] = useState({
    subheading: "Contact <span class='text-red-600 font-extrabold'>Info</span>",
  });

  const validation = {
    name: { required: true, message: "Please enter your name !!!" },
    email: { required: true, message: "Please enter your email !!!" },
    phone: { required: true, message: "Please enter phone number !!!" },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);

  const billingAddress = addressData && addressData?.billingAddress && addressData?.billingAddress;

  const shippingAddress = addressData && addressData?.shippingAddress && addressData?.shippingAddress;

  const orderSummary = myCheckoutData ? myCheckoutData?.orderSummary : {};

  const contactDetails = {
    name: values?.name,
    email: values?.email,
    phone: values?.phone,
  };

  const validateAndNavigate = () => {
    if (!orderSummary?.placeOrder) {
      toast.error(`Minimum order size is ${orderSummary?.minimumOrderSize}`);
      return;
    }

    if (!sameBillingAddress) {
      if (!billingAddress?.address) {
        toast.error("Billing address is required.");
        return;
      }
      if (!billingAddress?.state) {
        toast.error("Billing state is required.");
        return;
      }
      if (!billingAddress?.city) {
        toast.error("Billing city is required.");
        return;
      }
      if (!billingAddress?.pin) {
        toast.error("Billing pin is required.");
        return;
      }
    }

    if (!shippingAddress?.location) {
      toast.error("Shipping location is required.");
      return;
    }
    if (!shippingAddress?.state) {
      toast.error("Shipping state is required.");
      return;
    }
    if (!shippingAddress?.city) {
      toast.error("Shipping city is required.");
      return;
    }
    if (!shippingAddress?.pin) {
      toast.error("Shipping pin is required.");
      return;
    }
    if (!contactDetails?.name) {
      toast.error("User name is required.");
      return;
    }
    if (!contactDetails?.email) {
      toast.error("User email is required.");
      return;
    }
    if (!contactDetails?.phone) {
      toast.error("User phone is required.");
      return;
    }

    const addressData = {
      shippingAddress: shippingAddress && shippingAddress,
      billingAddress: sameBillingAddress ? shippingAddress : billingAddress,
      contactDetails: contactDetails,
    };

    navigate("/paymentinformation", { state: addressData });
  };

  const getDetails = useCallback(() => {
    setProfileDetailsData({ loading: true, data: {} });
    getProfileDetails().then((res) => {
      if (res?.status === 200) {
        setProfileDetailsData({
          loading: true,
          data: {
            ...res?.data,
            role: "USER",
          },
        });

        setFieldsValue({
          name: res?.data?.name || "",
          email: res?.data?.email || "",
          phone: res?.data?.phone || "",
        });
      } else {
        setProfileDetailsData({});
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getDetails();
  }, [getDetails]);

  return (
    <>
      <div className="xl:w-3/5  md:px-0 px-5">
        {/* <div className="bg-red-600 p-2 pl-5 uppercase text-white text-xl font-semibold rounded-md">
          {ShippingAddressData.heading}
        </div> */}
        <div>
          <div className="flex items-center md:pt-7 pt-2 md:pb-3 pb-1 space-x-2">
            <div
              className=" uppercase text-black md:text-2xl text-xl font-medium rounded-md"
              dangerouslySetInnerHTML={{
                __html: contactInfo.subheading,
              }}></div>

            <div className="text-2xl">
              <i className="fa-solid fa-circle-question"></i>
            </div>
          </div>
          <hr className="w-full border-1 border-slate-500 pb-8"></hr>

          <div className="w-full">
            <div className={classNames("relative overflow-hidden bg-slate-100 shadow-lg rounded-md p-4 pb-5 z-0")}>
              <div className="flex flex-wrap gap-4">
                {" "}
                <div className="relative w-full md:w-[48%]">
                  <Input
                    isInputGroup={false}
                    inputGroupIcon={"fa-regular fa-user"}
                    inputGroupPosition={"left"}
                    label={"Name"}
                    labelClasses={"!text-sm !font-bold !text-black pb-1"}
                    inputType={"text"}
                    value={values.name}
                    inputName={"name"}
                    onChange={handleChange}
                    inputPlaceholder={"Enter Name"}
                    inputClasses={"!py-2 !px-2 w-full gap-2 !border-slate-300 !h-12"}
                    {...formValidate(errors, "name")}
                  />
                </div>
                <div className="relative w-full md:w-[48%]">
                  <Input
                    isInputGroup={false}
                    inputGroupIcon={"fa-solid fa-envelope"}
                    inputGroupPosition={"left"}
                    label={"Email"}
                    labelClasses={"!text-sm !font-bold !text-black pb-1"}
                    inputType={"text"}
                    value={values.email}
                    inputName={"email"}
                    onChange={handleChange}
                    inputPlaceholder={"Enter Email"}
                    inputClasses={"!py-2 !px-2 w-full !border-slate-300 !h-12"}
                    {...formValidate(errors, "email")}
                  />
                </div>
                <div className="relative w-full md:w-[48%]">
                  <Input
                    isInputGroup={false}
                    inputGroupIcon={"fa-solid fa-phone"}
                    inputGroupPosition={"left"}
                    label={"Phone No."}
                    labelClasses={"!text-sm !font-bold !text-black pb-1"}
                    inputType={"text"}
                    value={values.phone}
                    inputName={"phone"}
                    onChange={handleChange}
                    inputPlaceholder={"Enter Phone Number"}
                    inputClasses={"!py-2 !px-2 w-full !border-slate-300 !h-12"}
                    {...formValidate(errors, "phone")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex items-center md:pt-7 pt-2 md:pb-3 pb-1 space-x-2">
            <div
              className=" uppercase text-black md:text-2xl text-xl font-medium rounded-md"
              dangerouslySetInnerHTML={{
                __html: shippingAddressData.subheading,
              }}></div>

            <div className="text-2xl">
              <i className="fa-solid fa-circle-question"></i>
            </div>
          </div>
          <hr className="w-full border-1 border-slate-500 pb-8"></hr>
          <div className="">
            {shippingAddress && (
              <div className="flex space-x-5">
                <div
                  className={classNames(
                    "relative overflow-hidden bg-slate-50 border border-red-600 !shadow-lg rounded-md p-3 z-0"
                  )}>
                  <div className="flex items-center">
                    <div className="flex-1 space-y-1">
                      <div className="flex flex-wrap items-center md:space-x-2 space-x-1">
                        <div className="text-xs font-bold">Address :</div>
                        <div className="md:text-sm text-xs">{shippingAddress?.location || "N/A"}</div>
                      </div>
                      <div className="flex flex-wrap items-center md:space-x-2 space-x-1">
                        <div className="text-xs font-bold">City :</div>
                        <div className="md:text-sm text-xs">{shippingAddress?.city || "N/A"}</div>
                      </div>
                      <div className="flex flex-wrap items-center md:space-x-2 space-x-1">
                        <div className="text-xs font-bold">State :</div>
                        <div className="md:text-sm text-xs">{shippingAddress?.stateName || "N/A"}</div>
                      </div>
                      <div className="flex flex-wrap items-center md:space-x-2 space-x-1">
                        <div className="text-xs font-bold">Postalcode :</div>
                        <div className="md:text-sm text-xs">{shippingAddress?.pin || "N/A"}</div>
                      </div>
                    </div>
                    <div className="ml-4 mt-12">
                      <Button
                        buttonClasses={"!bg-transparent !text-red-600 "}
                        buttonLabel=""
                        buttonLabelClasses="!text-base"
                        buttonIcon={"fa-regular fa-pen"}
                        buttonIconPosition={"left"}
                        buttonEffect={"filled"}
                        buttonFunction={() => {
                          setIsOpen(true);
                          setAddressDetails(shippingAddress);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* <ShippingAddressForm></ShippingAddressForm> */}
          </div>
          {!shippingAddress && (
            <Button
              buttonClasses={"bg-red-600 hover:!bg-black !px-5"}
              buttonLabel="Add Address"
              buttonLabelClasses="!text-base"
              buttonIcon={"fa-regular fa-plus"}
              buttonIconPosition={"left"}
              buttonEffect={"filled"}
              buttonFunction={() => setIsOpen(true)}
            />
          )}
          <div className=" pt-8"></div>
          <Modal
            size="xl4"
            title="Shipping Address"
            open={isOpen}
            onClose={() => setIsOpen(false)}
            titleClass={"!pt-5 !ps-3"}>
            <ShippingAddressModal
              onClose={() => setIsOpen(false)}
              setDetails={setAddressDetails}
              details={addressDetails}
            />
          </Modal>
        </div>
        <BillingAddress
          billingAddressData={billingAddress}
          sameBillingAddress={sameBillingAddress}
          setSameBillingAddress={setSameBillingAddress}
        />
        <div className="flex justify-end pt-8 space-x-4">
          <Button
            buttonClasses={"!bg-gray-600 hover:!bg-black !px-12"}
            buttonLabel="Back"
            buttonLabelClasses="!text-lg"
            buttonEffect={"filled"}
            buttonHasLink={true}
            buttonLink={"/cart"}
          />
          {!orderSummary?.placeOrder ? (
            <Button
              buttonClasses={"bg-gray-600 hover:!bg-black !px-12"}
              buttonLabel="Next"
              buttonLabelClasses="!text-lg"
              buttonEffect={"filled"}
              buttonHasLink={false}
              buttonFunction={validateAndNavigate}
              isDisable={true}
            />
          ) : (
            <Button
              buttonClasses={"bg-gray-600 hover:!bg-black !px-12"}
              buttonLabel="Next"
              buttonLabelClasses="!text-lg"
              buttonEffect={"filled"}
              buttonHasLink={false}
              buttonFunction={validateAndNavigate}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ShippingAddress;
