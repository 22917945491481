import React, { useCallback, useEffect, useState } from "react";
import testimonialimg from "../assets/images/testimonial/testimonial-img-1.webp";
import testimonialimg_2 from "../assets/images/testimonial/testimonial-img-2.webp";
import testimonialimg_3 from "../assets/images/testimonial/testimonial-img-3.webp";
import TestimonialItems from "../components/Items/TestimonialItems";
import MainBanner from "../components/sections/MainBanner";
import SmallBanner from "../components/sections/SmallBanner";
import { testimonialListData } from "../services/frontService/TestimonialService";
import { toast } from "react-toastify";
import { classNames } from "../helpers/classNames";
import { getPageData } from "../services/frontService/PageService";
import TestimonialBoxLoader from "../components/loader/TestimonialBoxLoader";

const Testimonial = ({ testimonial }) => {
  const slug = "testimonials";
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });

  const [homeData, setHomeData] = useState({});

  const loadPageData = useCallback(() => {
    getPageData({
      slug: slug,
    }).then((res) => {
      if (res?.status === 200) {
        setHomeData(res?.data);
      }
    });
  }, []);

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);

  //  Product list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({
      ...pre,
      data: [],
      loading: true,
    }));
    testimonialListData({
      perPage: 100,
      // status: 1,
      // featured: 1,
    }).then((res) => {
      if (res && res?.docs?.success) {
        setList({
          loading: false,
          data: res?.docs?.data,
          pageCount: res?.docs?.metadata?.totalPages,
          totalItem: res?.docs?.metadata?.totalDocs,
        });
      } else {
        setList((pre) => ({ ...pre, data: [], loading: true }));
        toast.error(res?.message);
      }
    });
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const TestimonialItemstData = {
    subheading: "Great Designs At The Best Price",
    heading: homeData?.name || "TESTIMONIALS",
    // description:
    //   "HJL Designs is now carrying RJS designed labels for all RJS products. Like our non-branded labels, they are printed on high quality, easily removable nylon at a size of 9.5 cm x 7cm without leaving any residue behind.",
    subdata: list && list?.data?.length > 0 ? list?.data : [],
  };
  const loaders = Array(3).fill(null);
  return (
    <>
      <SmallBanner slug={slug} />
      <section className="sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto md:px-auto px-5">
        <div className={classNames("text-center md:px-32 px-0", testimonial ? "md-pt-24 pt-14 " : "md:pt-20 pt-14")}>
          <div className="md:text-xl text-sm text-gray-700">{TestimonialItemstData?.subheading}</div>
          <h2 className="great-design xl:text-6xl md:text-5xl text-2xl font-bold pt-2 pb-4 drop-shadow-md uppercase">
            <span className="relative inline-block !leading-none before:content-[''] before:absolute before:top-2/4 before:-translate-y-1/2 md:before:-left-36 before:-left-20 md:before:w-32 before:w-16 before:border-t-2 before:border-black after:content-[''] after:absolute after:top-2/4 after:-translate-y-1/2 md:after:-right-36 after:-right-20 md:after:w-32 after:w-16 after:border-t-2 after:border-black">
              {TestimonialItemstData?.heading}
            </span>
          </h2>
        </div>

        <div className=" py-6 xl:py-9">
          {list?.loading ? (
            <>
              {loaders?.map((_, index) => (
                <TestimonialBoxLoader key={index} testimonial={true} />
              ))}
            </>
          ) : (
            TestimonialItemstData?.subdata?.length > 0 && (
              <div className={classNames("w-full", testimonial ? "space-y-0" : "space-y-4")}>
                {TestimonialItemstData?.subdata?.map((item, index) => (
                  <TestimonialItems key={index} data={item} testimonial={true} />
                ))}
              </div>
            )
          )}
        </div>
      </section>
    </>
  );
};

export default Testimonial;
