import React, { useState } from "react";
import Image from "../../elements/Image";
import Review from "../Review";
import { Link } from "react-router-dom";
import Modal from "../../elements/Modal/Modal";
import OrderStatusChange from "../../../pages/adminPannel/order/OrderStatusChange";
import { UserDetailsOrderStatusChange } from "./UserDetailsOrderStatusChange";
import { classNames } from "../../../helpers/classNames";

const RecentProductItems = ({ data, orderList, setOrderList = () => {}, getOrderDetails = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [statusDetails, setStatusDetails] = useState({});

  const getProgress = (status) => {
    switch (status) {
      case status:
        return status;
      default:
        return 0;
    }
  };

  const num = parseInt(data?.orderStatusInfo?.processing_parcentage);

  const progress = getProgress(num);

  return (
    <>
      <div className="relative flex md:gap-6 gap-4 md:py-6 py-8">
        <div className="relative flex  w-full space-Y-10">
          <div className="relative w-full">
            <Link to={`/admin/order-details/${data?.order_id}`}>
              <div className="md:text-xl text-base font-bold capitalize text-slate-900 leading-none">
                {data.order_id}
              </div>
            </Link>
            <div className="flex space-x-2 py-1 items-center cursor-pointer">
              {/* <div className="md:text-base text-sm">Status :</div> */}
              <div
                className="text-red-600 font-bold text-xl"
                onClick={() => {
                  setIsOpen(true);
                  setStatusDetails(data);
                }}>
                {data?.status === 1
                  ? `${data?.orderStatusInfo?.name}`
                  : data?.status === 2
                  ? `${data?.orderStatusInfo?.name}`
                  : data?.status === 3
                  ? `${data?.orderStatusInfo?.name}`
                  : data?.status === 4
                  ? `${data?.orderStatusInfo?.name}`
                  : data?.status === 5
                  ? `${data?.orderStatusInfo?.name}`
                  : ""}
              </div>
            </div>
            <Link to={`/admin/order-details/${data?.order_id}`}>
              <div
                className=" font-bold capitalize text-slate-700"
                dangerouslySetInnerHTML={{
                  __html: data?.orderStatusInfo?.title || "N/A",
                }}></div>
              <div className="md:text-xl text-base md:py-2 py-1 text-black font-bold absolute md:top-0 top-16 right-0">
                Total Price: <span className="text-red-600">CAD {data?.total_price.toFixed(2)}</span>
              </div>
              <div className="md:text-xl text-base md:py-12 py-6 text-black font-bold absolute md:top-0 top-16 right-0">
                GST/HST Charge ({data?.vat_percentage} %):{" "}
                <span className="text-red-600">CAD {data?.vat_charge.toFixed(2)}</span>
              </div>
            </Link>
            {data?.status === 5 ? null : (
              <Link to={`/admin/order-details/${data?.order_id}`}>
                <div className="relative mt-4">
                  <div className="relative w-full bg-gray-200 rounded-full h-6">
                    <div
                      className="absolute top-0 left-0 h-6 bg-red-600 rounded-full"
                      style={{ width: `${progress}%` }}>
                      <span
                        className={classNames(
                          "font-medium text-center flex justify-center",
                          progress > 4 ? "text-white" : "absolute left-full top-1/2 -translate-y-1/2 ml-2 text-black"
                        )}>
                        {progress}%
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>

      <Modal open={isOpen} title={`Order Maintainence for Order: ${statusDetails?.order_id}`} size="xl2">
        <UserDetailsOrderStatusChange
          onClose={() => setIsOpen(false)}
          statusDetails={statusDetails}
          setStatusDetails={setStatusDetails}
          list={orderList}
          setList={setOrderList}
          getOrderDetails={getOrderDetails}
        />
      </Modal>
    </>
  );
};

export default RecentProductItems;
