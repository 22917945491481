import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DetailsImageSection from "./DetailsImageSection";
import DetailsText from "./ImageDetailsText";
import ImageDetailsText from "./ImageDetailsText";
import ProductDetailsDrescription from "../Items/ProductDetailsDrescription";
import SelectMoreProducts from "./SelectMoreProducts";
import DetailsBreadcrumb from "../Items/DetailsBreadcrumb";
import { toast } from "react-toastify";
import {
  addToCartProduct,
  addWishlistProduct,
  productListData,
} from "../../services/frontService/ProductService";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { loginModalOpen } from "../../redux/slice/loginSlice";
import { setCartDetails } from "../../redux/slice/cartSlice";
import { useNavigate } from "react-router-dom";
import ProductLoader from "../loader/ProductLoader";
import ProductDetailsLoader from "../loader/ProductDetailsLoader";

const ProductInformation = ({ details }) => {
  const cardRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const [loaded, setLoaded] = useState(false);
  const profile = useSelector((state) => state.profile.profileData);
  let token_ = localStorage.getItem("auth_token");
  const items = [
    { label: "Home", href: "/" },
    { label: "Product", href: "/category/subcategory/product" },
  ];
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [isSolid, setIsSolid] = useState(false);
  const [isOpen, setIsOpen] = useState({ data: {}, open: false });
  const [activeTab, setActiveTab] = useState(1);

  const handleAddToCart = async (data, quantity) => {
    if (isLogin) {
      setLoaded(true);
      try {
        const res = await addToCartProduct({
          product_id: data?.id,
          quantity: quantity || 1,
        });
        if (res?.data?.status === 200 || res?.data?.status === 201) {
          toast.info(res?.data?.message);
          dispatch(setCartDetails(res?.data?.data));
        } else {
          toast.info(
            res?.data?.message || res?.data?.errors || "Something went wrong"
          );
        }
        setLoaded(false);
      } catch (err) {
        setLoaded(false);
        // console.error(err);
        toast.error("Somthing went wrong !!!");
      }
    } else {
      dispatch(loginModalOpen({ isOpen: true }));
    }
  };

  const handleWishlist = async (data) => {
    if (isLogin) {
      setLoaded(true);
      try {
        const res = await addWishlistProduct({
          product_id: data?.id,
        });

        if (res?.data?.status === 200 || res?.data?.status === 201) {
          toast.info(res?.data?.message);
          setIsSolid((pre) => !pre);
        } else {
          toast.info(
            res?.data?.message || res?.data?.errors || "Something went wrong"
          );
        }

        setLoaded(false);
      } catch (err) {
        setLoaded(false);
        // console.error(err);
        toast.error("Somthing went wrong !!!");
      }
    } else {
      dispatch(loginModalOpen({ isOpen: true }));
    }
  };

  const actions = useMemo(() => {
    return [
      // {
      //   _id: 1,
      //   icon: "fa-light fa-heart",
      //   isLink: false,
      //   isWishlist: true,
      //   isSolid: isSolid,
      //   click: (data) => {
      //     if (isLogin) {
      //       addWishList(data);
      //     } else {
      //       dispatch(loginModalOpen({ isOpen: true }));
      //     }
      //   },
      // },
      {
        _id: 2,
        icon: "fa-light fa-bag-shopping",
        isLink: false,
        click: (data) => {
          if (isLogin) {
            handleAddToCart(data, 1);
          } else {
            dispatch(loginModalOpen({ isOpen: true }));
          }
        },
      },
      {
        _id: 3,
        icon: "fa-light fa-arrow-right",
        isLink: false,
        click: (data) => {
          navigate(`/productdetails/${data?.alias}`);
        },
      },
      {
        _id: 4,
        icon: "fa-light fa-eye",
        isLink: false,
        click: (data) => setIsOpen({ open: true, data: data }),
      },
    ];
  }, [isLogin]);

  //  Product list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({
      ...pre,
      data: [],
      loading: true,
    }));
    productListData({
      parent_category_slug:
        details?.data?.productCategoryInfo?.parent_category_slug || "",
    }).then((res) => {
      if (res && res?.success) {
        setList({
          loading: false,
          data: res?.docs,
          pageCount: res?.docs?.metadata?.totalPages,
          totalItem: res?.docs?.metadata?.totalDocs,
        });
      } else {
        setList((pre) => ({ ...pre, data: [], loading: true }));
        toast.error(res?.message);
      }
    });
  }, [details?.data, isLogin]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const scrollHandler = (type) => {
    if (cardRef.current) {
      const scrollElement = document.querySelector("body");
      switch (type) {
        case "review":
          if (scrollElement) {
            cardRef.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
          }
          setActiveTab(2);
          break;
        case "description":
          if (scrollElement) {
            cardRef.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
          }
          setActiveTab(1);
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <div>
        <div className="sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          {details?.loading ? (
            <>
              <ProductDetailsLoader></ProductDetailsLoader>
            </>
          ) : (
            <div>
              {/* <div className="md:pt-5 pt-2 md:pb-10 pb-5">
                <DetailsBreadcrumb items={items} />
              </div> */}
              <div className="block xl:grid xl:grid-cols-5  gap-10 justify-center">
                <div className="col-span-2">
                  <DetailsImageSection details={details?.data} />
                </div>
                <div className="col-span-3">
                  <ImageDetailsText
                    details={details}
                    handleAddToCart={handleAddToCart}
                    handleWishlist={handleWishlist}
                    isSolid={isSolid}
                    setIsSolid={setIsSolid}
                    scrollHandler={scrollHandler}
                  />
                </div>
              </div>
              <div ref={cardRef}>
                <ProductDetailsDrescription
                  details={details}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </div>
            </div>
          )}

          <div>
            <SelectMoreProducts
              list={list}
              actions={actions}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductInformation;
