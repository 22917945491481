import React from "react";
import Review from "./Review";

const SingleDescriptionRating = ({ data }) => {
  return (
    <>
      <div>
        <div className="relative flex space-x-4 w-full pl-6 mt-2 mb-2">
          <div className="w-full">
            <div className="space-y-1">
              <div className="flex items-center absolute top-0 right-2">
                <div className="md:text-xl text-xl font-bold text-black">{data?.productInfo?.title}</div>
              </div>
              <div className="flex">
                <div className="md:text-l text-l font-bold text-gray-700">
                  {data?.review_by === 1 ? data?.reviewUserInfo?.name : data?.review_by === 2 ? data?.user_name : "N/A"}
                </div>
              </div>
              <div className="flex items-end space-x-2">
                <Review
                  rating={data?.rating}
                  totalReviews={data?.rating}
                  isReviewShow={true}
                  classes={"md:flex block"}
                />
              </div>
            </div>
            <div className="md:text-base text-sm md:pt-4 pt-2 capitalize">{data?.review}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleDescriptionRating;
