import React, { useCallback, useEffect, useState } from "react";
import { classNames } from "../../../helpers/classNames";
import img2 from "../../../assets/images/admin/folder.avif";
import Image from "../../elements/Image";
import { useDebounce } from "../../../hooks/useDebounce";
import { toast } from "react-toastify";
import {
  deleteMediaFile,
  getMediaList,
  getTrashList,
  mediaFolderRestore,
  mediaRestore,
} from "../../../services/mediaService";
import ShowMediaContent from "./ShowMediaContent";
import Button from "../../form/Button";

const ShowTrashContent = ({ viewMode, setViewMode = () => {},mediaContentClass }) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [folderList, setFolderList] = useState([]);
  const [limit, setLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [restoreComplete, setRestoreComplete] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [count, setCount] = useState(0);
  const search = useDebounce(keyword, 500);

  // trash list >>>>>>>>>
  const loadList = useCallback(() => {
    setLoading(true);
    getTrashList({
      keyword: search,
      perPage: limit,
      page: currentPage,
    }).then((res) => {
      if (res && res?.docs?.success) {
        if (currentPage > 1) {
          setList((prevList) => ({
            ...prevList,
            loading: false,
            data: [...prevList.data, ...(res.docs?.media?.data || [])],
            pageCount: res?.docs?.media?.metadata?.totalPages,
            keyword: search,
            totalItem: res?.docs?.media?.metadata?.totalDocs,
          }));
        } else {
          setList({
            loading: false,
            data: res.docs?.media?.data,
            pageCount: res?.docs?.media?.metadata?.totalPages,
            keyword: search,
            totalItem: res?.docs?.media?.metadata?.totalDocs,
          });
        }

        setFolderList(res?.docs?.folders || []);
        setCount(res?.docs?.media.metadata?.totalDocs);
        setPagination({
          hasNextPage: res?.docs?.media?.metadata?.hasNextPage,
          hasPrevPage: res?.docs?.media?.metadata?.hasPrevPage,
        });
        setLoading(false);
      } else {
        setList((pre) => ({ ...pre, data: [], loading: false }));
        toast.error(res?.message);
        setLoading(false);
      }
    });
  }, [limit, currentPage, search]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const handleRestore = async (id) => {
    if (!id) {
      toast.error("No File  specified for restore");
      return;
    }
    setRestoreComplete(true);
    try {
      const res = await mediaRestore({ id: id });

      if (res?.status === 200) {
        loadList();
        toast.success(res?.data?.message || "File restore successfully");
        setList((prevList) => ({
          ...prevList,
          data: prevList.data.filter((item) => item.id !== id),
          totalItem: prevList.totalItem - 1,
        }));
      } else {
        toast.error(res?.data?.message || "Failed to restore file");
      }
    } catch (error) {
      toast.error(error?.message || "An error occurred while restore the file");
    } finally {
      setRestoreComplete(false);
    }
  };

  const handleFolderRestore = async (id) => {
    if (!id) {
      toast.error("No File  specified for restore");
      return;
    }
    setRestoreComplete(true);
    try {
      const res = await mediaFolderRestore({ id: id });

      if (res?.status === 200) {
        loadList();
        toast.success(res?.data?.message || "File restore successfully");
      } else {
        toast.error(res?.data?.message || "Failed to restore file");
      }
    } catch (error) {
      toast.error(error?.message || "An error occurred while restore the file");
    } finally {
      setRestoreComplete(false);
    }
  };
  return (
    <>
      <div className="flex justify-between items-center md:mt-0 mt-10">
        <div className="font-bold md:text-3xl text-2xl m-1 text-secondary pb-2">
          Trash List
        </div>
        <div className="flex gap-4">
        <div className="flex gap-1">
                  <div>
                    <Button
                      buttonClasses={"!h-10 !bg-black"}
                      buttonIcon={"fa-sharp fa-solid fa-grid-2"}
                      buttonIconPosition={"right"}
                      buttonLabel={""}
                      buttonLabelClasses={""}
                      buttonFunction={() => setViewMode("grid")}
                    />
                  </div>
                  <div>
                    <Button
                      buttonClasses={"!h-10 !bg-black"}
                      buttonIcon={"fa-regular fa-chart-tree-map"}
                      buttonIconPosition={"right"}
                      buttonLabel={""}
                      buttonLabelClasses={""}
                      buttonFunction={() => setViewMode("list")}
                    />
                  </div>
                </div>
        <div className="flex justify-center items-center">
          <input
            type="text"
            onChange={(e) => {
              setKeyword(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search..."
            value={keyword}
            className="w-full max-w-md p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          />
        </div>
        </div>
      </div>
      {/* Loading Spinner */}
      {loading && (
        <div className="flex items-center justify-center h-screen">
          <div className="w-16 h-16 border-4 border-red-500 border-t-transparent border-solid rounded-full animate-spin"></div>
        </div>
      )}
      <div className="mt-8">
        <ShowMediaContent
          loading={loading}
          list={list}
          setList={setList}
          folderList={folderList}
          loadList={loadList}
          pagination={pagination}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          handleRestore={handleRestore}
          handleFolderRestore={handleFolderRestore}
          isTrash={true}
          viewMode={viewMode}
          setViewMode={setViewMode}
          mediaContentClass={mediaContentClass}
        />
      </div>
    </>
  );
};

export default ShowTrashContent;
