import React, { useCallback, useEffect, useState } from "react";
import Input from "../form/Input";
import Select from "../form/Select";
import Button from "../form/Button";
import { getStateList } from "../../services/userService";
import {
  getProfileDetails,
  myBillingAddressEdit,
  myShippingAddressEdit,
} from "../../services/frontService/ProfileService";
import { useNavigate, useParams } from "react-router-dom";
import useForm from "../../hooks/useForm";
import { formValidate } from "../../helpers/formValidate";
import { toast } from "react-toastify";
import SelectDropdownSearch from "../form/SelectDropdownSearch";
import { setCartDetails } from "../../redux/slice/cartSlice";
import {
  cartListData,
  checkoutDetailsData,
} from "../../services/frontService/ProductService";
import { useDispatch } from "react-redux";
import { setCheckoutDetails } from "../../redux/slice/checkoutSlice";
import { setProfileDetails } from "../../redux/slice/profileSlice,";

const BillingAddressModal = ({ onClose, setDetails = () => {}, details }) => {
  const dispatch = useDispatch();
  const validation = {
    address: { required: true, message: "Please enter your address !!!" },
    address2: { required: true, message: "Please enter your address 2!!!" },
    city: { required: true, message: "Please enter city !!!" },
    pin: { required: true, message: "Please enter pin number !!!" },
    state: { required: true, message: "Please enter state !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  const navigate = useNavigate();
  const [stateKeyword, setStateKeyword] = useState("");
  const [stateList, setStateList] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (details?.id) {
      setFieldsValue({
        address: details?.address || "",
        address2: details?.address2 || "",
        city: details?.city || "",
        state: { name: details?.stateName, _id: Number(details?.state) },
        pin: details?.pin || "",
      });
    }
  }, [details]);

  const onBillSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await myBillingAddressEdit({
        id: details?.id,
        billingAddress: values?.address,
        billingAddress2: values?.address2,
        billingCity: values?.city || "",
        billingPin: values?.pin || "",
        billingState: values?.state?._id,
      });
      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.info(res?.data?.message);
        onClose();
        setDetails({});
        checkoutDetailsData().then((res) => {
          if (res?.status === 200) {
            dispatch(setCheckoutDetails(res?.data));
          } else {
            dispatch(setCheckoutDetails({}));
          }
        });
        getProfileDetails().then((res) => {
          if (res?.status === 200) {
            dispatch(setProfileDetails({ ...res?.data, role: "USER" }));
          }
        });
      } else {
        toast.info(
          res?.data?.message || res?.data?.errors || "Something went wrong"
        );
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  //get state list >>>>>>>>>
  const loadStateList = useCallback(() => {
    getStateList({
      keyword: stateKeyword,
      perPage: 9999999999,
    }).then((res) => {
      if (res && res?.success) {
        setStateList(res?.data);
      }
    });
  }, [stateKeyword]);

  useEffect(() => {
    loadStateList();
  }, [loadStateList]);
  return (
    <>
      <div className="relative w-full md:px-5 p-2 pt-0">
        <div className=" text-2xl absolute -top-14 right-2">
          {/* {/ <i className="fa-regular fa-xmark"></i> /} */}
          <Button
            buttonClasses={
              "!bg-white !h-8 !text-3xl !text-black hover:!text-white !text-xl hover:!bg-black hover:!border-black !text-center border border-gray-600 gap-3  transition duration-200 rounded-none leading-none !rounded-md"
            }
            // buttonLabel="Change Profile Photo"
            buttonIcon={"fa-regular fa-xmark"}
            buttonIconPosition={"right"}
            // buttonLabelClasses=""
            buttonEffect={"filled"}
            buttonFunction={onClose}
          />
        </div>
        <div>
          <form onSubmit={(event) => handleSubmit(event, onBillSubmit)}>
            <div className="py-2 md:px-2 px-0">
              <div className="grid grid-cols-5 gap-4 divide-x divide-slate-200">
                <div className="xl:col-span-5 col-span-5">
                  <div className="space-y-3">
                    <div className="grid grid-cols-12 gap-4">
                      <div className="relative md:col-span-6 col-span-12">
                        <Input
                          isInputGroup={true}
                          label={"Address"}
                          labelClasses={"!text-base"}
                          inputClasses={"!py-6"}
                          inputType={"text"}
                          value={values.address}
                          inputName={"address"}
                          onChange={handleChange}
                          inputPlaceholder={"Enter Address"}
                          {...formValidate(errors, "address")}
                        />
                      </div>
                      <div className="relative md:col-span-6 col-span-12">
                        <Input
                          isInputGroup={true}
                          label={"Address 2"}
                          labelClasses={"!text-base"}
                          inputClasses={"!py-6"}
                          inputType={"text"}
                          value={values.address2}
                          inputName={"address2"}
                          onChange={handleChange}
                          inputPlaceholder={"Enter Address 2"}
                          {...formValidate(errors, "address2")}
                        />
                      </div>
                      <div className="relative md:col-span-6 col-span-12">
                        <div className="relative col-span-6">
                          <SelectDropdownSearch
                            label={"Province "}
                            placeholder={"Select a province "}
                            labelClasses={"!text-base pb-1"}
                            xPlacement={"bottom"}
                            buttonArrowIcon={""}
                            selectName={"state"}
                            isSearch={true}
                            dropdownButtonClass="!px-3  !py-6 !border-gray-200 !w-full"
                            onChange={handleChange}
                            onSearch={(key) => setStateKeyword(key)}
                            value={values?.state}
                            dropdownData={stateList?.map((item) => ({
                              name: item?.name,
                              _id: item?.id,
                            }))}
                            {...formValidate(errors, "state")}
                          />
                        </div>
                      </div>
                      <div className="relative md:col-span-6 col-span-12">
                        <Input
                          isInputGroup={true}
                          label={"City"}
                          inputPlaceholder={"Enter  City"}
                          labelClasses={"!text-base"}
                          inputClasses={"!py-6"}
                          inputType={"city"}
                          value={values.city}
                          inputName={"city"}
                          onChange={handleChange}
                          {...formValidate(errors, "city")}
                        />
                      </div>
                      <div className="relative md:col-span-6 col-span-12">
                        <Input
                          isInputGroup={true}
                          label={"Postal Code"}
                          inputPlaceholder={"Enter Postal Code"}
                          inputClasses={"!py-6"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.pin}
                          inputName={"pin"}
                          onChange={handleChange}
                          {...formValidate(errors, "pin")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end space-x-5 pt-6 mt-6">
                <div className="">
                  <Button
                    buttonClasses={
                      "!bg-white !border !border-black  !px-10 !text-black  hover:!bg-black hover:!text-white"
                    }
                    buttonLabel="Cancel"
                    buttonLabelClasses="  !text-xl "
                    buttonFunction={onClose}
                    buttonType={"button"}
                  />
                </div>
                <div className="">
                  <Button
                    buttonClasses={"bg-red-600 hover:!bg-black !px-10"}
                    buttonLabel="Save"
                    buttonLabelClasses="  !text-xl "
                    buttonHasLink={false}
                    buttonType={"submit"}
                    isDisable={loaded}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BillingAddressModal;
