import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../../global.css";
import { FreeMode, Mousewheel, Navigation, Thumbs } from "swiper/modules";
import SwiperProduct from "../Items/SwiperProduct";
import { classNames } from "../../helpers/classNames";

export default function DetailsImageSection({ quickView = false, details,adminThumbGallery }) {
  const deviceWidth = window.innerWidth;
  const swiperParams = {
    breakpoints: {
      0: {
        slidesPerView: 1.2,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1280: {
        slidesPerView: 6,
        spaceBetween: 20,
      },
    },
  };

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // console.log(details,"hdxsd")

  const filteredProductImages = details?.productImages
    ?.filter((item) => item?.imageUrl !== null)
    ?.sort((a, b) => b.default_status - a.default_status);

  return (
    <>
      <div className="sm:flex sm:flex-row-reverse block">
        {filteredProductImages?.length > 0 && (
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            loop={true}
            spaceBetween={10}
            navigation={filteredProductImages?.length > 1 ? true : false}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="main-gallery"
          >
            {filteredProductImages?.map((item, index) => (
              <SwiperSlide key={index} className="relative !h-auto !flex">
                <SwiperProduct
                  data={item}
                  fancybox={true}
                  fancyboxAttribute="gallery"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {filteredProductImages?.length > 0 && !quickView && (
          <Swiper
            //  {...swiperParams}
            onSwiper={setThumbsSwiper}
            loop={true} 
            spaceBetween={10}
            slidesPerView={4}
            direction={deviceWidth < 600 ? "horizontal" : "vertical"}
            mousewheel={true}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs, Mousewheel]}
            className={classNames("thumb-gallery", adminThumbGallery)}
          >
            {filteredProductImages?.map((item, index) => (
              <SwiperSlide key={index}>
                <SwiperProduct
                  data={item}
                  // fancybox={true}
                  // fancyboxAttribute="gallery"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </>
  );
}
