import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setTitle } from "../../../helpers/MetaTag";
import { addCategory, categoryDetails, getParentCategoryList } from "../../../services/categoryService";
import SelectDropdownSearch from "../../../components/form/SelectDropdownSearch";

const CategoryAddEdit = () => {
  const location = useLocation();
  setTitle("HJL DESIGNS");
  const { id } = useParams();
  const validation = {
    category_name: { required: true, message: "Please enter your category name !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });
  const [parentCategoryList, setParentCategoryList] = useState({ data: [] });
  const [parentCategoryKeyword, setParentCategoryKeyword] = useState("");
  const [statusKeyword, setStatusKeyword] = useState("");

  const getDetails = useCallback(() => {
    if (id) {
      setDetailsData({ loading: true, data: {} });
      categoryDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          setFieldsValue({
            category_name: res?.docs?.category_name || "",
            parent_category: { name: res?.docs?.parent_category, _id: res?.docs?.parent_category_slug } || "",
            status: { name: res?.docs?.status, _id: res?.docs?.status } || "",
          });
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  useEffect(() => {
    getParentCategoryList({ keyword: parentCategoryKeyword, perPage: 9999 }).then((res) => {
      if (res && res?.status === 200) {
        setParentCategoryList({
          data: res.data,
        });
      }
    });
  }, []);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      if (!id) {
        const res = await addCategory({
          // id: id,
          parent_category: values?.parent_category?._id,
          category_name: values?.category_name,
          status: values?.status?._id,
        });

        if (res?.data?.status === 200 || res?.data?.status === 201) {
          navigate("/admin/category-list");
          toast.info(res?.data?.message);
        } else {
          toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
        }
      } else {
        const res = await addCategory({
          id: id,
          parent_category: values?.parent_category?._id,
          category_name: values?.category_name,
          status: values?.status?._id,
        });

        if (res?.data?.status === 200 || res?.data?.status === 201) {
          navigate(`/admin/category-list${location.search}`);
          toast.info(res?.data?.message);
        } else {
          toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
        }
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  const data = [
    { name: "Active", _id: 1 },
    { name: "In-Active", _id: 2 },
  ];

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <PageHeader
          title={detailsData?.data?.id ? "Edit Category" : "Add New Category"}
          headerActions={() => (
            <div className="flex items-center space-x-3">
              <Button
                buttonHasLink={true}
                buttonLink={`/admin/category-list${location.search}`}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonLabelClasses={"md:block hidden"}
                buttonClasses={"!bg-white !text-red-600 !border-red-600 !border-2"}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-red-600 !text-org-silver"}
                buttonLabel={"Save"}
                buttonLabelClasses={"md:block hidden"}
                isDisable={loaded}
              />
            </div>
          )}
        />
        <div className="py-2 md:px-6 px-0">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-5 gap-4 divide-x divide-slate-200 md:px-10 px-0">
              <div className="xl:col-span-6 col-span-5">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-list"}
                        inputGroupPosition={"left"}
                        label={"Category Name"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.category_name}
                        inputName={"category_name"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Category Name"}
                        {...formValidate(errors, "category_name")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <SelectDropdownSearch
                        label={"Parent Category Name"}
                        placeholder={"Select Category Name  "}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"parent_category"}
                        isSearch={true}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={handleChange}
                        onSearch={(key) => setParentCategoryKeyword(key)}
                        value={values?.parent_category}
                        dropdownData={
                          parentCategoryList &&
                          parentCategoryList?.data?.map((item) => ({
                            name: item?.name,
                            _id: item?.slug,
                          }))
                        }
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <SelectDropdownSearch
                        label={"Status  "}
                        placeholder={"Select Status"}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"status"}
                        isSearch={false}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={handleChange}
                        onSearch={(key) => setStatusKeyword(key)}
                        value={values?.status}
                        dropdownData={data}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3 w-full justify-end">
                <Button
                  buttonHasLink={true}
                  buttonLink={`/admin/category-list${location.search}`}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={"!bg-white !text-primary !border-primary !border-2"}
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-primary !text-org-silver"}
                  buttonLabel={"Save"}
                  isDisable={loaded}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CategoryAddEdit;
