import React from "react";
import MainBanner from "../components/sections/MainBanner";
import ContactInfo from "../components/sections/ContactInfo";
import SmallBanner from "../components/sections/SmallBanner";

const ContactUs = () => {
  const slug = "contact-us";
  return (
    <>
      {/* <MainBanner/> */}
      <SmallBanner slug={slug} />
      <ContactInfo />
    </>
  );
};

export default ContactUs;
