import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "../../../components/form/Button";
import Image from "../../../components/elements/Image";
import { orderDetails } from "../../../services/orderService";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { settingsDetails } from "../../../services/sitesettingService";
import PageLoader from "../../../components/common/PageLoader";
import dayjs from "dayjs";
import LOGO from "../../../assets/images/logo.png";
import invoice from "../../../assets/images/invoice.jpg";
import moment from "moment/moment";

const OrderDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [detailsData, setDetailsData] = useState({});
  const [imageLoaded, setImageLoaded] = useState(false);
  const pdfRef = useRef();
  const [logoData, setLogoData] = useState({});
  // const [logoBase64, setLogoBase64] = useState("");

  const subTotal = detailsData?.data?.orderDetailsInfo
    ?.reduce((total, cart) => {
      return total + cart?.actual_price * cart?.quantity;
    }, 0)
    .toFixed(2);

  const getDetails = useCallback(() => {
    setDetailsData({ loading: true, data: {} });
    orderDetails(id).then((res) => {
      if (res?.status === 200) {
        setDetailsData({
          loading: false,
          data: res?.docs,
          name: JSON.parse(res?.docs?.user_info)?.name,
          email: JSON.parse(res?.docs?.user_info)?.email,
          contact_no: JSON.parse(res?.docs?.user_info)?.contact_no,
          shippingLocation: JSON.parse(res?.docs?.user_info)?.address_info?.shipping_address?.location,
          shippingState: JSON.parse(res?.docs?.user_info)?.address_info?.shipping_address?.state,
          shippingCity: JSON.parse(res?.docs?.user_info)?.address_info?.shipping_address?.city,
          shippingPincode: JSON.parse(res?.docs?.user_info)?.address_info?.shipping_address?.pincode,
          billingAddress: JSON.parse(res?.docs?.user_info)?.address_info?.billing_address?.address,
          billingAddress2: JSON.parse(res?.docs?.user_info)?.address_info?.billing_address?.address2,
          billingState: JSON.parse(res?.docs?.user_info)?.address_info?.billing_address?.state,
          billingCity: JSON.parse(res?.docs?.user_info)?.address_info?.billing_address?.city,
          billingPincode: JSON.parse(res?.docs?.user_info)?.address_info?.billing_address?.pincode,
        });
      }
      setDetailsData({
        loading: false,
        data: res?.docs,
        name: JSON.parse(res?.docs?.user_info)?.name,
        email: JSON.parse(res?.docs?.user_info)?.email,
        contact_no: JSON.parse(res?.docs?.user_info)?.contact_no,
        shippingLocation: JSON.parse(res?.docs?.user_info)?.address_info?.shipping_address?.location,
        shippingState: JSON.parse(res?.docs?.user_info)?.address_info?.shipping_address?.state,
        shippingCity: JSON.parse(res?.docs?.user_info)?.address_info?.shipping_address?.city,
        shippingPincode: JSON.parse(res?.docs?.user_info)?.address_info?.shipping_address?.pincode,
        billingAddress: JSON.parse(res?.docs?.user_info)?.address_info?.billing_address?.address,
        billingAddress2: JSON.parse(res?.docs?.user_info)?.address_info?.billing_address?.address2,
        billingState: JSON.parse(res?.docs?.user_info)?.address_info?.billing_address?.state,
        billingCity: JSON.parse(res?.docs?.user_info)?.address_info?.billing_address?.city,
        billingPincode: JSON.parse(res?.docs?.user_info)?.address_info?.billing_address?.pincode,
      });
    });
  }, []);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const getSettingDetails = useCallback(() => {
    setLogoData({ loading: true, data: {} });
    settingsDetails().then((res) => {
      if (res?.status === 200) {
        setLogoData({ loading: true, data: res?.docs?.logoUrl });
      }
    });
  }, []);

  useEffect(() => {
    getSettingDetails();
  }, [getSettingDetails]);

  const downloadPdf = () => {
    const element = pdfRef.current;
    var opt = {
      margin: 0.4,
      filename: "Order_Invoice.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    if (element) {
      html2pdf(element, opt);
    }
  };

  const handleBack = () => {
    const page = location.search;
    navigate(`/admin/order-list${page}`);
  };

  return (
    <>
      {detailsData?.loading ? (
        <PageLoader />
      ) : (
        <div>
          <div className="md:flex block space-x-6 mt-5">
            <Button
              buttonClasses={
                "!bg-white !p-1 !text-gray-600 !text-2xl hover:!text-slate-900 h-10 !px-2 flex items-center justify-center transition-all duration-200 !border !border-gray-300 !w-14"
              }
              buttonLabelClasses={""}
              buttonIcon={"fa-solid fa-arrow-left"}
              buttonIconPosition={"left"}
              buttonHasLink={false}
              buttonFunction={handleBack}
            />
          </div>

          <div className="md:flex block w-full bg-white border p-5 rounded-xl shadow-md mt-8">
            <div className="w-full">
              <div className="md:text-xl text-xl text-black font-bold gap-2">
                Order Id : {detailsData?.data?.order_id ? detailsData?.data?.order_id : null}
              </div>
              <div className="md:text-xl text-xl text-black font-semibold mt-1 mb-2">
                Order Date :{" "}
                {detailsData?.data?.created_at ? dayjs(detailsData?.data?.created_at).format("MMM DD,YYYY") : null}
              </div>
              <div className="md:text-xl text-xl text-black font-semibold mt-1 mb-2">
                Payment : {detailsData?.data?.payment ? detailsData?.data?.payment : null}
              </div>
              <div className="md:text-xl text-xl text-black font-semibold mt-1 mb-2">
                Payment Status :{" "}
                {detailsData?.data?.payment_status === 1 ? (
                  <span className="text-green-600">Paid</span>
                ) : (
                  <span className="text-amber-600">Pending</span>
                )}
              </div>
            </div>
            <div>
              <div className="w-full flex justify-end space-x-4 md:mt-2 mt-4">
                <Button
                  buttonClasses={
                    "!bg-white !p-1 !text-black !text-2xl hover:!text-slate-900 h-10 !px-4 flex items-center justify-center transition-all duration-200 !border !border-gray-300"
                  }
                  buttonLabel={"Download"}
                  buttonLabelClasses={""}
                  buttonIcon={"fa-solid fa-file-pdf"}
                  buttonIconPosition={"left"}
                  buttonHasLink={false}
                  buttonFunction={downloadPdf}
                />
              </div>
              {detailsData?.data?.status === 1 ? (
                <div className="w-36 rounded-md mt-3 text-base bg-amber-100 text-amber-600 flex justify-center items-center">
                  {detailsData?.data?.orderStatusInfo?.name || ""} (
                  {detailsData?.data?.orderStatusInfo?.processing_parcentage || ""})
                </div>
              ) : detailsData?.data?.status === 2 ? (
                <div className="w-36 rounded-md mt-3 text-base bg-green-100 text-green-600 flex justify-center items-center">
                  {detailsData?.data?.orderStatusInfo?.name || ""} (
                  {detailsData?.data?.orderStatusInfo?.processing_parcentage || ""})
                </div>
              ) : detailsData?.data?.status === 3 ? (
                <div className="w-48 rounded-md mt-3 text-base bg-pink-100 text-pink-600 flex justify-center items-center">
                  {detailsData?.data?.orderStatusInfo?.name || ""} (
                  {detailsData?.data?.orderStatusInfo?.processing_parcentage || ""})
                </div>
              ) : detailsData?.data?.status === 4 ? (
                <div className="w-36 rounded-md mt-3 text-base bg-blue-100 text-blue-600 flex justify-center items-center">
                  {detailsData?.data?.orderStatusInfo?.name || ""} (
                  {detailsData?.data?.orderStatusInfo?.processing_parcentage || ""})
                </div>
              ) : detailsData?.data?.status === 5 ? (
                <div className="w-24 rounded-md mt-3 text-base bg-red-100 text-red-600 flex justify-center items-center">
                  {detailsData?.data?.orderStatusInfo?.name || ""}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="flex flex-col mt-8 gap-6 md:flex-row xl:flex-nowrap md:flex-wrap">
            <div className="w-full rounded-md bg-white shadow-md p-6 text-gray-600 font-bold space-y-4 xl:w-1/3 md:w-5/12">
              <h3 className="text-xl text-black uppercase">Customer and Order</h3>
              <div className="space-y-2">
                <div className="flex 3xl:space-x-2">
                  <div className="text-gray-800 3xl:w-5/12 w-4/6">Name :</div>
                  <div className="text-gray-600 font-medium w-full">{detailsData?.name ? detailsData.name : "-"}</div>
                </div>
                <div className="flex 3xl:space-x-2">
                  <div className="text-gray-800 3xl:w-5/12 w-4/6">Email :</div>
                  <div className="text-gray-600 font-medium w-full break-all">
                    {detailsData?.email ? detailsData.email : "-"}
                  </div>
                </div>
                <div className="flex 3xl:space-x-2">
                  <div className="text-gray-800 3xl:w-5/12 w-4/6">Phone :</div>
                  <div className="text-gray-600 font-medium w-full">
                    {detailsData?.contact_no ? detailsData.contact_no : "-"}
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full rounded-md bg-white shadow-md p-6 text-gray-600 font-bold xl:w-1/3  md:w-6/12 space-y-4">
              <h3 className="text-xl text-black uppercase">Shipping Address</h3>

              <div className=" space-y-2">
                {detailsData.shippingLocation && (
                  <div className="flex 3xl:space-x-2">
                    <div className="text-gray-800 3xl:w-5/12 w-4/6">Location :</div>
                    <div className="text-gray-600 font-medium w-full">{detailsData.shippingLocation}</div>
                  </div>
                )}
                {detailsData.shippingCity && (
                  <div className="flex 3xl:space-x-2">
                    <div className="text-gray-800 3xl:w-5/12 w-4/6">City :</div>
                    <div className="text-gray-600 font-medium w-full">{detailsData.shippingCity}</div>
                  </div>
                )}
                {detailsData.shippingState && (
                  <div className="flex 3xl:space-x-2">
                    <div className="text-gray-800 3xl:w-5/12 w-4/6">State :</div>
                    <div className="text-gray-600 font-medium w-full">{detailsData.shippingState}</div>
                  </div>
                )}
                {detailsData?.shippingPincode && (
                  <div className="flex 3xl:space-x-2">
                    <div className="text-gray-800 3xl:w-5/12 w-4/6">Postal code :</div>
                    <div className="text-gray-600 font-medium w-full">{detailsData?.shippingPincode}</div>
                  </div>
                )}
                <div className="pl-6 space-y-2"></div>
              </div>
            </div>

            <div className="w-full rounded-md bg-white shadow-md p-6 text-gray-600 font-bold xl:w-1/3 md:w-6/12 space-y-4">
              <h3 className="text-xl text-black uppercase">Billing Address</h3>

              <div className="space-y-2">
                {detailsData.billingAddress ? (
                  <div className="flex 3xl:space-x-2">
                    <div className="text-gray-800 3xl:w-5/12 w-4/6">Address :</div>
                    <div className="text-gray-600 font-medium w-full">{detailsData.billingAddress}</div>
                  </div>
                ) : (
                  <div className="flex 3xl:space-x-2">
                    <div className="text-gray-800 3xl:w-5/12 w-4/6">Address :</div>
                    <div className="text-gray-600 font-medium w-full">{detailsData.shippingLocation}</div>
                  </div>
                )}
                {detailsData.billingAddress2 && (
                  <div className="flex 3xl:space-x-2">
                    <div className="text-gray-800 3xl:w-5/12 w-4/6">Address 2 :</div>
                    <div className="text-gray-600 font-medium w-full">{detailsData.billingAddress2}</div>
                  </div>
                )}
                {detailsData.billingCity ? (
                  <div className="flex 3xl:space-x-4">
                    <div className="text-gray-800 3xl:w-5/12 w-4/6">City :</div>
                    <div className="text-gray-600 font-medium w-full">{detailsData.billingCity}</div>
                  </div>
                ) : (
                  <div className="flex 3xl:space-x-4">
                    <div className="text-gray-800 3xl:w-5/12 w-4/6">City :</div>
                    <div className="text-gray-600 font-medium w-full">{detailsData.shippingCity}</div>
                  </div>
                )}
                {detailsData.billingState ? (
                  <div className="flex 3xl:space-x-4">
                    <div className="text-gray-800 3xl:w-5/12 w-4/6">State :</div>
                    <div className="text-gray-600 font-medium w-full">{detailsData.billingState}</div>
                  </div>
                ) : (
                  <div className="flex 3xl:space-x-4">
                    <div className="text-gray-800 3xl:w-5/12 w-4/6">State :</div>
                    <div className="text-gray-600 font-medium w-full">{detailsData.shippingState}</div>
                  </div>
                )}
                {detailsData?.billingPincode ? (
                  <div className="flex 3xl:space-x-4">
                    <div className="text-gray-800 3xl:w-5/12 w-4/6">Postal code :</div>
                    <div className="text-gray-600 font-medium w-full">{detailsData?.billingPincode}</div>
                  </div>
                ) : (
                  <div className="flex 3xl:space-x-4">
                    <div className="text-gray-800 3xl:w-5/12 w-4/6">Postal code :</div>
                    <div className="text-gray-600 font-medium w-full">{detailsData?.shippingPincode}</div>
                  </div>
                )}

                <div className="pl-6 space-y-2"></div>
              </div>
            </div>
          </div>

          <div className="overflow-x-auto shadow-md rounded-lg mt-10">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 border-b border-gray-400">
                <tr className="border-b border-gray-400 bg-white">
                  <th
                    colSpan="8"
                    className="px-4 py-2 text-left text-xl text-gray-600 font-bold tracking-wider border-b border-gray-800 capitalize">
                    Items <span className="font-bold text-red-600">Order</span>
                  </th>
                </tr>
                <tr>
                  <th className="px-4 py-2 text-left text-md text-gray-800 font-bold uppercase tracking-wider">
                    Items Name
                  </th>
                  <th className="px-4 py-2 text-left text-md text-gray-800 font-bold uppercase tracking-wider">
                    Order Id
                  </th>
                  <th className="px-4 py-2 text-center text-md text-gray-800 font-bold uppercase tracking-wider">
                    Quantity
                  </th>
                  <th className="px-4 py-2 text-center text-md text-gray-800 font-bold uppercase tracking-wider">
                    Category Name
                  </th>
                  <th className="px-4 py-2 text-center text-md text-gray-800 font-bold uppercase tracking-wider">
                    No Of Packages
                  </th>
                  <th className="px-4 py-2 text-center text-md text-gray-800 font-bold uppercase tracking-wider">
                    Actual Price
                  </th>
                  <th className="px-4 py-2 text-center text-md text-gray-800 font-bold uppercase tracking-wider">
                    Total Price
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {detailsData?.data?.orderDetailsInfo?.map((item) => (
                  <tr key={item?.order_id}>
                    <td className="px-4 py-2 whitespace-nowrap text-sm font-semibold text-gray-900">
                      <div className="flex items-center">
                        <div className="w-10 h-10 overflow-hidden rounded-lg">
                          <Image
                            src={item?.productImageUrl}
                            alt=""
                            effect="blur"
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <Link to={`/admin/product-details/${item?.product_id}`}>
                          <div className="px-2 text-md text-gray-600 font-bold">{item?.product_name || ""}</div>
                        </Link>
                      </div>
                    </td>
                    <td className="px-4 py-2 text-md text-gray-700 font-semibold md:text-start text-center">
                      {item?.order_id || ""}
                    </td>
                    <td className="px-4 py-2 text-md text-gray-700 font-semibold text-center">
                      {item?.quantity || ""}
                    </td>
                    <td className="px-4 py-2 text-md text-gray-700 font-semibold text-center">
                      {item?.product_category_name || ""}
                    </td>
                    <td className="px-4 py-2 text-md text-gray-700 font-semibold text-center">
                      {item?.package_qty || ""}
                    </td>
                    <td className="px-4 py-2 text-md text-gray-700 font-semibold text-center">
                      {item?.actual_price?.toFixed(2) || ""}
                    </td>
                    <td className="px-4 py-2 text-md text-gray-700 font-semibold text-center">
                      {item?.total_price?.toFixed(2) || ""}
                    </td>
                  </tr>
                ))}
                <tr className="!border-t-1 !border-slate-400">
                  {/* <td colSpan="4"></td> */}
                  <td className="px-4 pt-2 text-lg text-gray-700 font-bold text-end" colSpan="5">
                    Sub Total
                  </td>
                  <td className="px-4 pt-2 text-lg text-gray-500 font-semibold text-right" colSpan="2">
                    CAD {subTotal || ""}
                  </td>
                </tr>
                <tr className="!border-0">
                  {/* <td colSpan="5"></td> */}
                  <td className="px-4 py-2 text-lg text-gray-700 font-bold text-end" colSpan="5">
                    GST/HST Charge ({detailsData?.data?.vat_percentage}%)
                  </td>
                  <td className="px-4 py-2 text-lg text-gray-500 font-semibold text-right" colSpan="2">
                    CAD {detailsData?.data?.vat_charge.toFixed(2) || ""}
                  </td>
                </tr>
                <tr className="!border-0">
                  {/* <td colSpan="5"></td> */}
                  <td className="px-4 pb-2 text-lg text-gray-700 font-bold text-end" colSpan="5">
                    Delivery Charge
                  </td>
                  <td className="px-4 pb-2 text-lg text-gray-500 font-semibold text-right" colSpan="2">
                    {detailsData?.data?.delivery_charge === 0.0
                      ? "Free"
                      : "CAD " + detailsData?.data?.delivery_charge.toFixed(2)}
                  </td>
                </tr>
                <tr className="!border-t-2 !border-slate-400">
                  {/* <td colSpan="5"></td> */}
                  <td className="px-4 py-2 text-2xl text-red-600 font-bold text-end" colSpan="5">
                    Grand Total
                  </td>
                  <td
                    className="px-4 py-2 text-2xl text-red-500 font-semibold text-right !border-slate-400 !border-t-2"
                    colSpan="2">
                    CAD {detailsData?.data?.total_price.toFixed(2) || ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* <div className="hidden">
            <div ref={pdfRef}>
              <div className="max-w-3xl mx-auto p-6 bg-white rounded shadow-sm my-6" id="invoice">
                <div className="grid grid-cols-2 items-center">
                  <div className="w-24 h-24 overflow-hidden">
                    <img src={LOGO} alt="Logo" />
                  </div>

                  <div className="text-right">
                    <p className="text-black font-bold text-lg">{detailsData?.name ? detailsData.name : ""}</p>
                    <p className="text-gray-500 text-lg">{detailsData?.email ? detailsData.email : ""}</p>
                    <p className="text-gray-500 text-lg mt-1">
                      {detailsData?.contact_no ? detailsData.contact_no : ""}
                    </p>
                  </div>
                </div>

                <div className="grid grid-cols-2 items-center mt-8">
                  <div>
                    <p className="font-bold text-black text-lg">Bill To :</p>
                    <p className="text-gray-500 text-lg">
                      {detailsData.billingLocation || ""}
                      <br />
                      {detailsData.billingCity || ""}
                      {detailsData.billingState || ""}
                    </p>
                    <p className="text-gray-500 text-lg">{detailsData?.billingPincode || ""}</p>
                  </div>

                  <div className="text-right text-lg">
                    <p className="text-black font-bold ">
                      Order Id:
                      <span className="text-gray-500">
                        {detailsData?.data?.order_id ? detailsData?.data?.order_id : ""}
                      </span>
                    </p>
                    <p className="text-black font-bold">
                      Order Date:{" "}
                      <span className="text-gray-500">
                        {moment(detailsData?.data?.created_at).format("DD/MM/YYYY") || ""}
                      </span>
                      <br />
                    </p>
                  </div>
                </div>

                <div className="-mx-4 mt-8 flow-root sm:mx-0">
                  <table className="min-w-full">
                    <thead className="border-b border-gray-400 text-gray-900">
                      <tr>
                        <th scope="col" className="py-2 pl-2 pr-2 text-left text-lg font-bold text-gray-900 sm:pl-0">
                          Items Name
                        </th>
                        <th
                          scope="col"
                          className="hidden px-2 py-2 text-center text-lg font-bold text-gray-900 sm:table-cell">
                          Quantity
                        </th>
                        <th
                          scope="col"
                          className="hidden px-2 py-2 text-right text-lg font-bold text-gray-900 sm:table-cell">
                          Price
                        </th>
                        <th scope="col" className="py-2 pl-2 pr-2 text-right text-lg font-bold text-gray-900 sm:pr-0">
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {detailsData?.data?.orderDetailsInfo?.map((item) => (
                        <tr className="border-b border-gray-400" key={item?.order_id}>
                          <td className="max-w-0 py-3 pl-2 pr-2 text-sm sm:pl-0">
                            <div className="font-bold text-gray-900">{item?.product_name || ""}</div>
                          </td>
                          <td className="hidden px-2 py-3 text-center text-sm text-gray-500 sm:table-cell">
                            {item?.quantity || ""}
                          </td>
                          <td className="hidden px-2 py-3 text-right text-sm text-gray-500 sm:table-cell">
                            {item?.actual_price || ""}
                          </td>
                          <td className="py-3 pl-2 pr-2 text-right text-sm text-gray-500 sm:pr-0">
                            {item?.total_price || ""}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <th
                          scope="row"
                          colspan="3"
                          className="hidden pl-2 pr-2 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0">
                          Sub Total
                        </th>
                        <th
                          scope="row"
                          className="pl-4 pr-2 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden">
                          Sub Total
                        </th>
                        <td className="pl-2 pr-2 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">
                          {detailsData?.data?.total_price || ""}
                        </td>
                      </tr>

                      <tr>
                        <th
                          scope="row"
                          colspan="3"
                          className="hidden pl-2 pr-2 pt-2 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0">
                          VAT Charges
                        </th>
                        <th
                          scope="row"
                          className="pl-4 pr-2 pt-2 text-left text-sm font-normal text-gray-500 sm:hidden">
                          VAT Charges
                        </th>
                        <td className="pl-2 pr-2 pt-2 text-right text-sm text-gray-900 sm:pr-0">
                          {detailsData?.data?.vat_charge || ""}
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          colspan="3"
                          className="hidden pl-2 pr-2 pt-2 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0">
                          Delivery Charges
                        </th>
                        <th
                          scope="row"
                          className="pl-4 pr-2 pt-2 text-left text-sm font-normal text-gray-500 sm:hidden">
                          Delivery Charges
                        </th>
                        <td className="pl-2 pr-2 pt-2 text-right text-sm text-gray-900 sm:pr-0">
                          {detailsData?.data?.delivery_charge || ""}
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          colspan="3"
                          className="hidden pl-2 pr-2 pt-2 text-right text-lg font-bold text-gray-900 sm:table-cell sm:pl-0 ">
                          GRAND TOTAL
                        </th>
                        <th scope="row" className="pl-4 pr-2 pt-2 text-left text-lg font-bold text-gray-900 sm:hidden ">
                          GRAND TOTAL
                        </th>
                        <td className="pl-2 pr-2 pt-2 text-right text-lg text-gray-900 sm:pr-0 font-bold">
                          {detailsData?.data?.total_price || ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> */}

          <div className="hidden">
            <div ref={pdfRef} className="p-4">
              <div className="flex lg:justify-between border-b pb-2 border-gray-500">
                <div>
                  <img src={LOGO} alt="logo" className="h-24 w-24 object-contain" />
                </div>
                <div className="mt-4 text-right">
                  <p className="mb-1 font-semibold text-4xl">
                    <img src={invoice} alt="invoice" className="h-12 w-64 object-contain" />
                  </p>
                  <p className="text-sm font-normal">
                    Order ID : <span className="font-semibold text-lg">{detailsData?.data?.order_id || "N/A"}</span>
                  </p>
                  <p className="text-xs font-normal">
                    Date :{" "}
                    <span className="text-sm">
                      {detailsData?.data?.created_at
                        ? dayjs(detailsData?.data?.created_at).format("DD/MM/YYYY")
                        : "N/A"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex justify-between mt-3 border-b pb-4 border-gray-500 gap-4">
                <div className="text-left">
                  <span className="text-sm font-bold">Contact Info</span>
                  <p className="font-semibold text-sm">{detailsData?.name || ""}</p>
                  <p className="text-xs font-normal">{detailsData?.email || ""}</p>
                  <p className="text-xs font-normal">{detailsData?.contact_no || ""}</p>
                </div>

                <div className="text-center">
                  <span className="text-sm font-bold">Shipping Address</span>
                  <p className="text-xs font-normal">
                    {detailsData.shippingLocation ? detailsData.shippingLocation : ""}
                  </p>
                  <p className="text-xs font-normal">
                    {` ${detailsData.shippingCity ? detailsData.shippingCity : ""}`}
                  </p>
                  <p className="text-xs font-normal">{detailsData.shippingState ? detailsData.shippingState : ""}</p>
                  <p className="text-xs font-normal">
                    {detailsData.shippingPincode ? detailsData.shippingPincode : ""}
                  </p>
                </div>

                <div className="text-right">
                  <span className="text-sm font-bold">Billing Address</span>
                  <p className="text-xs font-normal">
                    {detailsData.billingAddress ? detailsData.billingAddress : detailsData.shippingLocation}
                  </p>
                  <p className="text-xs font-normal">
                    {`${detailsData.billingAddress2 ? detailsData.billingAddress2 : ""} ${
                      detailsData.billingCity ? detailsData.billingCity : detailsData.shippingCity
                    }`}
                  </p>
                  <p className="text-xs font-normal">
                    {detailsData.billingState ? detailsData.billingState : detailsData.shippingState}
                  </p>
                  <p className="text-xs font-normal">
                    {detailsData.billingPincode ? detailsData.billingPincode : detailsData?.shippingPincode}
                  </p>
                </div>
              </div>

              <div className="-mx-4 flow-root sm:mx-0">
                <table className="min-w-full">
                  <thead className="border-b border-gray-500 text-gray-900">
                    <tr>
                      <th scope="col" className="py-2 pl-2 pr-2 text-left text-sm font-bold text-gray-900 sm:pl-0">
                        Items Name
                      </th>
                      <th
                        scope="col"
                        className="hidden px-2 py-2 text-center text-sm font-bold text-gray-900 sm:table-cell">
                        Quantity
                      </th>
                      <th
                        scope="col"
                        className="hidden px-2 py-2 text-center text-sm font-bold text-gray-900 sm:table-cell">
                        No Of Packages
                      </th>
                      <th
                        scope="col"
                        className="hidden px-2 py-2 text-right text-sm font-bold text-gray-900 sm:table-cell">
                        Price
                      </th>
                      <th scope="col" className="py-2 pl-2 pr-2 text-right text-sm font-bold text-gray-900 sm:pr-0">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailsData?.data?.orderDetailsInfo?.map((item) => (
                      <tr key={item?.order_id}>
                        <td className="max-w-0 py-3 pl-2 pr-2 text-xs sm:pl-0">
                          <div className="font-bold text-gray-900">{item?.product_name || ""}</div>
                        </td>

                        <td className="hidden px-2 py-3 text-center text-xs text-gray-700 sm:table-cell">
                          {item?.quantity || ""}
                        </td>
                        <td className="hidden px-2 py-3 text-center text-xs text-gray-700 sm:table-cell">
                          {item?.package_qty || ""}
                        </td>
                        <td className="hidden px-2 py-3 text-right text-xs text-gray-700 sm:table-cell">
                          {item?.actual_price.toFixed(2) || ""}
                        </td>
                        <td className="py-3 pl-2 pr-2 text-right text-xs text-gray-700 sm:pr-0">
                          {item?.total_price.toFixed(2) || ""}
                        </td>
                      </tr>
                    ))}
                    <tr className="border-t border-gray-500">
                      <th
                        scope="row"
                        colspan="5"
                        className="hidden pl-2 pr-2 pt-2 text-right text-sm font-normal text-gray-700 sm:table-cell sm:pl-0">
                        <div className="flex justify-end">
                          <div className="border-b border-gray-500 w-[270px] pb-2 space-y-2">
                            <div className="flex gap-2 items-center">
                              <div className="flex-grow">Sub Total</div>
                              <div className="flex-grow-0 text-right text-md font-semibold text-gray-900 w-[120px]">
                                CAD {subTotal || ""}
                              </div>
                            </div>
                            <div className="flex gap-2 items-center">
                              <div className="flex-grow">GST/HST Charge ({detailsData?.data?.vat_percentage}%)</div>
                              <div className="flex-grow-0 text-right text-md text-gray-900 w-[120px]">
                                CAD {detailsData?.data?.vat_charge.toFixed(2) || ""}
                              </div>
                            </div>
                            <div className="flex gap-2 items-center">
                              <div className="flex-grow"> Delivery Charge</div>
                              <div className="flex-grow-0 text-right text-md text-gray-900 w-[120px]">
                                {detailsData?.data?.delivery_charge === 0.0
                                  ? "Free"
                                  : "CAD" + detailsData?.data?.delivery_charge.toFixed(2)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        colspan="5"
                        className="hidden pl-2 pr-2  text-right text-lg font-semibold text-gray-900 sm:table-cell sm:pl-0">
                        <div className="flex justify-end">
                          <div className="flex gap-2 items-center">
                            <div className="flex-grow">Grand Total</div>
                            <div className="flex-grow-0 text-right w-[120px]">
                              CAD {detailsData?.data?.total_price.toFixed(2) || ""}
                            </div>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderDetails;
