import React, { useState } from "react";
import Image from "../elements/Image";
import Review from "./Review";
import { country } from "../../helpers";
import image2 from "../../assets/images/cards/card-inside-2.webp";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addToCartProduct } from "../../services/frontService/ProductService";
import { toast } from "react-toastify";
import { setCartDetails } from "../../redux/slice/cartSlice";
import { loginModalOpen } from "../../redux/slice/loginSlice";

const SingleMyWishlist = ({ data, addWishList = () => {} }) => {
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  const handleAddToCart = async (data, quantity) => {
    if (isLogin) {
      setLoaded(true);
      try {
        const res = await addToCartProduct({
          product_id: data?.wishListProductInfo?.id,
          quantity: data?.wishListProductInfo?.minimum_package_quantity || 1,
        });
        if (res?.data?.status === 200 || res?.data?.status === 201) {
          toast.info(res?.data?.message);
          dispatch(setCartDetails(res?.data?.data));
        } else {
          toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
        }
        setLoaded(false);
      } catch (err) {
        setLoaded(false);
        // console.error(err);
        toast.error("Somthing went wrong !!!");
      }
    } else {
      dispatch(loginModalOpen({ isOpen: true }));
    }
  };

  return (
    <div className="relative flex xl:gap-10 gap-6 md:py-6 py-4">
      <div className="relative">
        <div className="md:w-32 md:h-32 w-24 h-24 overflow-hidden rounded-md">
          <Image
            src={data?.wishListProductInfo?.productImageUrl}
            classes="w-full h-full object-cover"
            alt="product image"
          />
        </div>
      </div>
      <div className="relative flex w-full">
        <div className="relative w-full">
          <Link to={`/productdetails/${data?.wishListProductInfo?.alias}`}>
            <div className="md:text-xl text-base font-bold capitalize text-slate-900 leading-none">
              {data?.wishListProductInfo?.title}
            </div>
            <div className="relative md:text-base text-sm text-slate-600 mt-2">{data.title}</div>
          </Link>
          <div>
            <Review
              rating={data?.wishListProductInfo?.averageRating}
              totalReviews={data?.wishListProductInfo?.totalReview}
              isReviewShow={true}
              classes={"md:flex block"}
            />
          </div>
          <div className="flex space-x-2 py-1 items-center">
            <div className="md:text-base text-sm">Quantity :</div>
            <div>{data?.wishListProductInfo?.minimum_package_quantity}</div>
          </div>
        </div>
        {/* <div className="relative md:w-5/12 w-full">
          <div
            className="text-black text-2xl absolute top-0 right-0 cursor-pointer"
            onClick={() => handleAddToCart(data)}>
            <i className="fa-light fa-bag-shopping"></i>
          </div>
        </div>
        <div className="relative md:w-5/12 w-full">
          <div
            className="text-red-600 text-2xl absolute top-0 right-0 cursor-pointer"
            onClick={() => addWishList(data)}>
            <i className="fa-solid fa-heart"></i>
          </div>
          <div className="xl:text-xl lg:text-lg text-base md:py-2 py-1 text-black font-bold absolute md:top-12 top-6 md:right-0 right-1">
            {country + " " + (data?.wishListProductInfo?.price || "")}
          </div>
        </div> */}
        <div className="relative md:w-5/12 w-full flex justify-end space-x-4">
          <div className="text-black text-2xl cursor-pointer" onClick={() => handleAddToCart(data)}>
            <i className="fa-light fa-bag-shopping"></i>
          </div>
          <div className="text-red-600 text-2xl cursor-pointer" onClick={() => addWishList(data)}>
            <i className="fa-solid fa-heart"></i>
          </div>
          <div className="xl:text-xl lg:text-lg text-base md:py-2 py-1 text-black font-bold absolute md:top-12 top-6 md:right-0 right-1">
            {country + " " + (data?.wishListProductInfo?.price || "")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleMyWishlist;
