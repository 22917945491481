import React, { useCallback, useEffect, useState } from "react";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { Validation } from "../../../helpers/Validation";
import { toast } from "react-toastify";
// import {  useParams } from "react-router-dom";
import { setTitle } from "../../../helpers/MetaTag";
import { changePassword } from "../../../services/frontService/ProfileService";

const ChangePassword = () => {
  setTitle("HJL DESIGNS");
  // const { id } = useParams();
  const validation = {
    password: { required: true, message: "Please enter password !!!" },
    newPassword: { required: true, message: "Please enter new password !!!" },
  };
  const { values, handleChange, handleSubmit, errors, resetField } = useForm(
    {},
    validation
  );

  const [loaded, setLoaded] = useState(false);

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }
    if (!/(?=.*\d)/.test(password)) {
      errors.push("Password must contain at least one digit.");
    }
    if (!/(?=.*[a-z])/.test(password)) {
      errors.push("Password must contain at least one lowercase letter.");
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(password)) {
      errors.push(
        "Password must contain at least one special character (!@#$%^&*()_+-=[]{};':\"\\|,.<>/?)."
      );
    }

    return errors;
  };

  const passwordSubmit = async (values) => {
    setLoaded(true);
    try {
      if (values.password) {
        const passwordErrors = validatePassword(values.password);
        if (passwordErrors.length > 0) {
          toast.error(passwordErrors.join(" "));
          setLoaded(false);
          return;
        }
      }
      if (values.newPassword) {
        const passwordErrors = validatePassword(values.newPassword);
        if (passwordErrors.length > 0) {
          toast.error(passwordErrors.join(" "));
          setLoaded(false);
          return;
        }
      }
      if (
        values.newPassword &&
        !Validation(
          "confirmPassword",
          values.confirmpassword,
          values.newPassword
        )
      ) {
        toast.error("Confirm password and password doesn`t match");
        setLoaded(false);
        return;
      }
      const res = await changePassword({
        password: values?.password || "",
        newPassword: values?.newPassword || "",
        newPassword_confirmation: values?.confirmpassword || "",
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.info(res?.data?.message);
        resetField({ password: "", newPassword: "", confirmpassword: "" });
      } else {
        toast.info(
          res?.data?.message || res?.data?.errors || "Something went wrong"
        );
        resetField({ password: "", newPassword: "", confirmpassword: "" });
      }
      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <div>
      <div className="text-black text-2xl font-bold pb-2">Change Password</div>
      <form onSubmit={(event) => handleSubmit(event, passwordSubmit)}>
        <div className="py-2">
          <div className="grid grid-cols-12 gap-4">
            <div className="relative xl:col-span-4 md:col-span-6 col-span-12">
              <Input
                isInputGroup={false}
                inputGroupIcon={"fa-solid fa-key"}
                inputGroupPosition={"left"}
                label={"Old Password"}
                inputPlaceholder={"Enter Password"}
                labelClasses={"!text-base pb-1"}
                inputType={"password"}
                value={values.password}
                inputName={"password"}
                inputClasses={"!py-6"}
                onChange={handleChange}
                {...formValidate(errors, "password")}
              />
            </div>
            <div className="relative xl:col-span-4 md:col-span-6 col-span-12">
              <Input
                isInputGroup={false}
                inputGroupIcon={"fa-solid fa-key"}
                inputGroupPosition={"left"}
                label={"New Password"}
                inputPlaceholder={"Enter Password"}
                labelClasses={"!text-base pb-1"}
                inputType={"password"}
                value={values.newPassword}
                inputName={"newPassword"}
                inputClasses={"!py-6"}
                onChange={handleChange}
                {...formValidate(errors, "newPassword")}
              />
            </div>
            <div className="relative xl:col-span-4 md:col-span-6 col-span-12">
              <Input
                isInputGroup={false}
                inputGroupIcon={"fa-solid fa-key"}
                inputGroupPosition={"left"}
                label={"Confirm Password"}
                inputPlaceholder={"Enter Confirm Password"}
                labelClasses={"!text-base pb-1"}
                inputType={"password"}
                inputClasses={"!py-6"}
                value={values.confirmpassword}
                inputName={"confirmpassword"}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mt-8 pt-4 border-t border-slate-200">
            <div className="flex justify-end space-x-3">
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-primary !text-org-silver"}
                buttonLabel={"Update Password"}
                inputClasses={"!py-6"}
                isDisable={loaded}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
