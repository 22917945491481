import React, { useState } from "react";
import CartsSection from "../components/sections/CartsSection";
import DetailsBreadcrumb from "../components/Items/DetailsBreadcrumb";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { deleteCart } from "../services/frontService/ProductService";
import { setCartDetails } from "../redux/slice/cartSlice";

const items = [
  { label: "Home", href: "/" },
  { label: "Cart", href: "/cart" },
];

const Cart = () => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, data: {} });
  const myCartList = useSelector(
    (state) => state?.cart?.cartListData?.cartList
  );
  const myCartSummary = useSelector(
    (state) => state?.cart?.cartListData?.cartSummary
  );
  const myOrderSummary = useSelector(
    (state) => state?.cart?.cartListData?.orderSummary
  );

 

  const deleteCartData = async () => {
    setLoaded(true);
    try {
      const res = await deleteCart({
        id: deleteModal?.data?.id,
      });
      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.info(res?.data?.message);
        dispatch(setCartDetails(res?.data?.data));
        setDeleteModal({ isOpen: false, data: {} });
      } else {
        toast.info(
          res?.data?.message || res?.data?.errors || "Something went wrong"
        );
      }
      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <>
      <div className="relative sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto xl:pt-40 md:pt-32 pt-24 px-5">
        {/* <div className="pb-5">
          <DetailsBreadcrumb items={items} />
        </div> */}
        <div className="text-slate-900 md:text-3xl text-2xl font-medium mb-2 border-b border-slate-500 pb-2 pl-0 md:px-auto  uppercase">
          Cart <span className="text-red-600 font-extrabold">Items</span>
        </div>
        <CartsSection
          myCartList={myCartList}
          myCartSummary={myCartSummary}
          myOrderSummary={myOrderSummary}
          deleteCartData={deleteCartData}
          loaded={loaded}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
        />
      </div>
    </>
  );
};

export default Cart;
