import React, { useCallback, useEffect, useRef, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setTitle } from "../../../helpers/MetaTag";
import { deleteBanner, editPages, pagesDetails } from "../../../services/pagesService";
import { Editor } from "@tinymce/tinymce-react";
import Image from "../../../components/elements/Image";
import Modal from "../../../components/elements/Modal/Modal";
import { AddBannerModal } from "./AddBannerModal";
import DeleteModal from "../../../components/common/DeleteModal";

export const AddEditPage = () => {
  const location = useLocation();
  setTitle("HJL DESIGNS");
  const { id } = useParams();
  const validation = {
    name: { required: true, message: "Please enter your name !!!" },
    sub_title: { required: true, message: "Please enter your sub title !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [description, setDescription] = useState();
  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });
  const editorRef = useRef(null);
  const tinymceApiKey = "uemqg8efft8kgaho6qxmnav0yzbr7s6n00wmyfrh5b7gyxvl";
  const [isOpen, setIsOpen] = useState(false);
  const [bannerDetails, setBannerDetails] = useState({});
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    id: null,
  });
  const [deleteComplete, setDeleteComplete] = useState(false);
  const getDetails = useCallback(() => {
    if (id) {
      setDetailsData({ loading: true, data: {} });
      pagesDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          setFieldsValue({
            name: res?.docs?.name || "",
            sub_title: res?.docs?.sub_title || "",
          });
          setDescription(res?.docs?.description);
          setImageUrl(res?.docs?.imageUrl);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await editPages({
        id: id,
        image_name: imageUrl,
        name: values?.name,
        sub_title: values?.sub_title,
        description: description,
        // status: 1,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        // navigate("/admin/pages-list");
        toast.info(res?.data?.message);
      } else {
        toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  const handelAddOpenModal = () => {
    setIsOpen(true);
  };
  const handelEditOpenModal = (item) => {
    setIsOpen(true);
  };

  const handelDeleteModal = (item) => {
    setDeleteModal({
      isOpen: true,
      id: item?.id,
    });
  };

  const onDelete = async () => {
    if (deleteModal?.id) {
      setDeleteComplete(true);
      try {
        const res = await deleteBanner({ id: deleteModal?.id });
        if (res?.status === 200) {
          toast.success(res?.message);
          setDetailsData((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              bannerList: prevState.data.bannerList.filter((item) => item.id !== deleteModal.id),
            },
          }));
          setDeleteModal({ isOpen: false, id: null });
        } else {
          toast.error(res?.message || "Failed to delete banner.");
        }
      } catch (error) {
        toast.error("An error occurred while deleting the banner.");
      } finally {
        setDeleteComplete(false);
      }
    }
  };

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <PageHeader
          title={"Edit Pages"}
          headerActions={() => (
            <div className="flex items-center space-x-3">
              <Button
                buttonHasLink={true}
                buttonLink={`/admin/pages-list${location.search}`}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonClasses={"!bg-white !text-red-600 !border-red-600 !border-2"}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-red-600 !text-org-silver"}
                buttonLabel={"Save"}
                isDisable={loaded}
              />
            </div>
          )}
        />
        <div className="py-2 md:px-0 px-0">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-5 gap-4 divide-x divide-slate-200 md:px-10 px-0">
              <div className="xl:col-span-4 col-span-5">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={false}
                        label={"Name"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.name}
                        inputName={"name"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Name"}
                        {...formValidate(errors, "name")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={false}
                        label={"Sub Title"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.sub_title}
                        inputName={"sub_title"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Sub Title"}
                        {...formValidate(errors, "sub_title")}
                      />
                    </div>
                    <div className="relative md:col-span-12 col-span-12">
                      <span className="test-base">Description</span>
                      <Editor
                        apiKey={tinymceApiKey}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        value={description}
                        init={{
                          height: 200,
                          menubar: false,
                          plugins: [
                            "code",
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "code | undo redo | formatselect | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help ",
                          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                        onEditorChange={(newText) => setDescription(newText)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-1 col-span-5 pl-4 gap-3">
                <div className="relative">
                  <SingleImageUpload
                    label={"Image"}
                    size={"full"}
                    image={imageUrl}
                    setImage={setImageUrl}
                    accept={["jpg", "png", "jpeg", "webp"]}
                  />
                </div>
              </div>
            </div>
            {/* <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex justify-end space-x-3">
                <Button
                  buttonHasLink={true}
                  buttonLink={"/admin/pages-list"}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={
                    "!bg-white !text-primary !border-primary !border-2"
                  }
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-primary !text-org-silver"}
                  buttonLabel={"Save"}
                  isDisable={loaded}
                />
              </div>
            </div> */}
          </div>
        </div>
      </form>
      <div className="py-2">
        <div className="w-full bg-white rounded-md shadow  py-5 px-4">
          <div className="flex items-center justify-between">
            <span className="lg:text-2xl md:text-lg text-base font-bold text-primary mb-2">Banner Images</span>
            <div className="flex justify-end space-x-3">
              <Button
                buttonHasLink={false}
                buttonIcon={"fa-light fa-plus"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-primary !text-org-silver"}
                buttonLabel={"Add"}
                buttonLabelClasses={"md:block hidden"}
                buttonFunction={handelAddOpenModal}
              />
            </div>
          </div>
          <div className=" h-[500px] overflow-auto mt-12">
            <div className="grid 3xl:grid-cols-7 xl:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-3 divide-x divide-slate-200 md:px-10 px-0">
              {detailsData?.data?.bannerList?.map((item, index) => (
                <div key={index} className="relative !w-full aspect-square overflow-hidden group flex-shrink-0">
                  <Image src={item?.imageUrl} alt="" classes="w-full h-full !object-cover" effect={""} />
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 gap-4">
                    <Button
                      buttonHasLink={false}
                      buttonIcon={"fa-light fa-pen"}
                      buttonIconPosition={"left"}
                      buttonClasses={"!bg-primary !text-org-silver"}
                      buttonFunction={() => {
                        handelEditOpenModal(item);
                        setBannerDetails(item);
                      }}
                    />

                    <Button
                      buttonHasLink={false}
                      buttonIcon={"fa-light fa-trash"}
                      buttonIconPosition={"left"}
                      buttonClasses={"!bg-primary !text-org-silver"}
                      buttonFunction={() => {
                        handelDeleteModal(item);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Modal open={isOpen} title={`Manage Banner`} size="xl5">
        <AddBannerModal
          onClose={() => setIsOpen(false)}
          setLoaded={setLoaded}
          loaded={loaded}
          bannerId={bannerDetails?.id}
          setDetails={setDetailsData}
          getBannerDetails={getDetails}
        />
      </Modal>
      <DeleteModal
        open={deleteModal?.isOpen}
        close={() => setDeleteModal({ isOpen: false, id: null })}
        loading={deleteComplete}
        deleteHandler={onDelete}
      />
    </div>
  );
};
