import React, { useCallback, useEffect, useState } from "react";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { addShippingAddress, getStateList, userDetails } from "../../../services/userService";
import SelectDropdownSearch from "../../../components/form/SelectDropdownSearch";

const ShippingAddress = ({
  loaded,
  setLoaded = () => {},
  imageUrl,
  setImageUrl = () => {},
  date,
  setDate = () => {},
  onSubmit = () => {},
  setType = () => {},
  location,
}) => {
  const { id } = useParams();
  const validation = {
    location: { required: true, message: "Please enter your location !!!" },
    city: { required: true, message: "Please enter city !!!" },
    pin: { required: true, message: "Please enter pin number !!!" },
    state: { required: true, message: "Please enter state !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);

  const navigate = useNavigate();
  const [stateKeyword, setStateKeyword] = useState("");
  const [stateList, setStateList] = useState([]);

  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });

  const onShipSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await addShippingAddress({
        id: detailsData?.data?.userShippingAddressInfo?.id,
        user_id: id,
        location: values?.location,
        city: values?.city || "",
        pin: values?.pin || "",
        state: values?.state?._id,
      });
      if (res?.data?.status === 200 || res?.data?.status === 201) {
        navigate(`/admin/user-list${location.search}`);
        if (id) {
          // setActiveTab((prevTab) => Math.min(prevTab + 1, tabData.length));
        } else {
          navigate(`/admin/user-list${location.search}`);
        }
        toast.info(res?.data?.message);
      } else {
        toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  const getDetails = useCallback(() => {
    if (id) {
      setDetailsData({ loading: true, data: {} });
      userDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          setFieldsValue({
            location: res?.docs?.userShippingAddressInfo?.location || "",
            city: res?.docs?.userShippingAddressInfo?.city || "",
            state:
              {
                name: res?.docs?.userShippingAddressInfo?.stateInfo?.name
                  ? res?.docs?.userShippingAddressInfo?.stateInfo?.name
                  : null,
                _id: res?.docs?.userShippingAddressInfo?.stateInfo?.id
                  ? res?.docs?.userShippingAddressInfo?.stateInfo?.id
                  : null,
              } || "",
            pin: res?.docs?.userShippingAddressInfo?.pin || "",
          });
          setImageUrl(res?.docs?.imageUrl);
          setDate({
            startDate: res?.docs?.dateOfBirth,
            endDate: res?.docs?.dateOfBirth,
          });
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  // loading the State list >>>>>>>>>
  const loadStateList = useCallback(() => {
    getStateList({
      keyword: stateKeyword,
      perPage: 9999999999,
    }).then((res) => {
      if (res && res?.success) {
        setStateList(res?.data);
      }
    });
  }, [stateKeyword]);

  useEffect(() => {
    loadStateList();
  }, [loadStateList]);

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onShipSubmit)}>
        <div className="py-2 md:px-6 px-0">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-5 gap-4 divide-x divide-slate-200 md:px-10 px-0">
              <div className="xl:col-span-5 col-span-5">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        label={"Location"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.location}
                        inputName={"location"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Location"}
                        {...formValidate(errors, "location")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <div className="relative col-span-6">
                        <SelectDropdownSearch
                          label={"Province "}
                          placeholder={"Select a province "}
                          xPlacement={"bottom"}
                          buttonArrowIcon={""}
                          selectName={"state"}
                          isSearch={true}
                          dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                          onChange={handleChange}
                          onSearch={(key) => setStateKeyword(key)}
                          value={values?.state}
                          dropdownData={stateList?.map((item) => ({
                            name: item?.name,
                            _id: item?.id,
                          }))}
                          //   {...formValidate(errors, "state")}
                        />
                      </div>
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        label={"City"}
                        inputPlaceholder={"Enter  City"}
                        labelClasses={"!text-base"}
                        inputType={"city"}
                        value={values.city}
                        inputName={"city"}
                        onChange={handleChange}
                        {...formValidate(errors, "city")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        label={"Postal Code"}
                        inputPlaceholder={"Enter Postal Code"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.pin}
                        inputName={"pin"}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3 justify-end">
                <Button
                  buttonHasLink={true}
                  buttonLink={`/admin/user-list${location.search}`}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={"!bg-white !text-primary !border-primary !border-2"}
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-primary !text-org-silver"}
                  buttonLabel={"Save"}
                  isDisable={loaded}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ShippingAddress;
