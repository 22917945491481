import React, { useEffect, useState } from "react";
import Image from "../elements/Image";
import img from "../../assets/images/cards/card-inside-2.webp";
import ProductAction from "../sections/ProductAction";
import { Link, useNavigate } from "react-router-dom";
import { addWishlistProduct } from "../../services/frontService/ProductService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loginModalOpen } from "../../redux/slice/loginSlice";
import { toast } from "react-toastify";
import Button from "../form/Button";
import { classNames } from "../../helpers/classNames";
import LOGO from "../../assets/images/logo.png";

const SingleProduct = ({ data, lable, actions }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const [loaded, setLoaded] = useState(false);
  const [isSolid, setIsSolid] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  const imageData =
    data &&
    Array.isArray(data?.productImages) &&
    data?.productImages.length > 0 &&
    data?.productImages.find((item) => item.default_status === 1);

  const addWishList = async () => {
    if (isLogin) {
      setLoaded(true);
      try {
        const res = await addWishlistProduct({
          product_id: data?.id,
        });

        if (res?.data?.status === 200 || res?.data?.status === 201) {
          toast.info(res?.data?.message);
          setIsSolid((prev) => !prev);
        } else {
          toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
        }

        setLoaded(false);
      } catch (err) {
        setLoaded(false);
        // console.error(err);
        toast.error("Somthing went wrong !!!");
      }
    } else {
      dispatch(loginModalOpen({ isOpen: true }));
    }
  };

  const handleClick = () => {
    navigate(`/productdetails/${data?.alias}`); // Adjust the route as needed
  };
  useEffect(() => {
    if (isLogin) {
      setIsSolid(data?.is_wishlist === 1 ? true : false);
    } else {
      setIsSolid(false);
    }
  }, [data, isLogin]);

  return (
    <div className="rounded border w-full h-full flex flex-col overflow-hidden hover:shadow-[0_5px_10px_0px_rgb(0,0,0,0.3)] hover:scale-110 transition duration-300">
      <div className="relative 2xl:pl-12 xl:pl-8 xl:pr-16 md:pl-4 md:pr-4 pl-3 pr-3 md:pt-6 pt-3 pb-2">
        <div onClick={handleClick} className="w-full aspect-[1/1.3] overflow-hidden cursor-pointer">
          <Image
            className="w-full h-full object-cover"
            src={imageData?.imageUrl || LOGO}
            alt="Product Image"
            effect={"blur"}
            imageLoading={imageLoading}
            setImageLoading={setImageLoading}
          />
        </div>

        <div className="absolute top-1/2 -translate-y-2/4 right-0 space-y-2 xl:bg-transparent bg-white xl:shadow-none shadow-[0_5px_10px_0px_rgb(0,0,0,0.3)] rounded-lg">
          <div className="xl:pr-3 md:p-2 p-1 mt-0 !pb-0">
            <Button
              buttonClasses={classNames(
                "!p-2 !w-10 !h-10 !justify-center !bg-transparent md:!px-2 !px-1 font-medium !text-black hover:!text-slate-100 hover:!bg-slate-900 !rounded-full md:h-10 h-8 flex items-center justify-center transition-all duration-200 !text-xl",
                isSolid ? "!text-red-600" : ""
              )}
              buttonLabelClasses={""}
              buttonIcon={isSolid ? "fa-solid fa-heart" : "fa-light fa-heart"}
              buttonIconPosition={"left"}
              buttonHasLink={false}
              buttonFunction={addWishList}
            />
          </div>

          <ProductAction data={data} actions={actions} />
        </div>
      </div>

      <div className="xl:px-4 px-1 xl:pb-4 xl:pt-3 pb-2 pt-2 text-center mt-auto">
        <h2 className="inline-block xl:px-0 px-0 py-1 xl:text-lg md:text-base text-sm font-bold  text-gray-700 xl:mr-0 mr-0">
          {data?.title || ""}
        </h2>
      </div>
    </div>
  );
};

export default SingleProduct;
