import React, { useState } from "react";
import Button from "../form/Button";
import Review from "./Review";
import Image from "../elements/Image";
import { country } from "../../helpers";
import DeleteModal from "../common/DeleteModal";
import { addCartQuantity } from "../../services/frontService/ProductService";
import { toast } from "react-toastify";
import { setCartDetails } from "../../redux/slice/cartSlice";
import { useDispatch } from "react-redux";
import HideableInput from "../form/HideableInput";

const SingleCartItems = ({ data, deleteCartData = () => {}, loaded, setDeleteModal = () => {}, deleteModal }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(data?.quantity || 0);
  const [loading, setLoading] = useState(false);

  const handleDecrement = async () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      setLoading(true);
      try {
        const res = await addCartQuantity({
          id: data?.id,
          quantity: quantity - 1,
        });
        if (res?.data?.status === 200 || res?.data?.status === 201) {
          toast.info(res?.data?.message);
          dispatch(setCartDetails(res?.data?.data));
        } else {
          toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        // console.error(err);
        toast.error("Somthing went wrong !!!");
      }
    }
  };

  const handleIncrement = async () => {
    setQuantity(quantity + 1);
    setLoading(true);
    try {
      const res = await addCartQuantity({
        id: data?.id,
        quantity: quantity + 1,
      });
      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.info(res?.data?.message);
        dispatch(setCartDetails(res?.data?.data));
      } else {
        toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  const onSave = async (e) => {
    const value = parseInt(e.value, 10);
    if (!isNaN(value)) {
      setQuantity(value);
    }
    setLoading(true);
    try {
      const res = await addCartQuantity({
        id: data?.id,
        quantity: value,
      });
      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.info(res?.data?.message);
        dispatch(setCartDetails(res?.data?.data));
      } else {
        toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };
  const imageData =
    data &&
    Array.isArray(data?.productInfo?.productImages) &&
    data?.productInfo?.productImages.length > 0 &&
    data?.productInfo?.productImages[0];

  const [isEditing, setIsEditing] = useState(false);

  const handleInputChange = (e) => {
    const value = parseInt(e, 10);
    if (!isNaN(value)) {
      setQuantity(value);
    }
  };

  return (
    <>
      <div className="relative flex xl:gap-10 gap-6 md:py-6 py-4">
        <div className="relative">
          <div className="md:w-32 md:h-32 w-24 h-24 overflow-hidden rounded-md">
            <Image src={imageData?.imageUrl} classes={"w-full h-full object-cover"} alt="product image" />
          </div>
        </div>
        <div className="relative flex  w-full space-Y-10 pr-6">
          <div className="relative w-full">
            <div className="md:text-xl text-base font-bold capitalize text-slate-900 leading-none">
              {data?.productInfo?.title || ""}
            </div>
            {/* <div className="relative md:text-base text-sm text-slate-600 mt-2">
              {data.title}
            </div> */}
            <div>
              <Review
                rating={data?.averageRating || 0}
                totalReviews={data?.totalReview || 0}
                isReviewShow={true}
                classes={"md:flex block"}
              />
            </div>
            <div className="md:text-xl text-lg md:py-2 py-1 text-red-600 font-bold">
              {country + " " + (data?.actual_price.toFixed(2) || "")}
            </div>
            <div className="relative flex items-center gap-4">
              {/* <div className="relative text-xl  font-medium text-slate-900">Product name</div> */}
              <div className="flex items-center md:!space-x-2 space-x-0 my-1">
                <div className="relative">
                  <Button
                    buttonClasses={
                      "!bg-white md:!h-8 h-6  md:w-auto w-6 !px-1  border border-slate-700 !text-black hover:!text-slate-100 hover:!bg-slate-900 flex items-center justify-center transition-all duration-200"
                    }
                    buttonLabelClasses={""}
                    buttonIcon={"fa-light fa-minus"}
                    buttonIconPosition={"left"}
                    buttonHasLink={false}
                    buttonFunction={handleDecrement}
                    isDisable={loading ? loading : quantity > 1 ? false : true}
                  />
                </div>
                {/* <div className="text-base px-3 border-b border-red-600">
                  <div>{quantity}</div>
                </div> */}
                <div className="text-base px-3 border-b border-red-600" onClick={() => setIsEditing(true)}>
                  {isEditing ? (
                    <HideableInput
                      value={quantity}
                      onSave={onSave}
                      type={"transparent"}
                      rename={isEditing}
                      setRename={setIsEditing}
                      inputClasses={"!text-base !bg-transparent !border !border-transparent !w-16 !text-org-theme"}
                    />
                  ) : (
                    <div>{quantity}</div>
                  )}
                </div>
                <div>
                  <Button
                    buttonClasses={
                      "!bg-white md:!h-8 h-6  md:w-auto w-6 !px-1  border border-slate-700 !text-black hover:!text-slate-100 hover:!bg-slate-900 flex items-center justify-center transition-all duration-200"
                    }
                    buttonLabelClasses={""}
                    buttonIcon={"fa-light fa-plus"}
                    buttonIconPosition={"left"}
                    buttonHasLink={false}
                    buttonFunction={handleIncrement}
                    isDisable={loading}
                  />
                </div>
              </div>
              <div className="w-full flex justify-end">
                <div className="text-center">
                  {/* <div className="flex space-x-2 items-center text-black md:!text-base text-xs !font-bold uppercase md:py-0 py-1">
            <div>Cad 20</div>
            <div className="text-xs font-semibold">X</div>
            <div>3</div>

          </div> */}
                  <div className="text-black md:text-lg text-sm font-bold uppercase">
                    {country + " " + (data?.total_price.toFixed(2) || "")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute md:top-0 right-4 top-12">
            <div className=" text-slate-700 flex justify-end">
              <Button
                buttonClasses={
                  "!bg-white !p-1 !text-red-600 !text-2xl hover:!text-slate-900 h-8 flex items-center justify-center transition-all duration-200 !rounded-full"
                }
                buttonLabelClasses={""}
                buttonIcon={"fa-regular fa-trash"}
                buttonIconPosition={"left"}
                buttonHasLink={false}
                buttonFunction={() => setDeleteModal({ isOpen: true, data: data })}
              />
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        open={deleteModal?.isOpen}
        close={() => setDeleteModal({ isOpen: false })}
        loading={loaded}
        deleteHandler={deleteCartData}
      />
    </>
  );
};

export default SingleCartItems;
