import React from "react";
import Image from "../elements/Image";

const MainBannerItems = ({ data }) => {
  return (
    <>
      <div className="!w-full relative h-screen max-h-[240px] md:max-h-[440px] lg:max-h-full">
        <Image src={data?.imageUrl} effect={"blur"} className={"object-cover"} />
        {/* <img alt="" className="object-cover w-full h-full" crossOrigin="anonymous" /> */}
      </div>
    </>
  );
};

export default MainBannerItems;
