import React, { useState } from "react";
import Button from "../form/Button";
import Review from "./Review";
import Image from "../elements/Image";
import Modal from "../elements/Modal/Modal";
import LeaveFeedback from "../sections/LeaveFeedback";
import image1 from "../../assets/images/cards/card-inside-4.webp";
import { country } from "../../helpers";
import { Link } from "react-router-dom";

const SingleViewDetailsProductList = ({ data, details }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="relative flex lg:gap-10 gap-8 md:py-6 py-4">
        <div className="relative">
          <div className="md:w-32 md:h-32 w-24 h-24 overflow-hidden rounded-md">
            <Image src={data?.productImageUrl || image1} classes={"w-full h-full object-cover"} alt="product image" />
          </div>
        </div>
        <div className="relative flex  w-full space-Y-10">
          <div className="relative w-full">
            <Link to={`/productdetails/${data?.product_alias}`}>
              <div className="md:text-xl text-base font-bold capitalize text-slate-900 leading-none">
                {data?.product_name}
              </div>
            </Link>

            <div className="relative md:text-base text-sm text-slate-600 mt-2">{data.title}</div>

            <div>
              <Review
                rating={data?.averageRating}
                totalReviews={data?.totalReview}
                isReviewShow={true}
                classes={"md:flex block"}
              />
            </div>
            <div className="flex space-x-2 py-1 items-center">
              <div className="md:text-base text-sm">Quantity :</div>
              <div>{data?.quantity}</div>
            </div>
            <div className="flex space-x-2 py-1 items-center">
              <div className="md:text-base text-sm">No Of Package :</div>
              <div>{data?.package_qty}</div>
            </div>
            <div className="md:text-xl text-base md:py-2 py-1 text-black font-bold absolute md:top-0 top-6 right-0">
              {data?.price}
            </div>
            <div className="relative flex items-center gap-4">
              <div className="w-full flex sm:justify-end justify-start">
                <div className="text-center">
                  <div className="flex space-x-2 items-center text-black md:!text-base text-xs !font-bold uppercase md:py-0 py-1">
                    <div> {country + " " + (data?.actual_price.toFixed(2) || 0.0)}</div>
                  </div>
                  {details?.status === 4 && data?.reviewExists === 0 && (
                    <div className="md:mt-auto mt-2">
                      <Button
                        buttonClasses={
                          "md:!px-4 !px-3 !py-1 md:!h-10 !h-7 !h-8 !text-white hover:!text-white !text-xl hover:!bg-black hover:!border-black !text-center bg-red-600 gap-3  transition duration-200 rounded-none leading-none !rounded-md"
                        }
                        buttonLabel="Leave Feedback"
                        buttonLabelClasses="md:!text-lg !text-sm"
                        buttonEffect={"filled"}
                        buttonFunction={() => setIsOpen(true)}
                      />
                      <Modal size="xl" modalTitle="" open={isOpen}>
                        <LeaveFeedback onClose={() => setIsOpen(false)} data={data} />
                      </Modal>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleViewDetailsProductList;
