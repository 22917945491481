import React, { useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";
import Image from "../elements/Image";
import { toast } from "react-toastify";


const MultipleImageUploader = ({
  size = "sm",
  accept = [],
  label,
  labelClasses,
  images = [],
  setImages = () => {},
}) => {
  const [inputImages, setInputImages] = useState(images);


  useEffect(() => {
    if (inputImages.length > 0 && images.length !== inputImages.length) {
      setImages(inputImages);
    }
  }, [inputImages, setImages, images]);
  
  const handleInputChange = (event) => {
    const input = event.target;
    if (accept && Array.isArray(accept) && accept.length > 0) {
      const newImages = Array.from(input.files).filter((file) =>
        accept.includes(file.type.split("/")[1])
      );
      if (newImages.length > 0) {
        setInputImages((prevInputImages) => [...prevInputImages, ...newImages]);
      } else {
        toast.info(`Images only accept ${accept.join(", ")}`);
      }
    } else {
      setInputImages((prevInputImages) => [...prevInputImages, ...Array.from(input.files)]);
    }
  };
  
  const handleRemoveChange = (index) => {
    const newImages = [...inputImages];
    newImages.splice(index, 1);
    setInputImages(newImages);
    if (newImages.length <= 0) {
      setImages([]);
    }
  };
  // const handleRemoveChange = (index) => {
  //   // console.log(inputImages, "inputImagesinputImagesinputImages");
  //   const newImages = inputImages.filter((_, i) => i !== index);
  //   // console.log(newImages, "newImagesnewImages");
  //   setInputImages(newImages);
  // };

  const boxSize = {
    sm: "w-16",
    md: "w-32",
    lg: "w-60",
    full:"w-full"
  };
  const closeSize = {
    sm: "w-5 text-sm",
    md: "w-6 text-base",
    lg: "w-8 text-lg",
  };
  const uploaderIconSize = {
    sm: "text-xl",
    md: "text-2xl",
    lg: "text-3xl",
  };
  const uploaderTextSize = {
    sm: "text-[10px]",
    md: "text-xs",
    lg: "text-sm",
  };

  return (
    <>
      <div className="relative space-y-1 w-full">
        {label && (
          <div
            className={classNames(
              "text-sm font-semibold text-opplio-gray tracking-none",
              labelClasses
            )}
          >
            {label}
          </div>
        )}
        <div className="flex flex-wrap gap-4 pt-2">
        <div className="relative w-full">
            <div
              className={classNames(
                "relative overflow-hidden border-2 border-dashed border-slate-200 text-slate-500 bg-slate-50 rounded-md flex items-center justify-center flex-col gap-1 leading-none !w-full aspect-[2/0.5]",
                boxSize[size],
                uploaderIconSize[size]
              )}
            >
              <input
                type="file"
                className="absolute top-0 right-0 opacity-0 w-full h-full z-[1] cursor-pointer"
                multiple
                onChange={handleInputChange}
              />
              <div className="text-2xl text-red-600"><i class="fa-duotone fa-solid fa-camera"></i></div>
              <div
                className={classNames(
                  "font-semibold uppercase tracking-wider",
                  uploaderTextSize[size]
                )}
              >
                {size === "sm" ? "Images" : "Upload Images"}
              </div>
            </div>
          </div>
          <div className="min-h-30 max-h-80 w-full overflow-auto">
          <div className="flex flex-wrap gap-6 pt-2">
          {inputImages.map((image, index) => (
            <div key={index} className="relative">
              <div
                className={classNames(
                  "relative aspect-square overflow-hidden border border-slate-200 bg-white rounded-md !w-44",
                  boxSize[size]
                )}
              >
                <Image
                  src={URL.createObjectURL(image)}
                  loader={() => URL.createObjectURL(image)}
                  fill={true}
                  alt=""
                  sizes={"(max-width: 768px) 64px"}
                  classes="object-cover"
                />
              </div>
              <button
                type="button"
                className={classNames(
                  "aspect-square rounded-full bg-white shadow absolute -top-2.5 -right-2.5 text-red-500 ",
                  closeSize[size]
                )}
                onClick={() => handleRemoveChange(index)}
              >
                <i className="fa-regular fa-fw fa-xmark"></i>
              </button>
            </div>
          ))}
          </div>
          </div>
       
        </div>
      </div>
    </>
  );
};

export default MultipleImageUploader;
