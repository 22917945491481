import React, { useState } from "react";
import Button from "../../form/Button";
import { classNames } from "../../../helpers/classNames";

const MediaSidePanel = ({
  isExpanded,
  setIsExpanded = () => {},
  toggleSidebar = () => {},
  selectedMenu,
  setSelectedMenu = () => {},
  setSelectedFolderId = () => {},
  loadList = () => {},
  setOpen = () => {},
  setCurrentPage = () => {},
}) => {
  const handelChange = (item) => {
    setSelectedMenu(item);
    setSelectedFolderId(0);
    setCurrentPage(1);
    setOpen(false);
    if (item === "all") {
      loadList();
    }
  };
  return (
    <>
      <div
        className={`transition-all duration-300 ${
          isExpanded
            ? "xl:w-56 w-56 md:pt-0 pt-3"
            : "md:w-16 w-0 md:!mt-0 !mt-2"
        } !bg-white shadow-lg`}
      >
        <Button
          buttonClasses={
            "md:!hidden block !text-lg !bg-red-600 !text-white md:!px-2 !px-1 md:!h-10 !h-8 md:!ms-5 !ms-7"
          }
          buttonIcon={
            isExpanded ? "fa-solid fa-arrow-left" : "fa-solid fa-arrow-right"
          }
          buttonIconPosition={"left"}
          buttonHasLink={false}
          buttonFunction={toggleSidebar}
        />
      </div>
      <div
        className={`transition-all duration-300 ${
          isExpanded
            ? "xl:w-56 w-56 !opacity-100"
            : "md:w-16 w-0 md:opacity-100 opacity-0"
        } !bg-white shadow-lg h-full`}
      >
        <div
          className={classNames(
            "flex ",
            isExpanded ? "justify-end me-3" : "justify-center"
          )}
        >
          <Button
            buttonClasses={
              "lg:!hidden md:!block !hidden !text-lg !bg-red-600 !text-white px-2 !h-10 !mt-2"
            }
            buttonIcon={
              isExpanded ? "fa-solid fa-arrow-left" : "fa-solid fa-arrow-right"
            }
            buttonIconPosition={"left"}
            buttonHasLink={false}
            buttonFunction={toggleSidebar}
          />
        </div>
        <div className={"xl:pt-5 pt-4"}>
          <div
            className={classNames(
              "text-white font-bold bg-gray-800 flex gap-2 py-2 uppercase",
              isExpanded ? "text-lg pl-5" : "text-sm pl-2"
            )}
          >
            <p className={classNames(isExpanded ? "block" : "hidden")}>
              <i class="fa-sharp fa-regular fa-photo-film-music"></i>
            </p>
            Media
          </div>
          <div className={classNames(" pt-2 ", isExpanded ? "pl-0" : "pl-0")}>
            <div
              className={`p-2 flex gap-3 items-center  hover:bg-slate-100 ${
                selectedMenu === "all" ? "bg-red-100" : "bg-transparent"
              }`}
            >
              <Button
                buttonClasses={"!text-xl !text-red-600 !bg-transparent !h-8 "}
                buttonLabel={"All Media"}
                buttonLabelClasses={
                  isExpanded ? "!text-sm !font-bold !text-black" : "hidden"
                }
                buttonIcon={"fa-solid fa-photo-film"}
                buttonIconPosition={"left"}
                buttonHasLink={false}
                buttonFunction={() => handelChange("all")}
              />
            </div>
            {/* <div className="p-2 flex gap-3 items-center">
              <Button
                buttonClasses={"!text-2xl !text-red-600 !bg-transparent !h-8"}
                buttonLabel={"Image"}
                buttonLabelClasses={
                  isExpanded ? "!text-lg !font-bold !text-black" : "hidden"
                }
                buttonIcon={"fa-regular fa-image"}
                buttonIconPosition={"left"}
              />
            </div>
            <div className="p-2 flex gap-3 items-center">
              <Button
                buttonClasses={"!text-2xl !text-red-600 !bg-transparent !h-8"}
                buttonLabel={"Video"}
                buttonLabelClasses={
                  isExpanded ? "!text-lg !font-bold !text-black" : "hidden"
                }
                buttonIcon={"fa-solid fa-video"}
                buttonIconPosition={"left"}
              />
            </div>
            <div className="p-2 flex gap-3 items-center">
              <Button
                buttonClasses={"!text-2xl !text-red-600 !bg-transparent !h-8"}
                buttonLabel={"Documents"}
                buttonLabelClasses={
                  isExpanded ? "!text-lg !font-bold !text-black" : "hidden"
                }
                buttonIcon={"fa-solid fa-file"}
                buttonIconPosition={"left"}
              />
            </div> */}
            <div
              className={`p-2 flex gap-3 items-center  hover:bg-slate-100 ${
                selectedMenu === "trash" ? "bg-red-200" : "bg-transparent"
              }`}
            >
              <Button
                buttonClasses={"!text-xl !text-red-600 !bg-transparent !h-8"}
                buttonLabel={"Trash"}
                buttonLabelClasses={
                  isExpanded ? "!text-sm !font-bold !text-black" : "hidden"
                }
                buttonIcon={"fa-solid fa-trash"}
                buttonIconPosition={"left"}
                buttonHasLink={false}
                buttonFunction={() => handelChange("trash")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MediaSidePanel;
