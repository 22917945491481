import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { setTitle } from "../../../helpers/MetaTag";
import SelectDropdownSearch from "../../../components/form/SelectDropdownSearch";
import { getProductList, productDetails } from "../../../services/productService";
import SubmitRating from "../../../components/sections/SubmitRating";
import { getUserList } from "../../../services/userService";
import Textarea from "../../../components/form/Textarea";
import { addReview, reviewDetails } from "../../../services/reviewService";
import TextAreaAutoSize from "../../../components/form/TextareaAutoSize";

export const AddEditProductReview = ({ onClose = () => {}, productDetails, loadList = () => {} }) => {
  setTitle("HJL DESIGNS");
  const { id } = useParams();
  const validation = {
    user_name: { required: true, message: "Please enter your user name !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });
  const [statusKeyword, setStatusKeyword] = useState("");
  const [rating, setRating] = useState();
  const [productList, setProductList] = useState({ data: [] });
  const [productKeyword, setProductKeyword] = useState("");
  const [review, setReview] = useState();

  const getDetails = useCallback(() => {
    if (id) {
      setDetailsData({ loading: true, data: {} });
      reviewDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          setFieldsValue({
            user_name: res?.docs?.user_name,
            product_id:
              {
                name: res?.docs?.productInfo?.title ? res?.docs?.productInfo?.title : null,
                _id: res?.docs?.productInfo?.id ? res?.docs?.productInfo?.id : null,
              } || "",
            status:
              {
                name: res?.docs?.is_approved ? res?.docs?.is_approved : null,
                _id: res?.docs?.is_approved ? res?.docs?.is_approved : null,
              } || "",
          });
          setRating(res?.docs?.rating);
          setReview(res?.docs?.review);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      // if (!id) {
      const res = await addReview({
        product_id: values?.product_id?._id,
        rating: rating,
        review: review,
        is_approved: values?.status?._id,
        user_name: values?.user_name,
        review_by: 2,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        onClose(true);
        loadList();
        toast.info(res?.data?.message);
      } else {
        toast.info(res?.data?.message || res?.data?.errors?.category_id[0] || "Something went wrong");
      }
      // } else {
      //   const res = await addReview({
      //     id: id,
      //     product_id: values?.product_id?._id,
      //     rating: rating,
      //     review: review,
      //     is_approved: values?.status?._id,
      //     user_name: values?.user_name,
      //     review_by: 2,
      //   });

      //   if (res?.data?.status === 200 || res?.data?.status === 201) {
      //     onClose(true);
      //     loadList();
      //     toast.info(res?.data?.message);
      //   } else {
      //     toast.info(res?.data?.message || res?.data?.errors?.category_id[0] || "Something went wrong");
      //   }
      // }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      toast.error("Somthing went wrong !!!");
    }
  };

  const data = [
    { name: "Approved", _id: 1 },
    { name: "Not Approved", _id: 2 },
  ];

  useEffect(() => {
    getProductList({ perPage: 999 }).then((res) => {
      if (res && res?.docs?.status === 200) {
        setProductList({
          data: res?.docs?.data,
        });
      }
    });
  }, []);

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <PageHeader
          title={`Give A Review On ${productDetails?.title}`}
          // headerActions={() => (
          //   <div className="flex items-center space-x-3">
          //     <Button
          //       buttonHasLink={true}
          //       buttonLink={"/admin/review-list"}
          //       buttonType={"button"}
          //       buttonIcon={"fa-regular fa-xmark"}
          //       buttonIconPosition={"left"}
          //       buttonLabel={"Cancel"}
          //       buttonLabelClasses={"md:block hidden"}
          //       buttonClasses={"!bg-white !text-red-600 !border-red-600 !border-2"}
          //     />
          //     <Button
          //       buttonHasLink={false}
          //       buttonType={"submit"}
          //       buttonIcon={"fa-light fa-floppy-disk"}
          //       buttonIconPosition={"left"}
          //       buttonClasses={"!bg-red-600 !text-org-silver"}
          //       buttonLabel={"Save"}
          //       buttonLabelClasses={"md:block hidden"}
          //       isDisable={loaded}
          //     />
          //   </div>
          // )}
        />
        <div className="py-2 md:px-6 px-0">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-5 gap-4 divide-x divide-slate-200 md:px-10 px-0">
              <div className="xl:col-span-6 col-span-12">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative md:col-span-6 col-span-12">
                      <SelectDropdownSearch
                        label={"Product List"}
                        labelClasses={"!text-base"}
                        placeholder={"Select Product Name  "}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"product_id"}
                        isSearch={true}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={handleChange}
                        onSearch={(key) => setProductKeyword(key)}
                        value={values?.product_id}
                        dropdownData={
                          productList &&
                          productList?.data?.map((item) => ({
                            name: item?.title,
                            _id: item?.id,
                          }))
                        }
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={false}
                        inputGroupIcon={"fa-regular fa-cube"}
                        inputGroupPosition={"left"}
                        label={"User Name"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.user_name}
                        inputName={"user_name"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter User Name"}
                        {...formValidate(errors, "user_name")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <SelectDropdownSearch
                        label={"Status  "}
                        labelClasses={"!text-base"}
                        placeholder={"Select Status"}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"status"}
                        isSearch={false}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={handleChange}
                        onSearch={(key) => setStatusKeyword(key)}
                        value={values?.status}
                        dropdownData={data}
                      />
                    </div>
                    <div className="relative md:col-span-4 col-span-12">
                      <span className="block mb-2 text-slate-500">Give Rating</span>
                      <SubmitRating rating={rating} setRating={setRating} />
                    </div>
                    <div className="relative md:col-span-12 col-span-12">
                      <TextAreaAutoSize
                        label={"Review"}
                        labelClasses={"!text-base"}
                        inputValue={review}
                        inputName={"review"}
                        onChange={(e) => setReview(e.target.value)}
                        inputPlaceholder={"Enter Review"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center justify-end space-x-3">
                <Button
                  buttonHasLink={false}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={"!bg-white !text-primary !border-primary !border-2"}
                  buttonFunction={onClose}
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-primary !text-org-silver"}
                  buttonLabel={"Save"}
                  isDisable={loaded}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
