import React, { useState, useEffect, useRef, useCallback } from "react";
import Logo from "../assets/images/logo(white).png";
import { Link, NavLink } from "react-router-dom";
import Image from "../../src/components/elements/Image";
import Button from "../components/form/Button";
import Dropdown from "../components/form/Dropdown";
import { classNames } from "../helpers/classNames";
import { useLocation, useParams } from "react-router";
import CartHover from "../components/sections/CartHover";
import SearchBar from "../components/sections/SearchBar";
import { useNavigate } from "react-router-dom";
import UserDropdown from "./UserDropdown";
import { useSelector } from "react-redux";
import { loginModalOpen } from "../redux/slice/loginSlice";
import { useDispatch } from "react-redux";
import LogoutModal from "../components/elements/logoutModal/LogoutModal";

const DefaultHeader = ({ data, headerClass }) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const searchBarRef = useRef(null);
  const location = useLocation();
  let token = localStorage.getItem("auth_token");
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const profile = useSelector((state) => state.profile.profileData);
  const myCartData = useSelector((state) => state?.cart?.cartListData);
  const settings = useSelector((state) => state?.settings?.data);
  const [details, setDetails] = useState({ loading: true, data: {} });

  const handleScroll = () => {
    if (menuToggle) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  };

  const [isListOpen, setIsListOpen] = useState(false);

  const toggleList = () => {
    setIsListOpen(!isListOpen);
  };

  useEffect(() => {
    function handleScroll() {
      const isScrolled = window.scrollY > 20;
      setScrolled(isScrolled);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    handleScroll();
  }, [menuToggle]);

  const toggleSearchBar = () => {
    setSearchVisible(!searchVisible);
  };

  const handleClickOutside = (event) => {
    if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
      setSearchVisible(false);
    }
  };

  useEffect(() => {
    if (searchVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchVisible]);

  const routeChange = (slug, link) => {
    navigate(`${link}/${slug}`);
  };
  const dropdownMenuFront = [
    {
      _id: 1,
      label: "My Profile",
      link: "/user-profile",
      icon: "fa-solid fa-user",
    },
    {
      _id: 2,
      label: "Log Out",
      icon: "fa-regular fa-fw fa-arrow-right-from-arc",
      onclick: (val) => {
        setOpen(true);
      },
    },
  ];
  const orderSummary = myCartData ? myCartData?.orderSummary : {};
  // console.log(orderSummary, "myCartDatamyCartDatamyCartData");

  return (
    <>
      <header
        className={classNames(
          "fixed top-0 left-0 w-full z-10 bg-black/70  flex items-center transition-all duration-200",
          scrolled ? "h-14 md:h-20 xl:h-24 !bg-black shadow-md" : "xl:h-32 md:h-24 h-14",
          headerClass,
          location.pathname === "/cart" ? "!bg-black" : "",
          location.pathname === "/productdetails" ? "!bg-black" : "",
          location.pathname === "/profile" ? "!bg-black" : "",
          location.pathname === "/checkout" ? "!bg-black" : "",
          location.pathname === "/favouritesall" ? "!bg-black" : "",
          location.pathname === "/paymentinformation" ? "!bg-black" : "",
          location.pathname === "/vieworderdetails" ? "!bg-black" : "",
          location.pathname === "/contactus" ? "!bg-black" : "",
          location.pathname === "/aboutus" ? "!bg-black" : "",
          location.pathname === "/testimonial" ? "!bg-black" : "",
          location.pathname === "/cards" ? "!bg-black" : "",
          location.pathname === "/faq" ? "!bg-black" : "",
          location.pathname === "/user-profile" ? "!bg-black" : "",
          location.pathname === "/user-orders" ? "!bg-black" : "",
          location.pathname === "/user-wishlists" ? "!bg-black" : "",
          location.pathname === "/user-address" ? "!bg-black" : "",
          location.pathname === `/productdetails/${slug}` ? "!bg-black" : "",
          location.pathname === `/order-details/${slug}` ? "!bg-black" : ""
        )}>
        <div className="relative w-full sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center">
          <div className="2xl:w-1/6 w-1/12">
            <div className="xl:!w-20 md:w-[4.5rem] w-14 xl:pl-0 pl-5">
              <Link to={"/"}>
                <img
                  src={settings?.logoUrl || Logo}
                  alt={"Logo"}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-contain object-center"}
                  onError={(e) => {
                    e.target.src = Logo;
                  }}
                />
              </Link>
            </div>
          </div>
          <div
            className={classNames(
              "xl:p-auto p-5 block xl:flex xl:relative fixed top-0 right-0 z-10 items-center xl:bg-transparent bg-black space-y-4 xl:space-x-3 xl:space-y-0 2xl:w-3/5 xl:w-4/5 md:w-2/5 w-3/4 text-lg 2xl:justify-start justify-center xl:h-auto h-full xl:!translate-x-0 transition:all duration-300",
              menuToggle ? "translate-x-0" : "translate-x-full"
            )}>
            <div className="xl:hidden block w-5">
              <Button
                buttonClasses={"!px-5 !h-10 !text-3xl"}
                buttonHasLink={false}
                buttonIcon={"fa-regular fa-bars"}
                buttonIconPosition={"left"}
                buttonEffect={"filled"}
                // buttonFunction={() => setMenuToggle(!menuToggle)}
              />
            </div>
            {data?.menu?.length > 0 && (
              <div className="xl:p-auto p-2 block xl:flex xl:relative fixed top-0 right-0 z-10 items-center xl:bg-transparent bg-black space-y-4 4xl:space-x-3 8xl:space-x-1 xl:space-y-0  text-lg 2xl:justify-start justify-center xl:h-auto h-full w-full">
                {data?.menu?.map((item) => (
                  <div className="relative " key={item._id}>
                    {item.isParent ? (
                      <Dropdown
                        data={item.subdata}
                        buttonClass={
                          // "xl:w-auto !w-4/5  !border-none !w-full xl:!w-auto !text-white hover:!text-slate-700 bg-transparent hover:bg-white translate-y-0 xl:translate-y-auto w-[calc(100%-16px)]"
                          " relative text-lg xl:w-auto !border-none w-4/5 block font-medium text-white after:block after:content-[''] after:absolute after:h-[1px] after:bg-white after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center after:bottom-1"
                        }
                        buttonLabelClass={"font-medium "}
                        handleSelect={(data) => {
                          routeChange(data?.slug, item?.link);
                          setSearchVisible(false);
                          setMenuToggle(false);
                        }}
                        buttonIcon={true}
                        buttonLabel={item.name}
                      />
                    ) : (
                      <NavLink
                        to={item.link}
                        className={({ isActive }) =>
                          // "xl:w-auto w-4/5 px-3 font-medium text-white hover:text-red-600 hover:scale-110 hover:font-extrabold bg-transparent rounded-md h-10 flex items-center justify-start transition-all duration-200"
                          " !scale-100 relative text-lg xl:w-auto w-4/5 mx-3 block font-medium text-white after:block after:content-[''] after:absolute after:h-[1px] after:bg-white after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center" +
                          (isActive ? "text-carnation-500 " : "text-slate-800 ")
                        }
                        onClick={() => {
                          setSearchVisible(false);
                          setMenuToggle(false);
                        }}>
                        {item.name}
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="flex 3xl:w-2/12 xl:w-2/12 w-full justify-end items-center">
            {/* <Button
              buttonClasses={
                "!bg-transparent !md:px-5 px-1 !h-10 md:!text-3xl text-lg"
              }
              buttonHasLink={false}
              buttonIcon={"fa-light fa-magnifying-glass"}
              buttonIconPosition={"left"}
              buttonEffect={"filled"}
              buttonFunction={toggleSearchBar}
            /> */}
            {isLogin && (
              <div
                className="relative"
                onMouseEnter={() => setIsListOpen(true)}
                onMouseLeave={() => setIsListOpen(false)}>
                <div className="text-white">
                  <Button
                    buttonClasses={"!md:px-5 px-1 !h-10 md:!text-3xl text-lg"}
                    buttonHasLink={true}
                    buttonIcon={"fa-light fa-bag-shopping"}
                    buttonIconPosition={"left"}
                    buttonEffect={"filled"}
                    buttonLink={"/cart"}
                  />
                </div>
                <div className="bg-white absolute right-2 xl:right-2 top-0 xl:-top-1 rounded-full w-4 xl:w-5 h-4 xl:h-5 text-[8px] xl:text-[10px] flex items-center justify-center text-black">
                  {myCartData?.cartSummary?.totalCartItem > 99 ? "99+" : myCartData?.cartSummary?.totalCartItem || 0}
                </div>
                {myCartData?.cartList?.length > 0 && isListOpen && (
                  <div className="absolute md:right-0 -right-12 top-full md:w-[400px] w-[300px]">
                    <CartHover myCartData={myCartData} orderSummary={orderSummary} />
                  </div>
                )}
              </div>
            )}

            <div className="relative">
              {profile && profile?.role === "USER" && profile?.customer_id && isLogin ? (
                <div className="relative  px-2 py-3">
                  <UserDropdown xPlacement={"right"} data={dropdownMenuFront} userData={profile} />
                </div>
              ) : (
                <div
                  className="flex text-white cursor-pointer"
                  onClick={() => {
                    dispatch(loginModalOpen({ isOpen: true }));
                  }}>
                  <Button
                    buttonClasses={"!md:px-5 px-1 !h-10 md:!text-3xl text-lg"}
                    buttonHasLink={true}
                    buttonIcon={"fa-sharp fa-regular fa-arrow-right-to-arc"}
                    buttonIconPosition={"left"}
                    buttonEffect={"filled"}
                  />
                  <p className="!h-10 md:!text-xl text-lg mt-1">Login</p>
                </div>
              )}
            </div>
            <div>
              <div className="text-white xl:hidden">
                <Button
                  buttonClasses={"!bg-transparent !md:px-5 px-1 !h-10 md:!text-3xl text-lg"}
                  buttonHasLink={false}
                  buttonIcon={"fa-regular fa-bars"}
                  buttonIconPosition={"left"}
                  buttonEffect={"filled"}
                  buttonFunction={() => setMenuToggle(!menuToggle)}
                />
              </div>
            </div>
          </div>
          <div
            className={classNames(
              "fixed z-[9] w-full h-full top-0 left-0 bg-black opacity-45 transition-all duration-300",
              menuToggle ? "opacity-100 visible" : "opacity-0 invisible"
            )}
            onClick={() => setMenuToggle(!menuToggle)}></div>
        </div>
      </header>
      <div
        className={classNames(
          "fixed left-0 w-full z-[9] bg-black/70  flex items-center transition-all duration-200",
          headerClass,
          location.pathname === "/cart" ? "!bg-black" : "",
          location.pathname === "/productdetails" ? "!bg-black" : "",
          location.pathname === "/profile" ? "!bg-black" : "",
          location.pathname === "/checkout" ? "!bg-black" : "",
          location.pathname === "/favouritesall" ? "!bg-black" : "",
          location.pathname === "/paymentinformation" ? "!bg-black" : "",
          location.pathname === "/vieworderdetails" ? "!bg-black" : "",
          location.pathname === "/contactus" ? "!bg-black" : "",
          location.pathname === "/aboutus" ? "!bg-black" : "",
          location.pathname === "/testimonial" ? "!bg-black" : "",
          location.pathname === "/cards" ? "!bg-black" : "",

          scrolled ? "top-14 md:top-20 xl:top-24 !bg-black shadow-md " : "xl:top-32 md:top-24 top-14"
          // searchVisible
          //   ? "translate-y-0 opacity-100 visible"
          //   : "-translate-y-24 opacity-0 invisible"
        )}>
        {searchVisible && (
          <div className="w-full sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto md:px-0 px-5">
            <div className="py-4">
              <SearchBar />
            </div>
          </div>
        )}
      </div>
      <LogoutModal isOpen={isOpen} setOpen={setOpen} />
    </>
  );
};

export default DefaultHeader;
