import React, { useState } from "react";
import Input from "../../form/Input";
import Button from "../../form/Button";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import { addNewFolder } from "../../../services/mediaService";

const FolderAdd = ({
  setFolderModalOpen = () => {},
  selectedFolderId,
  setSelectedFolderId = () => {},
  loadList = () => {},
  setCurrentPage = () => {},
}) => {
  const validation = {
    name: { required: true, message: "Please enter folder name !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  const [loaded, setLoaded] = useState(false);

  //   console.log(selectedFolderId, "selectedFolderId");

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await addNewFolder({
        folderId: selectedFolderId,
        folderName: values?.name,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.success(res?.data?.message);
        setSelectedFolderId(selectedFolderId);
        setFolderModalOpen(false);
        loadList();
        setCurrentPage(1);
      } else {
        toast.info(
          res?.data?.message || res?.data?.errors || "Something went wrong"
        );
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };
  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="!pt-2 md:px-3 px-0">
          <div className="w-full">
            <div className="grid grid-cols-5 gap-4 divide-x divide-slate-200">
              <div className="xl:col-span-6 col-span-6">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative md:col-span-12 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-list"}
                        inputGroupPosition={"left"}
                        label={"Folder Name"}
                        labelClasses={"!text-base !pb-2"}
                        inputType={"text"}
                        value={values.name}
                        inputName={"name"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Folder Name"}
                        {...formValidate(errors, "name")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-2 justify-end pb-2">
                <Button
                  buttonHasLink={false}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={
                    "!bg-white !text-primary !border-primary !border-2"
                  }
                  buttonFunction={() => setFolderModalOpen(false)}
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-primary !text-org-silver"}
                  buttonLabel={"Save"}
                  isDisable={loaded}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FolderAdd;
