import React from "react";
import MainBanner from "../components/sections/MainBanner";
import AboutUsSection from "../components/sections/AboutUsSection";
import SmallBanner from "../components/sections/SmallBanner";

const AboutUs = () => {
  const slug = "about-us";
  return (
    <>
      <SmallBanner slug={slug} />
      <AboutUsSection />
    </>
  );
};

export default AboutUs;
