import React, { useCallback, useEffect, useState } from "react";
import Button from "../../../components/form/Button";
import { changeOrderStatus } from "../../../services/orderService";
import useForm from "../../../hooks/useForm";
import TextAreaAutoSize from "../../../components/form/TextareaAutoSize";
import SelectDropdownSearch from "../../../components/form/SelectDropdownSearch";
import { toast } from "react-toastify";

const OrderStatusChange = ({
  onClose = () => {},
  statusDetails,
  setStatusDetails = () => {},
  list,
  setList = () => {},
  loadList = () => {},
}) => {
  const validation = {
    // message: { required: true, message: "Please enter your message !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const [message, setMessage] = useState({
    Processing: "Your Order has been received.",
    Accepted: "We are verifying your Order",
    Shipped: "Your Order has Delivered to you",
    "Out for Delivery": "Your Order is out for Delivery",
    Cancel: "Your order has been canceled",
  });

  const onSubmit = async (values) => {
    changeOrderStatus({
      id: statusDetails?.id,
      status: values?.status?._id,
    }).then((res) => {
      if (res?.status === 200) {
        setList((pre) => ({
          ...pre,
          data: pre.data.map((item) =>
            item.id === statusDetails?.id
              ? {
                  ...item,
                  orderStatusInfo: {
                    name: values?.status?.name,
                    id: values?.status?._id,
                    // processing_parcentage: values?.parcentage,
                  },
                }
              : item
          ),
        }));
        toast.success("Status Change Successful");
        onClose(true);
        loadList();
      } else {
        toast.error("Somthing Went Wrong");
      }
    });
  };

  useEffect(() => {
    if (statusDetails?.order_id) {
      setFieldsValue({
        status: { name: statusDetails?.orderStatusInfo?.name, _id: statusDetails?.orderStatusInfo?.id },
        message: statusDetails?.orderStatusInfo?.title,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusDetails?.order_id]);

  return (
    <>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="py-2 md:px-6 px-0">
          <div className="mb-4">Order Maintenance:</div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="relative md:col-span-2 ">
              <SelectDropdownSearch
                label={"Status: "}
                labelClasses={"!text-base"}
                placeholder={"Select Status"}
                xPlacement={"bottom"}
                buttonArrowIcon={""}
                selectName={"status"}
                isSearch={false}
                dropdownButtonClass="!px-3 !border-gray-200 !w-full"
                onChange={handleChange}
                value={values?.status}
                dropdownData={[
                  { name: "Processing", _id: 1 },
                  { name: "Accepted", _id: 2 },
                  { name: "Out for Delivery", _id: 3 },
                  { name: "Shipped", _id: 4 },
                  { name: "Cancel", _id: 5 },
                ]}
              />
            </div>
            <div className="relative md:col-span-2">
              <TextAreaAutoSize
                isInputGroup={true}
                inputGroupIcon={"fa-solid fa-user-tie"}
                inputGroupPosition={"left"}
                label={"Message: "}
                labelClasses={"!text-base"}
                inputType={"text"}
                inputValue={message[values?.status?.name]}
                inputName={"message"}
                onChange={handleChange}
                inputPlaceholder={"Enter Message"}
              />
            </div>
          </div>
          <div className="flex justify-end space-x-3 mt-2">
            <Button
              buttonHasLink={false}
              buttonFunction={onClose}
              buttonType={"button"}
              buttonIcon={"fa-regular fa-xmark"}
              buttonIconPosition={"left"}
              buttonLabel={"Cancel"}
              buttonLabelClasses={"md:block hidden"}
              buttonClasses={"!bg-white !text-primary !border-primary !border-2"}
            />
            <Button
              buttonHasLink={false}
              buttonType={"submit"}
              buttonIcon={"fa-light fa-floppy-disk"}
              buttonIconPosition={"left"}
              buttonClasses={"!bg-primary !text-org-silver"}
              buttonLabel={"Save"}
              buttonLabelClasses={"md:block hidden"}
              // isDisable={loaded}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default OrderStatusChange;
