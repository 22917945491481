import React, { useCallback, useEffect, useState } from "react";
import ContactInfoItem from "../Items/ContactInfoItem";
import ContactImage from "../Items/ContactImage";
import ContactDetails from "../Items/ContactDetails";
import { getPageData } from "../../services/frontService/PageService";
import { useSelector } from "react-redux";

const contactus = {
  subheading: "Great Designs At The Best Price",
  heading: "Contact US",
  description:
    "HJL Designs is a manufacturer and wholesaler of high quality vinyl labels for the craft wine making industry. With a mix of modern and traditional designs, our ever-expanding lineup of 9.5 cm x 7 cm labels fit any bottle size.",
};

const ContactInfo = () => {
  const [homeData, setHomeData] = useState({});
  const settings = useSelector((state) => state?.settings?.data);

  const loadPageData = useCallback(() => {
    getPageData({
      slug: "contact-us",
    }).then((res) => {
      if (res?.status === 200) {
        setHomeData(res?.docs);
      }
    });
  }, []);

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);

  return (
    <>
      <div className="sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl xl:pt-20 pt-10 mx-auto">
        <div className="relative">
          <div>
            <div className="text-center xl:px-40 px-0 w-full">
              <div className="md:text-lg text-sm text-gray-700">{contactus.subheading}</div>
              <h2 className="great-design xl:text-6xl md:text-5xl text-2xl font-bold pt-2 pb-4 drop-shadow-md uppercase">
                <span className="relative inline-block !leading-none before:content-[''] before:absolute before:top-2/4 before:-translate-y-1/2 md:before:-left-36 before:-left-20 md:before:w-32 before:w-16 before:border-t-2 before:border-black after:content-[''] after:absolute after:top-2/4 after:-translate-y-1/2 md:after:-right-36 after:-right-20 md:after:w-32 after:w-16 after:border-t-2 after:border-black">
                  {contactus.heading}
                </span>
              </h2>
              {/* <div className="md:text-lg text-sm pb-3 text-gray-800">
            {contactus.description}
          </div> */}
            </div>
          </div>
          <div className="flex xl:flex-row flex-col md:gap-10 gap-5 md:py-14 py-0">
            {/* <ContactImage /> */}
            <ContactDetails settings={settings} />
            <ContactInfoItem />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactInfo;
