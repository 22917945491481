import React from "react";
import Image from "../elements/Image";
import img from "../../assets/images/cards/card-inside-6.webp";
import Review from "./Review";
import SingleDescriptionRating from "./SingleDescriptionRating";
import nodatafound from "../../assets/images/nodatafound.png";

const ProductDescriptionRating = ({ reviewList, details }) => {
  return (
    <>
      {details?.data?.reviewInfo?.length > 0 ? (
        <div className="xl:w-full w-full bg-white rounded-md md:px-10 px-5 pt-5 pb-7 shadow-md flex items-start md:gap-5 gap-3 divide-x">
          <div className="md:w-20 w-14 aspect-square rounded-full overflow-hidden flex ">
            <Image src={img} alt="No Data Found" effect="blur" classes="w-full h-full object-cover" />
          </div>
          <div className="relative divide-y divide-slate-200">
            {details?.data?.reviewInfo?.map((item, index) => (
              <SingleDescriptionRating key={index} data={item} />
            ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center md:space-y-4 space-y-2 md:mt-8 mt-4">
          <div className="md:w-28 md:h-28 w-20 h-20 rounded-full bg-[#F0F9F4] overflow-hidden flex items-center justify-center">
            <div className="text-[#e73737] md:text-7xl text-5xl">
              <i className="fa-regular fa fa-exclamation"></i>
            </div>
          </div>
          <div className="text-cape-cod md:text-3xl text-2xl text-center">No Reviews Available.</div>
          <div className="text-concord text-base max-w-md text-center">
            It looks like there are no reviews for this item yet. Be the first to share your thoughts!
          </div>
        </div>
      )}
    </>
  );
};

export default ProductDescriptionRating;
{
  /* <div className="xl:w-full w-full bg-white rounded-md md:px-10 px-5 py-5 shadow-md">
          <div className="w-full h-auto overflow-hidden flex justify-center items-center">
            <div>
              <Image src={nodatafound} alt="No Data Found" effect="blur" className="w-full h-full object-cover" />
            </div>
          </div>
        </div> */
}
