import React from "react";

import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement } from "chart.js";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const LineChart = ({ chartList }) => {
  const chart = chartList?.data?.map((item) => item?.month_name);
  const salesData = chartList?.data?.map((item) => item?.total_order_values);

  const data = {
    labels: chart,
    datasets: [
      {
        label: "Sales",
        data: salesData,
        fill: false,
        backgroundColor: "rgb(75, 192, 192)",
        borderColor: "rgba(75, 192, 192, 0.2)",
        tension: 0.1,
      },
    ],
  };

  // const data = {
  //   labels: [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December",
  //   ],
  //   datasets: [
  //     {
  //       label: "Sales",
  //       data: [65, 59, 80, 81, 56, 55, 40],
  //       fill: false,
  //       backgroundColor: "rgb(75, 192, 192)",
  //       borderColor: "rgba(75, 192, 192, 0.2)",
  //       tension: 0.1,
  //     },
  //   ],
  // };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `Sales: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

  return (
    <>
      <div className="w-full p-4 bg-white rounded-lg shadow-md">
        <h3 className="text-2xl font-normal mb-3 text-black">
          Sales <span className="text-red-600 font-bold">Data</span>
        </h3>
        <hr className="w-full border-1 border-slate-500 mb-4"></hr>
        <Line data={data} options={options} />
      </div>
    </>
  );
};

export default LineChart;
