import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setTitle } from "../../../helpers/MetaTag";
import { addTestimonial, testimonialDetails } from "../../../services/testimonialService";
import TextEditor from "../../../components/form/TextEditor";

const TestimonialAddEdit = () => {
  const location = useLocation();
  setTitle("HJL DESIGNS");
  const { id } = useParams();
  const validation = {
    name: { required: true, message: "Please enter your name !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [companyImage, setCompanyImage] = useState();
  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });
  const [date, setDate] = useState({ startDate: null, endDate: null });
  const [description, setDescription] = useState();

  const getDetails = useCallback(() => {
    if (id) {
      setDetailsData({ loading: true, data: {} });
      testimonialDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          setFieldsValue({
            name: res?.docs?.name || "",
          });
          setDescription(res?.docs?.description);
          setImageUrl(res?.docs?.imageUrl);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      if (!id) {
        const res = await addTestimonial({
          // id: id,
          image: imageUrl,
          name: values?.name,
          description: description,
          status: 1,
        });

        if (res?.data?.status === 200 || res?.data?.status === 201) {
          navigate("/admin/testimonial-list");
          toast.info(res?.data?.message);
        } else {
          toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
        }
      } else {
        const res = await addTestimonial({
          id: id,
          image: imageUrl,
          name: values?.name,
          description: description,
          status: 1,
        });

        if (res?.data?.status === 200 || res?.data?.status === 201) {
          navigate(`/admin/testimonial-list${location.search}`);
          toast.info(res?.data?.message);
        } else {
          toast.info(res?.data?.message || res?.data?.errors || "Something went wrong");
        }
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <PageHeader
          title={detailsData?.data?.id ? "Edit Testimonial" : "Add New Testimonial"}
          headerActions={() => (
            <div className="flex items-center space-x-3">
              <Button
                buttonHasLink={true}
                buttonLink={`/admin/testimonial-list${location.search}`}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonLabelClasses={"md:block hidden"}
                buttonClasses={"!bg-white !text-red-600 !border-red-600 !border-2"}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-red-600 !text-org-silver"}
                buttonLabel={"Save"}
                buttonLabelClasses={"md:block hidden"}
                isDisable={loaded}
              />
            </div>
          )}
        />
        <div className="py-2 md:px-6 px-0">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-5 gap-4 divide-x divide-slate-200 md:px-10 px-0">
              <div className="xl:col-span-4 col-span-5">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative md:col-span-12 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-regular fa-user"}
                        inputGroupPosition={"left"}
                        label={"Name"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.name}
                        inputName={"name"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Name"}
                        {...formValidate(errors, "name")}
                      />
                    </div>
                    <div className="relative md:col-span-12 col-span-12">
                      <TextEditor
                        label={"Description"}
                        labelClasses={"!text-base"}
                        value={description}
                        onChange={(e) => setDescription(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-1 col-span-5 pl-4 gap-3">
                <div className="relative">
                  <SingleImageUpload
                    label={"Image"}
                    size={"full"}
                    image={imageUrl}
                    setImage={setImageUrl}
                    accept={["jpg", "png", "jpeg"]}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3 w-full justify-end">
                <Button
                  buttonHasLink={true}
                  buttonLink={`/admin/testimonial-list${location.search}`}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={"!bg-white !text-primary !border-primary !border-2"}
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-primary !text-org-silver"}
                  buttonLabel={"Save"}
                  isDisable={loaded}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TestimonialAddEdit;
