import React, { useState } from "react";
import Input from "../../form/Input";
import Button from "../../form/Button";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import { addNewFile, addNewFolder } from "../../../services/mediaService";
import MultipleImageUpload from "../../form/MultipleImageUpload";
import MultipleImageUploader from "../../form/MultiImageUploader";

const FileAdd = ({
  setFileModalOpen = () => {},
  selectedFolderId,
  setSelectedFolderId = () => {},
  loadList = () => {},
  setCurrentPage = () => {},
}) => {
  const [loaded, setLoaded] = useState(false);
  const [selectedImg, setSelectedImg] = useState([]);

  const mediaData = {};
  Array.isArray(selectedImg) &&
    selectedImg?.length > 0 &&
    selectedImg?.forEach((item, index) => {
      mediaData[`mediaFile[${index}]`] = item;
    });

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await addNewFile({
        folderId: selectedFolderId,
        // ...mediaData,
        mediaFile: selectedImg,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.success(res?.data?.message);
        setSelectedFolderId(selectedFolderId);
        setFileModalOpen(false);
        loadList();
        setCurrentPage(1);
      } else {
        toast.info(
          res?.data?.message || res?.data?.errors || "Something went wrong"
        );
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <div>
      <div className="pt-2 md:px-4 px-0">
        <div className="w-full">
          {/* <div className="grid grid-cols-5 gap-4 divide-x divide-slate-200 md:px-10 px-0"> */}
          {/* <div className="xl:col-span-6 col-span-6"> */}
          {/* <div className="space-y-3"> */}
          {/* <div className="grid grid-cols-12 gap-4"> */}
          {/* <div className="relative md:col-span-12 col-span-12"> */}
          <MultipleImageUploader
            size={"full"}
            images={selectedImg}
            setImages={setSelectedImg}
            label={" "}
            accept={["jpg", "png", "jpeg"]}
          />
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
          <div className="mt-8 pt-4 border-t border-slate-200">
            <div className="flex items-center space-x-2 justify-end pb-2">
              <Button
                buttonHasLink={false}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonClasses={
                  "!bg-white !text-primary !border-primary !border-2"
                }
                buttonFunction={() => setFileModalOpen(false)}
              />
              <Button
                buttonHasLink={false}
                buttonType={"button"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-primary !text-org-silver"}
                buttonLabel={"Save"}
                buttonFunction={onSubmit}
                isDisable={loaded}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileAdd;
